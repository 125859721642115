import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { FaDownload, FaCheckCircle, FaCalendar } from "react-icons/fa";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useLoadingContext } from "react-router-loading";
import { format } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { HashLoader } from "react-spinners";
import Select from "react-select";
import { CSVLink } from "react-csv";

const AfterOfficeHours = () => {
	let today = new Date();
	let month = [];
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";
	let monthNumber = month[today.getMonth()];
	let [present, setpresent] = useState(0);
	let [absent, setabsent] = useState(0);
	let [onleave, setonleave] = useState(0);
	let [holiday, setholiday] = useState(0);
	const loadingContext = useLoadingContext();
	let [loading, setLoading] = useState(false);
	const [date, setdate] = useState(new Date());
	const [attdata, setattdata] = useState(null);
	const [isVisible, setisVisible] = useState(false);
	const [filename, setFilename] = useState("after_office_hours.csv");
	const [weeksummary, setWeekSummary] = useState([]);
	const [weekdata, setWeekData] = useState([]);
	const [monthYear, setmonthYear] = useState({});
	const currmonth = monthNumber;
	const [summary, setSummary] = useState([]);
	const [alluser, setalluser] = useState(null);
	const [username, setusername] = useState();
	const [userdatalist, setuserdata] = useState([]);
	const [selectedval, setselectedval] = useState("");
	const [orgval, setorgval] = React.useState([]);
	const [csvData, setCsvData] = useState([]);
	const curryear = today.getFullYear();
	const [tableData, setTableData] = useState();
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: Number(currmonth),
	});
	useEffect(() => {
		if (selectedval?.uuid && selectedmonth?.year && selectedmonth?.month) {
			console.log("Calling API with:", {
				year: selectedmonth.year,
				month: selectedmonth.month,
				uuid: selectedval.uuid,
			});
			getData({
				year: selectedmonth.year,
				month: selectedmonth.month,
				uuid: selectedval.uuid,
			});
		}
	}, [selectedval?.uuid, selectedmonth?.year, selectedmonth?.month]);
	const columns = [
		{ title: "Date", field: "date" },
		{
			title: "Start Time",
			field: "start_time",
		},
		{
			title: "End Time",
			field: "end_time",
		},
		{
			title: "Duration",
			field: "duration",
		},
	];
	// const options = {
	// 	movableRows: true,
	// 	movableColumns: true,
	// 	pagination: "local",
	// 	paginationSize: 10,
	// };
	const options = {
		pagination: "local",
		paginationSize: 31,
		movableRows: true,
		movableColumns: true,
	};
	const getData = async ({ year, month, uuid }) => {
		console.log("Fetching Data with Params:", { year, month, uuid });
		setLoading(true);

		// Use passed values or fallback to selectedmonth
		const pmonth = month || selectedmonth?.month;
		const pyear = year || selectedmonth?.year;

		const user = userdatalist.find((user) => user.uuid === uuid);
		if (!user) {
			console.error("User not found for the provided UUID.");
			setLoading(false);
			return;
		}

		const payload = {
			month: pmonth,
			year: pyear,
			company_uuid: user.company_uuid,
			uuid: user.uuid,
			username: user.username,
		};

		console.log("Payload for API:", payload);

		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(
				`${BASEURL}get-after-office-hrs/${userdata?.uuid}`,
				{
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify(payload),
				}
			);
			const result = await response.json();

			if (result.status === 200) {
				setTableData(result?.attendance || []);
				formatCsvData(result?.attendance);

				const newFilename = `${result?.name}_${pyear}_${pmonth}_after_office_hours.csv`;
				setFilename(newFilename);
			} else {
				console.error("Error fetching data:", result.message);
				setTableData([]);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false);
		}
	};

	const formatCsvData = (attendance) => {
		const formattedData = attendance.map((item) => ({
			Date: item.date,
			"Start Time": item.start_time,
			"End Time": item.end_time,
			Duration: item.duration,
			"Punching Times": item.punching_times,
		}));
		setCsvData(formattedData);
	};

	const headers = [
		{ label: "Date", key: "Date" },
		{ label: "Start Time", key: "Start Time" },
		{ label: "End Time", key: "End Time" },
		{ label: "Duration", key: "Duration" },
		{ label: "Punching Times", key: "Punching Times" },
	];
	const getSummary = async (username, date, users = []) => {
		if (
			JSON.parse(localStorage.getItem("isAuth")).type == "0" ||
			JSON.parse(localStorage.getItem("isAuth")).type == "1"
		) {
			if (username != "" && users && users.length > 0) {
				let res = users.filter((el) => {
					return Number(username) === Number(el.username);
				});
				setselectedval({
					value: username,
					uuid: res[0].uuid,
					label: res[0].name,
					active: "🟢",
				});
			}
		}
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-Summary/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				username,
				company_uuid: JSON.parse(localStorage.getItem("isAuth"))
					.companydata[0].uuid,
				master: JSON.parse(localStorage.getItem("isAuth")).master,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setusername(username);
					setSummary(res.summary);
					setWeekSummary(res.weeksummary);
					setWeekData(res.weeklydata);
				} else {
					setSummary([]);
					setWeekSummary([]);
					setWeekData([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	const filterByReference = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return !arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	const filterByReference2 = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	let filterarr = filterByReference(userdatalist, alluser);
	let filterarr2 = filterByReference2(userdatalist, alluser);
	if (filterarr2 && filterarr2.length > 0) {
		for (let i = 0; i < filterarr2.length; i++) {
			filterarr2[i].active2 = true;
		}
	}
	let finalarr3 = filterarr2.concat(filterarr);
	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < finalarr3.length; i++) {
		data.push({
			value: finalarr3[i].username,
			label: finalarr3[i].name,
			active: finalarr3[i].active2 ? "🟢" : "🔴",
			uuid: finalarr3[i].uuid,
		});
	}
	const getUserData = async (org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsersData/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.data);
					setalluser(res.alluser);
					if (res.alluser.length > 0 && res.data.length > 0) {
						setusername(res.alluser[0]["username"]);
						getSummary(res.alluser[0]["username"], date, res.data);
					} else {
						let userdata = JSON.parse(
							localStorage.getItem("isAuth")
						);
						getSummary(userdata.username, date, res.data);
					}
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
		loadingContext.done();
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const queryMonth = queryParams.get("month");
		const queryYear = queryParams.get("year");
		const queryEmployeeUuid = queryParams.get("employee_uuid");

		// Set month and year from query parameters
		if (queryMonth && queryYear) {
			setselectedmonth({
				month: parseInt(queryMonth, 10),
				year: parseInt(queryYear, 10),
			});
		}

		// Find the employee by UUID
		if (queryEmployeeUuid && userdatalist.length > 0) {
			const selectedUser = userdatalist.find(
				(user) => user.uuid === queryEmployeeUuid
			);
			if (selectedUser) {
				setselectedval(selectedUser);
				setusername(selectedUser.username); // Extract and set username
			} else {
				console.warn("No user found for the provided employee_uuid.");
			}
		}
	}, [userdatalist]);

	useEffect(() => {
		getUserData();
	}, []);
	const handleOnDismiss = () => {
		setisVisible(false);
	};
	const handleOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month })
		);
		setisVisible(false);
		setmonthYear({ year, month: monthname });
		setselectedmonth({ year, month: month });
		getData({ year, month, uuid: selectedval?.uuid });
	};
	const handleuser = (e) => {
		setselectedval(e);
		setusername(e.value);
		getData({
			year: selectedmonth?.year,
			month: selectedmonth?.month,
			uuid: e.uuid,
		});
	};
	const showMonthPicker = (e) => {
		setisVisible(true);
		e.preventDefault();
	};
	const getMonthValue = () => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		if (selectedmonth?.year && selectedmonth?.month) {
			const monthName = monthNames[selectedmonth.month - 1];
			return `${monthName}-${selectedmonth.year}`;
		}
		const currentDate = new Date();
		const currMonthName = monthNames[currentDate.getMonth()];
		const currYear = currentDate.getFullYear();
		return `${currMonthName}-${currYear}`;
	};
	return (
		<>
			<div class="intro-y box lg:mt-5 ">
				<div className="" style={{ justifyContent: "space-between" }}>
					<div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
						<h2 className="font-medium text-base p-2 mr-auto">
							After Office Hours
						</h2>
					</div>
				</div>
				<div className="intro-y box p-5">
					<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
						<div className="flex mt-5 sm:mt-0">
							<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
								<input
									type="text"
									style={{ fontSize: "12px" }}
									onFocus={(e) => showMonthPicker(e)}
									value={getMonthValue()}
									className="form-control calenderdate1"
									id="basic-url"
									aria-describedby="basic-addon3"
								/>
								<div className="input-group-append calendaricon">
									<FaCalendar
										style={{ fontSize: "19px" }}
										onClick={(e) => showMonthPicker(e)}
									/>
								</div>
							</div>
							{loading ? (
								<div className="text-center m-5 p-5">
									<HashLoader
										color="#5755d9"
										size={30}
										style={{
											position: "absolute",
											right: "50%",
										}}
									/>
								</div>
							) : (
								<ReactMonthPicker
									show={isVisible}
									lang={[
										"Jan",
										"Feb",
										"Mar",
										"Apr",
										"May",
										"Jun",
										"Jul",
										"Aug",
										"Sep",
										"Oct",
										"Nov",
										"Dec",
									]}
									value={selectedmonth}
									onChange={handleOnChange}
									onDismiss={handleOnDismiss}
								/>
							)}
						</div>

						<div>
							{!loading && isAuth.role === 0 && (
								<Select
									defaultValue={data[0]}
									value={
										data.find(
											(item) =>
												item.uuid === selectedval?.uuid
										) || null
									}
									className="basic-single mr-2"
									onChange={(e) => {
										handleuser(e);
									}}
									options={data}
									getOptionLabel={(option) =>
										`${option.label}`
									}
									getOptionValue={(option) => option.uuid} // Ensure correct value mapping
									styles={{
										option: (base) => ({
											...base,
											height: "100%",
											display: "flex",
										}),
									}}
								/>
							)}
						</div>

						{/* <button
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "10px 18px",
										backgroundColor: "rgb(30 64 175)",
										color: "white",
										border: "none",
										borderRadius: "5px",
										fontSize: "16px",
										cursor: "pointer",
										boxShadow:
											"0 4px 8px rgba(0, 0, 0, 0.1)",
										transition:
											"background-color 0.3s ease",
									}}
									onMouseOver={(e) => {
										e.target.style.backgroundColor =
											"rgb(29, 53, 153)";
									}}
									onMouseOut={(e) => {
										e.target.style.backgroundColor =
											"rgb(30 64 175)";
									}}
								>
									<FaDownload /> remnove
								</button> */}
						<CSVLink
							data={csvData}
							headers={headers}
							filename={filename}
							className="btn btn-primary"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "10px 18px",
								backgroundColor: "rgb(30 64 175)",
								color: "white",
								border: "none",
								borderRadius: "5px",
								fontSize: "16px",
								cursor: "pointer",
								boxShadow:
									"0 4px 8px rgba(0, 0, 0, 0.1)",
								transition:
									"background-color 0.3s ease",
							}}
							onMouseOver={(e) => {
								e.target.style.backgroundColor =
									"rgb(29, 53, 153)";
							}}
							onMouseOut={(e) => {
								e.target.style.backgroundColor =
									"rgb(30 64 175)";
							}}
						>
							<FaDownload />
						</CSVLink>
					</div>

					<div className="overflow-x-auto scrollbar-hidden">
						{tableData && tableData.length === 0 ? (
							<div className="text-center mt-5">
								No data available for this month
							</div>
						) : (
							<ReactTabulator
								columns={columns}
								data={tableData}
								options={{
									pagination: "local",
									paginationSize: 31,
									movableRows: true,
									movableColumns: true,
								}}
								className="mt-5 table-report table-report--tabulator"
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default AfterOfficeHours;

// // import React, { useEffect, useState } from "react";
// // import axios from "axios";
// // import { useParams } from "react-router-dom";
// // import { BASEURL } from "../BASEURL";
// // import { HashLoader } from "react-spinners";
// // import { FaTimes } from "react-icons/fa";
// // import {
// // 	AiOutlineZoomIn,
// // 	AiOutlineZoomOut,
// // 	AiOutlineCloseCircle,
// // } from "react-icons/ai";
// // import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// // function TestResult() {
// // 	const [testData, setTestData] = useState(null);
// // 	const [screenshotData, setScreenshotData] = useState([]);
// // 	const [loading, setLoading] = useState(true);
// // 	const [error, setError] = useState(null);
// // 	const [selectedIndex, setSelectedIndex] = useState(null); // For navigation in the modal
// // 	const params = useParams();

// // 	const formatDate = (dateString) => {
// // 		const date = new Date(dateString);
// // 		const day = String(date.getDate()).padStart(2, "0");
// // 		const month = String(date.getMonth() + 1).padStart(2, "0");
// // 		const year = String(date.getFullYear());
// // 		return `${year}-${month}-${day}`;
// // 	};

// // 	useEffect(() => {
// // 		const handleKeyDown = (e) => {
// // 			if (e.key === "ArrowLeft") handlePrev();
// // 			if (e.key === "ArrowRight") handleNext();
// // 			if (e.key === "Escape") setSelectedIndex(null);
// // 		};
// // 		window.addEventListener("keydown", handleKeyDown);

// // 		return () => {
// // 			window.removeEventListener("keydown", handleKeyDown);
// // 		};
// // 	}, [selectedIndex]);

// // 	const getData = async () => {
// // 		try {
// // 			const formData = new FormData();
// // 			formData.append("uuid", params.id);
// // 			const res = await axios.post(BASEURL + "get-jobdata", formData, {
// // 				headers: {
// // 					"Content-Type": "application/json",
// // 				},
// // 			});
// // 			if (res.data.status === 201 && res.data.data.length > 0) {
// // 				setTestData(res.data.data[0]);
// // 				fetchScreenshotData(res.data.data[0]);
// // 			} else {
// // 				setError("No test data found");
// // 			}
// // 		} catch (error) {
// // 			setError("Failed to fetch data");
// // 		} finally {
// // 			setLoading(false);
// // 		}
// // 	};

// // 	const fetchScreenshotData = async (testData) => {
// // 		try {
// // 			const { application_id } = testData;
// // 			let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 			let token = userdata.usertoken;

// // 			const res = await axios.post(
// // 				BASEURL + "get-test-applicant-screenshotData",
// // 				{ application_id },
// // 				{
// // 					headers: {
// // 						Authorization: token,
// // 					},
// // 				}
// // 			);

// // 			if (res.data.status === 201) {
// // 				setScreenshotData(res.data.data);
// // 			}
// // 		} catch (error) {
// // 			// setError("Failed to fetch screenshot data");
// // 		}
// // 	};

// // 	useEffect(() => {
// // 		getData();
// // 	}, []);

// // 	if (loading) return <div>Loading...</div>;
// // 	if (error) return <div>Error: {error}</div>;
// // 	if (!testData && screenshotData.length === 0)
// // 		return <div>No test data or screenshots available</div>;

// // 	const imageBaseUrl = "https://d1azebur1db3zi.cloudfront.net/screenshot";
// // 	const screenshots = Object.values(screenshotData)
// // 		.flat()
// // 		.filter((data) => data.image_name);

// // 	const handlePrev = () => {
// // 		if (selectedIndex !== null) {
// // 			console.log("Previous clicked");
// // 			setSelectedIndex((prevIndex) =>
// // 				prevIndex > 0 ? prevIndex - 1 : screenshots.length - 1
// // 			);
// // 		}
// // 	};
// // 	const handleNext = () => {
// // 		if (selectedIndex !== null) {
// // 			console.log("Next clicked");
// // 			setSelectedIndex((prevIndex) =>
// // 				prevIndex < screenshots.length - 1 ? prevIndex + 1 : 0
// // 			);
// // 		}
// // 	};

// // 	const zoomControls = (zoomIn, zoomOut, resetZoom) => {
// // 		return (
// // 			<div
// // 				style={{
// // 					position: "absolute",
// // 					zIndex: "1",
// // 					right: "5%",
// // 					top: "85%",
// // 				}}
// // 			>
// // 				<button
// // 					onClick={zoomIn}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineZoomIn />
// // 				</button>
// // 				<button
// // 					onClick={zoomOut}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineZoomOut />
// // 				</button>
// // 				<button
// // 					onClick={resetZoom}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineCloseCircle />
// // 				</button>
// // 			</div>
// // 		);
// // 	};
// // 	return (
// // 		<>
// // 			<div className="intro-y box px-5 pt-2 mt-2">
// // 				<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
// // 					<div className="w-full lg:w-2 pr-4">
// // 						<h2 className="font-medium text-lg">
// // 							Assessment Test Result
// // 						</h2>
// // 						<div className="overflow-x-auto">
// // 							<table
// // 								className="min-w-full bg-white border border-gray-300"
// // 								style={{ width: "100%", maxWidth: "1000px" }}
// // 							>
// // 								<thead>
// // 									<tr>
// // 										<th className="border border-gray-300 p-2 text-left">
// // 											Description
// // 										</th>
// // 										<th className="border border-gray-300 p-2 text-left">
// // 											Value
// // 										</th>
// // 									</tr>
// // 								</thead>
// // 								<tbody>
// // 									<tr>
// // 										<td className="border border-gray-300 p-2 font-medium">
// // 											Total Marks
// // 										</td>
// // 										<td className="border border-gray-300 p-2">
// // 											{testData?.test_outof}
// // 										</td>
// // 									</tr>
// // 									<tr>
// // 										<td className="border border-gray-300 p-2 font-medium">
// // 											Obtained Marks
// // 										</td>
// // 										<td className="border border-gray-300 p-2">
// // 											{testData.test_result}
// // 										</td>
// // 									</tr>
// // 									<tr>
// // 										<td className="border border-gray-300 p-2 font-medium">
// // 											Test Percentage
// // 										</td>
// // 										<td className="border border-gray-300 p-2">
// // 											{testData.test_percentage}
// // 										</td>
// // 									</tr>
// // 									<tr>
// // 										<td className="border border-gray-300 p-2 font-medium">
// // 											Test Given Date
// // 										</td>
// // 										<td className="border border-gray-300 p-2">
// // 											{formatDate(testData.test_done_at)}
// // 										</td>
// // 									</tr>
// // 								</tbody>
// // 							</table>
// //                             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
// //   {selectedIndex !== null && (
// //     <div className="modal-backdrop">
// //       <div className="modal-container relative">
// //         {screenshots[selectedIndex] && (
// //           <TransformWrapper>
// //             {({ zoomIn, zoomOut, resetTransform }) => (
// //               <>
// //                 <TransformComponent>
// //                   <img
// //                     src={`${imageBaseUrl}/${
// //                       screenshots[selectedIndex].application_id
// //                     }/${formatDate(testData.test_done_at)}/${
// //                       screenshots[selectedIndex].image_name
// //                     }`}
// //                     alt={`Screenshot ${selectedIndex + 1}`}
// //                     className="modal-image"
// //                   />
// //                 </TransformComponent>
// //                 {zoomControls(zoomIn, zoomOut, resetTransform)}
// //               </>
// //             )}
// //           </TransformWrapper>
// //         )}
// //         {/* Close Button */}
// //         <button
// //           onClick={() => setSelectedIndex(null)}
// //           className="modal-button button-close"
// //         >
// //           <FaTimes />
// //         </button>

// //         {/* Previous Button */}
// //         <button
// //           onClick={handlePrev}
// //           className="modal-button button-prev"
// //         >
// //           Previous
// //         </button>

// //         {/* Next Button */}
// //         <button
// //           onClick={handleNext}
// //           className="modal-button button-next"
// //         >
// //           Next
// //         </button>
// //       </div>
// //     </div>
// //   )}
// // </div>

// // 						</div>
// // 					</div>
// // 				</div>

// // 				{screenshots.length > 0 && (
// // 					<div>
// // 						<h3 className="font-medium text-lg">
// // 							Screenshot Images
// // 						</h3>
// // 						<div className="grid grid-cols-8 lg:grid-cols-4 gap-4 mt-3">
// // 							{screenshots.map((data, index) => (
// // 								<a
// // 									key={index}
// // 									href={`${imageBaseUrl}/${
// // 										data.application_id
// // 									}/${formatDate(testData.test_done_at)}/${
// // 										data.image_name
// // 									}`}
// // 									target="_blank"
// // 									rel="noopener noreferrer"
// // 									onClick={(e) => {
// // 										e.preventDefault(); // Prevents opening in a new tab
// // 										setSelectedIndex(index); // Opens the modal at the selected index
// // 									}}
// // 								>
// // 									<img
// // 										src={`${imageBaseUrl}/${
// // 											data.application_id
// // 										}/${formatDate(
// // 											testData.test_done_at
// // 										)}/${data.image_name}`}
// // 										alt={`Screenshot ${index + 1}`}
// // 										className="border border-gray-300 rounded w-full max-w-xs cursor-pointer"
// // 										style={{
// // 											height: "150px",
// // 											objectFit: "contain",
// // 										}}
// // 									/>
// // 								</a>
// // 							))}
// // 						</div>
// // 					</div>
// // 				)}
// // 			</div>
// // 		</>
// // 	);
// // }

// // export default TestResult;

// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { useParams } from "react-router-dom";
// // import { BASEURL } from "../BASEURL";
// // import { FaTimes } from "react-icons/fa";
// // import {
// // 	AiOutlineZoomIn,
// // 	AiOutlineZoomOut,
// // 	AiOutlineCloseCircle,
// // } from "react-icons/ai";
// // import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// // import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// // function TestResult() {
// // 	const [testData, setTestData] = useState(null);
// // 	const [screenshotData, setScreenshotData] = useState([]);
// // 	const [loading, setLoading] = useState(true);
// // 	const [error, setError] = useState(null);
// // 	const [selectedIndex, setSelectedIndex] = useState(null);
// // 	const params = useParams();

// // 	const formatDate = (dateString) => {
// // 		const date = new Date(dateString);
// // 		const day = String(date.getDate()).padStart(2, "0");
// // 		const month = String(date.getMonth() + 1).padStart(2, "0");
// // 		const year = String(date.getFullYear());
// // 		return `${year}-${month}-${day}`;
// // 	};

// // 	const getData = async () => {
// // 		try {
// // 			const formData = new FormData();
// // 			formData.append("uuid", params.id);
// // 			const res = await axios.post(BASEURL + "get-jobdata", formData, {
// // 				headers: {
// // 					"Content-Type": "application/json",
// // 				},
// // 			});
// // 			if (res.data.status === 201 && res.data.data.length > 0) {
// // 				setTestData(res.data.data[0]);
// // 				fetchScreenshotData(res.data.data[0]);
// // 			} else {
// // 				setError("No test data found");
// // 			}
// // 		} catch (error) {
// // 			setError("Failed to fetch data");
// // 		} finally {
// // 			setLoading(false);
// // 		}
// // 	};

// // 	const fetchScreenshotData = async (testData) => {
// // 		try {
// // 			const { application_id } = testData;
// // 			let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 			let token = userdata.usertoken;

// // 			const res = await axios.post(
// // 				BASEURL + "get-test-applicant-screenshotData",
// // 				{ application_id },
// // 				{
// // 					headers: {
// // 						Authorization: token,
// // 					},
// // 				}
// // 			);

// // 			if (res.data.status === 201) {
// // 				setScreenshotData(res.data.data);
// // 			}
// // 		} catch (error) {
// // 			// setError("Failed to fetch screenshot data");
// // 		}
// // 	};

// // 	useEffect(() => {
// // 		getData();
// // 	}, []);

// // 	if (loading) return <div>Loading...</div>;
// // 	if (error) return <div>Error: {error}</div>;
// // 	if (!testData && screenshotData.length === 0)
// // 		return <div>No test data or screenshots available</div>;

// // 	const imageBaseUrl = "https://d1azebur1db3zi.cloudfront.net/screenshot";
// // 	const screenshots = Object.values(screenshotData)
// // 		.flat()
// // 		.filter((data) => data.image_name);

// // 	const handlePrev = () => {
// // 		if (selectedIndex !== null) {
// // 			setSelectedIndex((prevIndex) =>
// // 				prevIndex > 0 ? prevIndex - 1 : screenshots.length - 1
// // 			);
// // 		}
// // 	};
// // 	const handleNext = () => {
// // 		if (selectedIndex !== null) {
// // 			setSelectedIndex((prevIndex) =>
// // 				prevIndex < screenshots.length - 1 ? prevIndex + 1 : 0
// // 			);
// // 		}
// // 	};

// // 	// Zoom Controls Component
// // 	const zoomControls = (zoomIn, zoomOut, resetZoom) => {
// // 		return (
// // 			<div
// // 				style={{
// // 					position: "absolute",
// // 					zIndex: "1",
// // 					right: "5%",
// // 					top: "85%",
// // 				}}
// // 			>
// // 				<button
// // 					onClick={zoomIn}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineZoomIn />
// // 				</button>
// // 				<button
// // 					onClick={zoomOut}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineZoomOut />
// // 				</button>
// // 				<button
// // 					onClick={resetZoom}
// // 					className="p-2 px-5 mx-1"
// // 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// // 				>
// // 					<AiOutlineCloseCircle />
// // 				</button>
// // 			</div>
// // 		);
// // 	};

// // 	return (
// // 		<div className="intro-y box px-5 pt-2 mt-2">
// // 			<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
// // 				<div className="w-full lg:w-2 pr-4">
// // 					<h2 className="font-medium text-lg">
// // 						Assessment Test Result
// // 					</h2>
// // 					<div className="overflow-x-auto">
// // 						<table
// // 							className="min-w-full bg-white border border-gray-300"
// // 							style={{ width: "100%", maxWidth: "1000px" }}
// // 						>
// // 							<thead>
// // 								<tr>
// // 									<th className="border border-gray-300 p-2 text-left">
// // 										Description
// // 									</th>
// // 									<th className="border border-gray-300 p-2 text-left">
// // 										Value
// // 									</th>
// // 								</tr>
// // 							</thead>
// // 							<tbody>
// // 								<tr>
// // 									<td className="border border-gray-300 p-2 font-medium">
// // 										Total Marks
// // 									</td>
// // 									<td className="border border-gray-300 p-2">
// // 										{testData?.test_outof}
// // 									</td>
// // 								</tr>
// // 								<tr>
// // 									<td className="border border-gray-300 p-2 font-medium">
// // 										Obtained Marks
// // 									</td>
// // 									<td className="border border-gray-300 p-2">
// // 										{testData.test_result}
// // 									</td>
// // 								</tr>
// // 								<tr>
// // 									<td className="border border-gray-300 p-2 font-medium">
// // 										Test Percentage
// // 									</td>
// // 									<td className="border border-gray-300 p-2">
// // 										{testData.test_percentage}
// // 									</td>
// // 								</tr>
// // 								<tr>
// // 									<td className="border border-gray-300 p-2 font-medium">
// // 										Test Given Date
// // 									</td>
// // 									<td className="border border-gray-300 p-2">
// // 										{formatDate(testData.test_done_at)}
// // 									</td>
// // 								</tr>
// // 							</tbody>
// // 						</table>

// // 						<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
// // 							{selectedIndex !== null && (
// // 								<div className="modal-backdrop">
// // 									<div className="modal-container relative">
// // 										{screenshots[selectedIndex] && (
// // 											<TransformWrapper>
// // 												{({
// // 													zoomIn,
// // 													zoomOut,
// // 													resetTransform,
// // 												}) => (
// // 													<>
// // 														<TransformComponent>
// // 															<img
// // 																src={`${imageBaseUrl}/${
// // 																	screenshots[
// // 																		selectedIndex
// // 																	]
// // 																		.application_id
// // 																}/${formatDate(
// // 																	testData.test_done_at
// // 																)}/${
// // 																	screenshots[
// // 																		selectedIndex
// // 																	].image_name
// // 																}`}
// // 																alt={`Screenshot ${
// // 																	selectedIndex +
// // 																	1
// // 																}`}
// // 																className="modal-image"
// // 															/>
// // 														</TransformComponent>

// // 														{/* Zoom Controls */}
// // 														{/* {zoomControls(zoomIn, zoomOut, resetTransform)} */}
// // 													</>
// // 												)}
// // 											</TransformWrapper>
// // 										)}
// // 										{/* Close Button */}
// // 										<button
// // 											onClick={() =>
// // 												setSelectedIndex(null)
// // 											}
// // 											className="modal-button button-close"
// // 										>
// // 											<FaTimes />
// // 										</button>

// // 										{/* Previous Button */}
// // 										<button
// // 											onClick={handlePrev}
// // 											className="modal-button button-prev"
// // 										>
// // 											<FaChevronLeft />
// // 										</button>

// // 										{/* Next Button */}
// // 										<button
// // 											onClick={handleNext}
// // 											className="modal-button button-next"
// // 										>
// // 											<FaChevronRight />
// // 										</button>
// // 									</div>
// // 								</div>
// // 							)}
// // 						</div>
// // 					</div>
// // 				</div>
// // 			</div>

// // 			{/* Display Screenshots */}
// // 			{screenshots.length > 0 && (
// // 				<div>
// // 					<h3 className="font-medium text-lg">Screenshot Images</h3>
// // 					<div className="grid grid-cols-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 mt-2">
// // 						{screenshots.map((data, index) => (
// // 							<a
// // 								key={index}
// // 								href={`${imageBaseUrl}/${
// // 									data.application_id
// // 								}/${formatDate(testData.test_done_at)}/${
// // 									data.image_name
// // 								}`}
// // 								target="_blank"
// // 								rel="noopener noreferrer"
// // 								onClick={(e) => {
// // 									e.preventDefault();
// // 									setSelectedIndex(index);
// // 								}}
// // 							>
// // 								<img
// // 									src={`${imageBaseUrl}/${
// // 										data.application_id
// // 									}/${formatDate(testData.test_done_at)}/${
// // 										data.image_name
// // 									}`}
// // 									alt={`Screenshot ${index + 1}`}
// // 									className="border border-gray-300 rounded w-full max-w-xs cursor-pointer"
// // 									style={{
// // 										height: "150px",
// // 										objectFit: "contain",
// // 									}}
// // 								/>
// // 							</a>
// // 						))}
// // 					</div>
// // 				</div>
// // 			)}
// // 		</div>
// // 	);
// // }

// // export default TestResult;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { BASEURL } from "../BASEURL";
// import { FaTimes } from "react-icons/fa";
// import {
// 	AiOutlineZoomIn,
// 	AiOutlineZoomOut,
// 	AiOutlineCloseCircle,
// } from "react-icons/ai";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// function TestResult() {
// 	const [testData, setTestData] = useState(null);
// 	const [screenshotData, setScreenshotData] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const [error, setError] = useState(null);
// 	const [selectedIndex, setSelectedIndex] = useState(null);
// 	const params = useParams();

// 	const formatDate = (dateString) => {
// 		const date = new Date(dateString);
// 		const day = String(date.getDate()).padStart(2, "0");
// 		const month = String(date.getMonth() + 1).padStart(2, "0");
// 		const year = String(date.getFullYear());
// 		return `${year}-${month}-${day}`;
// 	};

// 	const getData = async () => {
// 		try {
// 			const formData = new FormData();
// 			formData.append("uuid", params.id);
// 			const res = await axios.post(BASEURL + "get-jobdata", formData, {
// 				headers: {
// 					"Content-Type": "application/json",
// 				},
// 			});
// 			if (res.data.status === 201 && res.data.data.length > 0) {
// 				setTestData(res.data.data[0]);
// 				fetchScreenshotData(res.data.data[0]);
// 			} else {
// 				setError("No test data found");
// 			}
// 		} catch (error) {
// 			setError("Failed to fetch data");
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	const fetchScreenshotData = async (testData) => {
// 		try {
// 			const { application_id } = testData;
// 			let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 			let token = userdata.usertoken;

// 			const res = await axios.post(
// 				BASEURL + "get-test-applicant-screenshotData",
// 				{ application_id },
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			);

// 			if (res.data.status === 201) {
// 				setScreenshotData(res.data.data);
// 			}
// 		} catch (error) {
// 			// setError("Failed to fetch screenshot data");
// 		}
// 	};

// 	useEffect(() => {
// 		getData();
// 	}, []);

// 	if (loading) return <div>Loading...</div>;
// 	if (error) return <div>Error: {error}</div>;
// 	if (!testData && screenshotData.length === 0)
// 		return <div>No test data or screenshots available</div>;

// 	const imageBaseUrl = "https://d1azebur1db3zi.cloudfront.net/screenshot";
// 	const screenshots = Object.values(screenshotData)
// 		.flat()
// 		.filter((data) => data.image_name);

// 	const handlePrev = () => {
// 		if (selectedIndex !== null) {
// 			setSelectedIndex((prevIndex) =>
// 				prevIndex > 0 ? prevIndex - 1 : screenshots.length - 1
// 			);
// 		}
// 	};
// 	const handleNext = () => {
// 		if (selectedIndex !== null) {
// 			setSelectedIndex((prevIndex) =>
// 				prevIndex < screenshots.length - 1 ? prevIndex + 1 : 0
// 			);
// 		}
// 	};

// 	// Zoom Controls Component
// 	const zoomControls = (zoomIn, zoomOut, resetZoom) => {
// 		return (
// 			<div
// 				style={{
// 					position: "absolute",
// 					zIndex: "1",
// 					right: "5%",
// 					top: "85%",
// 				}}
// 			>
// 				<button
// 					onClick={zoomIn}
// 					className="p-2 px-5 mx-1"
// 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// 				>
// 					<AiOutlineZoomIn />
// 				</button>
// 				<button
// 					onClick={zoomOut}
// 					className="p-2 px-5 mx-1"
// 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// 				>
// 					<AiOutlineZoomOut />
// 				</button>
// 				<button
// 					onClick={resetZoom}
// 					className="p-2 px-5 mx-1"
// 					style={{ backgroundColor: "#CBD5E0", borderRadius: "8px" }}
// 				>
// 					<AiOutlineCloseCircle />
// 				</button>
// 			</div>
// 		);
// 	};

// 	return (
// 		<div className="intro-y box px-5 pt-2 mt-2">
// 			<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
// 				<div className="w-full lg:w-2 pr-4">
// 					<h2 className="font-medium text-lg">
// 						Assessment Test Result
// 					</h2>
// 					<div className="overflow-x-auto">
// 						<table
// 							className="min-w-full bg-white border border-gray-300"
// 							style={{ width: "100%", maxWidth: "1000px" }}
// 						>
// 							<thead>
// 								<tr>
// 									<th className="border border-gray-300 p-2 text-left">
// 										Description
// 									</th>
// 									<th className="border border-gray-300 p-2 text-left">
// 										Value
// 									</th>
// 								</tr>
// 							</thead>
// 							<tbody>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">
// 										Total Marks
// 									</td>
// 									<td className="border border-gray-300 p-2">
// 										{testData?.test_outof}
// 									</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">
// 										Obtained Marks
// 									</td>
// 									<td className="border border-gray-300 p-2">
// 										{testData.test_result}
// 									</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">
// 										Test Percentage
// 									</td>
// 									<td className="border border-gray-300 p-2">
// 										{testData.test_percentage}
// 									</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">
// 										Test Given Date
// 									</td>
// 									<td className="border border-gray-300 p-2">
// 										{formatDate(testData.test_done_at)}
// 									</td>
// 								</tr>
// 							</tbody>
// 						</table>

					
// 					</div>
// 				</div>
// 			</div>

				
// 		</div>
// 	);
// }

// export default TestResult;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { BASEURL } from "../BASEURL";

// function TestResult() {
// 	const [testData, setTestData] = useState(null);
// 	const [videos, setVideos] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const [error, setError] = useState(null);
// 	const params = useParams();

// 	const formatDate = (dateString) => {
// 		const date = new Date(dateString);
// 		const day = String(date.getDate()).padStart(2, "0");
// 		const month = String(date.getMonth() + 1).padStart(2, "0");
// 		const year = String(date.getFullYear());
// 		return `${year}-${month}-${day}`;
// 	};

// 	const getTestData = async () => {
// 		try {
// 			const formData = new FormData();
// 			formData.append("uuid", params.id);
// 			const res = await axios.post(BASEURL + "get-jobdata", formData, {
// 				headers: { "Content-Type": "application/json" },
// 			});
// 			if (res.data.status === 201 && res.data.data.length > 0) {
// 				const updatedData = {
// 					...res.data.data[0],
// 					test_video_path: `https://trackapi.Rayvat.com/uploads/screenrecording/${res.data.data[0].test_video_path}`,
// 				};
// 				setTestData(updatedData);
// 				console.log("updatedDataupdatedData",updatedData)
// 			} else {
// 				setError("No test data found");
// 			}
// 		} catch (error) {
// 			setError("Failed to fetch test data");
// 		}
// 	};
// 	const getVideos = async () => {
// 		console.log("Calling");
// 		console.log("testData?.application_id", testData?.application_id);
		
// 		try {
// 		  // Ensure `testData` is available and has an `application_id` to pass to the backend
// 		  if (testData?.application_id) {
// 			console.log("Are we getting the Application Id", testData?.application_id);
// 			const res = await axios.get(`${BASEURL}applicant-videos`, {
// 			  params: { application_id: testData.application_id }, // Passing application_id as a query parameter
// 			});
	  
// 			if (res.data.success && res.data.data.length > 0) {
// 			  const updatedVideos = res.data.data.map((video) => ({
// 				...video,
// 				video_path: `https://trackapi.Rayvat.com/uploads/screenrecording/${video.video_path}`,
// 			  }));
// 			  setVideos(updatedVideos);
// 			} else {
// 			  setError("No video data found");
// 			}
// 		  }
// 		} catch (error) {
// 		  console.error("Error fetching applicant videos:", error);
// 		  setError("Failed to fetch videos");
// 		}
// 	  };
	  
	  


// 	const openFullscreen = (videoElement) => {
// 		if (videoElement.requestFullscreen) {
// 			videoElement.requestFullscreen();
// 		} else if (videoElement.webkitRequestFullscreen) {
// 			videoElement.webkitRequestFullscreen(); // For Safari
// 		} else if (videoElement.msRequestFullscreen) {
// 			videoElement.msRequestFullscreen(); // For IE/Edge
// 		}
// 	};
// 	// useEffect(() => {
// 	// 	setLoading(true);
// 	// 	getTestData()
// 	// 	  .then(() => {
// 	// 		if (testData?.application_id) {
// 	// 		  getVideos(); 
// 	// 		}
// 	// 	  })
// 	// 	  .catch((error) => {
// 	// 		console.error("Error initializing data:", error);
// 	// 		setError("Failed to fetch data");
// 	// 	  })
// 	// 	  .finally(() => setLoading(false));
// 	//   }, [testData]); // Ensure that useEffect runs again when testData changes
	  
// 	useEffect(() => {
// 		setLoading(true);
// 		Promise.all([getTestData(), getVideos()])
// 			.catch((error) => {
// 				console.error("Error initializing data:", error);
// 				setError("Failed to fetch data");
// 			})
// 			.finally(() => setLoading(false));
// 	}, []);

// 	if (loading) return <div>Loading...</div>;
// 	if (error) return <div>Error: {error}</div>;
// 	if (!testData && videos.length === 0) return <div>No data available</div>;

// 	return (
// 		<div className="intro-y box px-5 pt-2 mt-2">
// 			{/* Assessment Test Result Section */}
// 			<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
// 				<div className="w-full lg:w-2 pr-4">
// 					<h2 className="font-medium text-lg">Assessment Test Result</h2>
// 					<div className="overflow-x-auto">
// 						<table
// 							className="min-w-full bg-white border border-gray-300"
// 							style={{ width: "100%", maxWidth: "1000px" }}
							
// 						>
// 							<thead>
// 								<tr>
// 									<th className="border border-gray-300 p-2 text-left">Description</th>
// 									<th className="border border-gray-300 p-2 text-left">Value</th>
// 								</tr>
// 							</thead>
// 							<tbody>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">Total Marks</td>
// 									<td className="border border-gray-300 p-2">{testData?.test_outof}</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">Obtained Marks</td>
// 									<td className="border border-gray-300 p-2">{testData?.test_result}</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">Test Percentage</td>
// 									<td className="border border-gray-300 p-2">{testData?.test_percentage}</td>
// 								</tr>
// 								<tr>
// 									<td className="border border-gray-300 p-2 font-medium">Test Given Date</td>
// 									<td className="border border-gray-300 p-2">{formatDate(testData?.test_done_at)}</td>
// 								</tr>
// 							</tbody>
// 						</table>
// 					</div>
// 					{/* {testData?.test_video_path && (
// 						<div className="mt-4">
// 							<h3 className="text-lg font-medium">Test Video</h3>
// 							<video
// 								controls
// 								style={{ width: "30%", maxWidth: "600px", marginTop: "10px" }}
// 							>
// 								<source src={testData.test_video_path} type="video/webm" />
// 								Your browser does not support the video tag.
// 							</video>
// 						</div>
// 					)} */}
// 				</div>
// 			</div>

// 			{/* Applicant Videos Section */}
// 			<div className="mt-6">
// 				<h2 className="font-medium text-lg mb-4">Applicant Videos</h2>
// 				<div className="grid grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 gap-4">
// 					{videos.map((video) => (
// 						<div key={video.id} className="video-item">
// 							<video
// 								controls
								
// 								style={{ width: "100%", height: "200px", cursor: "pointer" }}
// 								onClick={(e) => openFullscreen(e.target)}
// 							>
// 								<source src={video.video_path} type="video/webm" />
// 								Your browser does not support the video tag.
// 							</video>
// 							<p className="mt-2 text-sm text-gray-500">
// 								Uploaded At: {formatDate(video.upload_date)}
// 							</p>
// 						</div>
// 					))}
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// export default TestResult;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASEURL } from "../BASEURL";

function TestResult() {
    const [testData, setTestData] = useState(null);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const params = useParams();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear());
        return `${year}-${month}-${day}`;
    };

    const getTestData = async () => {
        try {
            const formData = new FormData();
            formData.append("uuid", params.id);
            const res = await axios.post(BASEURL + "get-jobdata", formData, {
                headers: { "Content-Type": "application/json" },
            });
            if (res.data.status === 201 && res.data.data.length > 0) {
                const updatedData = {
                    ...res.data.data[0],
                    // test_video_path: `https://trackapi.Rayvat.com/uploads/screenrecording/${res.data.data[0].test_video_path}`,
                };
                setTestData(updatedData);
            } else {
                setError("No test data found");
            }
        } catch (error) {
            setError("Failed to fetch test data");
        }
    };

    const getVideos = async () => {
        if (!testData?.application_id) return; // Ensure `testData` and `application_id` are available

        try {
            const res = await axios.get(`${BASEURL}applicant-videos`, {
                params: { application_id: testData.application_id },
            });
            if (res.data.success && res.data.data.length > 0) {
                const updatedVideos = res.data.data.map((video) => ({
                    ...video,
                    video_path: `https://d1azebur1db3zi.cloudfront.net/screenrecording/${video.video_path}`,
                }));
                setVideos(updatedVideos);
            } else {
                console.error("No video data found");
            }
        } catch (error) {
            console.error("Error fetching applicant videos:", error);
            // setError("Failed to fetch videos");
        }
    };

    const openFullscreen = (videoElement) => {
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen(); // For Safari
        } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen(); // For IE/Edge
        }
    };

    useEffect(() => {
        setLoading(true);
        getTestData()
            .catch((error) => {
                console.error("Error fetching test data:", error);
                setError("Failed to fetch test data");
            })
            .finally(() => setLoading(false));
    }, [params.id]);

    useEffect(() => {
        if (testData?.application_id) {
            getVideos();
        }
    }, [testData]);

    if (loading) return <div>Loading...</div>;

    return (
        <div className="intro-y box px-5 pt-2 mt-2">
            {error && <div></div>}

            {/* Assessment Test Result Section */}
            {testData && (
            <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
			<div className="w-full lg:w-2 pr-4">
				<h2 className="font-medium text-lg">Assessment Test Result</h2>
				<div className="overflow-x-auto">
					<table className="min-w-full bg-white border border-gray-300" style={{ width: "100%", tableLayout: "auto" }}>
						<thead>
							<tr>
								<th className="border border-gray-300 p-2 text-left">Description</th>
								<th className="border border-gray-300 p-2 text-left">Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="border border-gray-300 p-2 font-medium">Total Marks</td>
								<td className="border border-gray-300 p-2">{testData?.test_outof}</td>
							</tr>
							<tr>
								<td className="border border-gray-300 p-2 font-medium">Obtained Marks</td>
								<td className="border border-gray-300 p-2">{testData?.test_result}</td>
							</tr>
							<tr>
								<td className="border border-gray-300 p-2 font-medium">Test Percentage</td>
								<td className="border border-gray-300 p-2">{testData?.test_percentage}</td>
							</tr>
							<tr>
								<td className="border border-gray-300 p-2 font-medium">Test Given Date</td>
								<td className="border border-gray-300 p-2">{formatDate(testData?.test_done_at)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		
            )}

            {/* Applicant Videos Section */}
            {videos.length > 0 && (
                <div className="mt-6">
                    <h2 className="font-medium text-lg mb-4">Applicant Videos</h2>
                    <div className="grid grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        {videos.map((video) => (
                            <div key={video.id} className="video-item">
                                <video
                                    controls
                                    className="w-full h-[200px] cursor-pointer rounded-lg shadow-lg transition-transform duration-300 ease-in-out hover:scale-105"
                                    onClick={(e) => openFullscreen(e.target)}
                                >
                                    <source src={video.video_path} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                                <p className="mt-2 text-sm text-gray-500">
                                    Uploaded At: {formatDate(video.upload_date)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* No Data Fallback */}
            {!testData && videos.length === 0 && <div>No data available</div>}
        </div>
    );
}

export default TestResult;


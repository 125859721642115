import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import Select from "react-select";
import { FaCalendar } from "react-icons/fa";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";

import ReactMonthPicker from "react-month-picker";
import reload from '../../reload.png'
import "react-month-picker/css/month-picker.css";

import Modal from "react-modal";
Modal.setAppElement("#root");

const Timeline = () => {
  const loadingContext = useLoadingContext();
  var today = new Date();
  var month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  var monthNumber = month[today.getMonth()];

  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [sidebardata, setsidebardata] = useState(null);

  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  const currmonth = monthNumber;

  const curryear = today.getFullYear();

  const [orgs, setorgs] = React.useState([]);
  const [master,] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);


  useEffect(() => {
    if (master === 1) {
      getOrganizations();
    }
    setorgval({ value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name });
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");
    getData({ year: year, month: month });

  }, []);

  const reloadFun = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");
    getData({ year: year, month: month });
  }


  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    let value = JSON.parse(localStorage.getItem("monthyear"));
    if (Object.keys(monthYear).length !== 0) {
      value = monthYear;
    }

    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");

    getData({ year: year, month: month, org });
  }

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.employee}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const columns = [
    {
      title: "Employee",
      field: "employee",
      formatter: reactFormatter(<DetailVal />),
      headerFilter: "input",
    },
    { title: "Working Time", field: "working", width: 150 },
    { title: "Idle Time", field: "idletime", width: 150 },
    { title: "Productive Time", field: "prod", width: 180 },
    {
      title: "Productive %",
      field: "prodper",
      formatter: reactFormatter(<AppUsage />),
    },
  ];

  function AppUsage(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.prodper !== undefined) {
      return (
        <>
          <div
            id="progressbar-height"
            className="p-1"
            style={{ width: "215px" }}
          >
            <div className="intro-y grid grid-cols-12 gap-5 mt-2">
              <div className="col-span-12 lg:col-span-11 2xl:col-span-10">
                <div className="preview">
                  <div className="progress h-2 mb-1 mt-2">
                    <div
                      className="progress-bar"
                      style={{ width: rowData.prodper + "%" }}
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-1 2xl:col-span-2">
                {rowData.prodper}%
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return "";
    }
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async ({ year, month, org = "" }) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-monthwise-productivity`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month,
        year,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        department: JSON.parse(localStorage.getItem("isAuth")).department,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org !== '' ? org : selectedorg,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          setattdata(res.data);

          setsidebardata(res.allarr);
        } else {
          setattdata([
            {
              employee: "No data Available",
            },
          ]);
          setsidebardata();
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
    loadingContext.done();
  };

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthname = monthNames[month - 1];
    localStorage.setItem(
      "monthyear",
      JSON.stringify({ year, monthname, month: month })
    );
    setisVisible(false);

    setmonthYear({ year, month: monthname });

    getData({ year, month });
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value
      ? value.monthname
      : monthYear && monthYear.month
        ? monthYear.month
        : 0;
    const year = value
      ? value.year
      : monthYear && monthYear.year
        ? monthYear.year
        : 0;

    var date = new Date();
    var curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-9 xl:col-span-9">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-8">
              <div className="intro-y flex items-center h-10">
                <h2 className="text-lg font-medium truncate mr-5">
                  All Employees Productivity
                </h2>
              </div>
              <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
                  <div className="intro-y box p-5">
                    <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                      <div className="flex mt-5 sm:mt-0">
                        {master === 1 &&
                          <Select
                            value={orgval}
                            className="basic-single mr-2"
                            onChange={(e) => {
                              getorg(e);
                            }}
                            options={orgs}
                            // getOptionLabel={(option) =>
                            //   ` ${option.active}   ${option.label}`
                            // }
                            styles={{
                              option: (base) => ({
                                ...base,
                                // height: "100%",
                                display: "flex",
                                textAlign: "left",
                              }),
                            }}
                          />

                        }

                        <div className="input-group">
                          <input
                            type="text"
                            style={{ fontSize: "12px" }}
                            onFocus={(e) => showMonthPicker(e)}
                            value={getMonthValue()}
                            className="form-control"
                            id="basic-url"
                            aria-describedby="basic-addon3"
                            onChange={() => { }}
                          />
                          <div className="input-group-append calendaricon">
                            <FaCalendar
                              style={{ fontSize: "12px" }}
                              onClick={(e) => showMonthPicker(e)}
                            />
                          </div>
                        </div>
                        <ReactMonthPicker
                          show={isVisible}
                          lang={[
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ]}
                          value={{ year: curryear, month: currmonth }}
                          onChange={handleOnChange}
                        />

                        <button className="ml-1 btn btn-default">
                          <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
                        </button>

                      </div>
                    </div>
                    <div className="overflow-x-auto scrollbar-hidden">
                      {loading ? (
                        <div className="text-center m-5 p-5">
                          <HashLoader
                            color="#5755d9"
                            size={30}
                            style={{ position: "absolute", right: "50%" }}
                          />
                        </div>
                      ) : (
                        <ReactTabulator
                          columns={columns}
                          data={attdata}
                          options={options}
                          className="mt-5 table-report table-report--tabulator"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 2xl:col-span-3 xl:col-span-3">
          <div className="2xl:border-l -mb-10 pb-10">
            <div className="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6">
              <div className="col-span-12 md:col-span-6 xl:col-span-12 2xl:col-span-12 mt-3 2xl:mt-8">
                <div className="intro-x flex items-center h-10">
                  <h2 className="text-lg font-medium truncate mr-5">
                    Overall Team Productivity
                  </h2>
                </div>
                <div className="mt-5">
                  <div className="intro-x">
                    <div className="box px-5 zoom-in">
                      <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                        <div className="intro-y box">
                          <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                            <small>Total Productivity</small>
                          </div>
                          {loading ? (
                            <div className="text-center m-5 p-5">
                              <HashLoader
                                color="#5755d9"
                                size={30}
                                style={{ position: "absolute", right: "50%" }}
                              />
                            </div>
                          ) : sidebardata ? (
                            <div
                              id="progressbar-height"
                              className="p-3"
                              style={{ width: "215px" }}
                            >
                              <div className="intro-y grid grid-cols-12 gap-5 mt-2">
                                <div className="col-span-12 lg:col-span-11 2xl:col-span-10">
                                  <div className="preview">
                                    <div className="progress h-2 mb-1 mt-2">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width:
                                            sidebardata &&
                                            sidebardata[0].prodper + "%",
                                        }}
                                        role="progressbar"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-12 lg:col-span-1 2xl:col-span-2">
                                  {sidebardata && sidebardata[0].prodper}%
                                </div>
                              </div>
                            </div>
                          ) : (
                            "No data availble"
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="box px-5 mt-2 zoom-in">
                      <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                        <div className="intro-y box">
                          <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                            <small>Average Idle Time</small>
                          </div>
                          {loading ? (
                            <div className="text-center m-5 p-5">
                              <HashLoader
                                color="#5755d9"
                                size={30}
                                style={{ position: "absolute", right: "50%" }}
                              />
                            </div>
                          ) : sidebardata ? (
                            <div
                              id="progressbar-height"
                              className="p-3"
                              style={{ width: "215px" }}
                            >
                              <div className="intro-y grid grid-cols-12 gap-5 mt-2">
                                <div className="col-span-12 lg:col-span-11 2xl:col-span-10">
                                  <div className="preview">
                                    <div className="progress h-2 mb-1 mt-2">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width:
                                            sidebardata &&
                                            sidebardata[0].idleper + "%",
                                        }}
                                        role="progressbar"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-12 lg:col-span-1 2xl:col-span-2">
                                  {sidebardata && sidebardata[0].idleper}%
                                </div>
                              </div>
                            </div>
                          ) : (
                            "No data availble"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;

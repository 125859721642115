import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PopupMenu } from "react-simple-widgets";
import swal from "sweetalert";
import { BsDownload, BsWindows, BsApple, BsPhone } from "react-icons/bs";
import { FaPhone, FaSignInAlt } from "react-icons/fa";
import { FcLinux } from "react-icons/fc";
import { RxAvatar } from "react-icons/rx";
import { SITEURL, BASEURL } from "./BASEURL";
import { useSelector, useDispatch } from 'react-redux';
import ringTone from "./../sounds/ringtone.mp3";
import useCreateUa from '../hooks/useCreateUa'
import useDisconnectUa from '../hooks/useDisconnectUa'
// import { pcsRegister } from '../redux/reducers/Auth/authService'
import useCallHangup from '../hooks/useCallHangup';
import useCallTimerDuration from '../hooks/useCallTimerDuration';
import {
  setCallCancel,
} from "../redux/reducers/sipjs/sipjsSlice";
import { acceptIncomingCall, rejectIncomingCall } from "../redux/reducers/sipjs/sipjsService";
// import Jenya from '../agent/Jenya-App_3.0.3_amd64.deb';
// import Jenya_w from '../agent/Jenya Tracking Setup 3.0.5.exe';

import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "60%",
    padding: "0%",
    borderRadius: "10px",
  },
};



const Header = (props) => {
  const isAuth = JSON.parse(localStorage.getItem("isAuth"));
  // console.log(isAuth);
  let navigate = useNavigate();
  const location = useLocation();
  const [path, setpath] = useState("");

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let [issigninbyadmin,] = useState(localStorage.getItem("isAuth") && JSON.parse(localStorage.getItem("isAuth")).issigninbyadmin);
  const [master,] = React.useState(localStorage.getItem("isAuth") && JSON.parse(localStorage.getItem("isAuth")).master);
  const [role,] = React.useState(localStorage.getItem("isAuth") && JSON.parse(localStorage.getItem("isAuth")).role);
  const empName = useSelector(state => state.auth.currentEmp)
  const callHangup = useCallHangup();

  const disconnectUA = useDisconnectUa();

  const dispatch = useDispatch();
  const [createUA, showIncomingCallScreen, setShowIncomingCallScreen, setSessions] = useCreateUa();
  const { connected, regState, connectingStatus, callDirection, sessionId, sessions, incomingUser, callCancel } = useSelector((state) => state.sipjs);
  const { isTimer } = useSelector((state) => state.theme);
  const [calltimer, TimerAction] = useCallTimerDuration()
  const [accountCreds,] = useState(JSON.parse(localStorage.getItem("_accountCreds")));

  useEffect(() => {
    // console.log(regState);
    // console.debug("regState");
    // console.debug(regState);
    if (isTimer == "1") {
      TimerAction('start')
    }
    if (isTimer == "2") {
      TimerAction('reset')
    }
  }, [isTimer]);

  const toggleClass = () => {
    let _userAccount = document.getElementById('_userAccount')
    setTimeout(() => {
      if (regState == "Registered") {
        _userAccount.classList.add('active')
      } else {
        _userAccount.classList.remove('active')
      }
    }, 500)
  }

  const hangupcall = () => {
    dispatch(setCallCancel(1))
    // console.log(sessions);
    callHangup(sessions[sessionId], 'incoming')
  }

  const acceptcall = () => {
    acceptIncomingCall(sessions[sessionId]);
    setShowIncomingCallScreen(false)
    // dispatch(hideDialpadeSection())
    // dispatch(showCallingScreeenFun())

  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const connect = async () => {
    createUA();
  };


  useEffect(() => {
    // console.debug(accountCreds?._sipExtension);
    if (role !== 2) {
      if (accountCreds?._sipExtension != "" && accountCreds?._sipPassword != "" && accountCreds?.__sipServer != "" && role !== 2) {
        connect();
      }
    }
  }, [])

  useEffect(() => {
    // toggleHanddlerConnect()
    const myArray = location.pathname.split("/");
    const path11 =
      (myArray[1] ? myArray[1].charAt(0).toUpperCase() : null) +
      (myArray[1] ? myArray[1].slice(1) : null);
    const path22 =
      (myArray[2] ? myArray[2].charAt(0).toUpperCase() : null) +
      (myArray[2] ? myArray[2].slice(1) : null);

    document.title = `Jenya  ${path11 ? `| ${path11}` : ""}  ${path22 ? `| ${path22}` : ""
      }`;

    setpath(myArray);
  }, [location.pathname]);

  const logout = (e) => {
    swal({
      title: "Logout",
      text: `Are you sure you want to logout?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {

        if (localStorage.getItem('isclient') === "1") {
          navigate("/client-login");
        } else {
          navigate("/login");
        }
        localStorage.clear();
        props.isLogout();
      }
    });
  };

  const SignInasAdmin = async () => {
    swal({
      title: "Sign In back Master Admin",
      text: `Are you sure you want to Sign In back Master Admin?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        // console.log(data.companyuuid); return
        // window.open(SITEURL, '_blank', 'incognito=yes');
        let authdata = JSON.parse(localStorage.getItem("isAuth_bkp"));
        if (authdata != null) {
          localStorage.setItem("isAuth", JSON.stringify(authdata));
          window.open(SITEURL, "_self");
        }

      }
    });

  }


  return (
    <>
      <div className="top-bar">
        <nav
          aria-label="breadcrumb"
          className="-intro-x mr-auto hidden sm:flex"
        >
          {/* <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            {path[1] ? (
              <li className="p-2 breadcrumb-item">
                <Link to={path}>
                  {path[1].charAt(0).toUpperCase() + path[1].slice(1)}
                </Link>
              </li>
            ) : null}
            {path[2] ? (
              <li className="p-2 breadcrumb-item">
                <Link to={path}>
                  {
                    path[1] == "employees" ?
                      empName : path[2].charAt(0).toUpperCase() + path[2].slice(1)}
                </Link>
              </li>
            ) : null}
          </ol> */}
        </nav>
        {issigninbyadmin !== undefined && issigninbyadmin === 1 &&
          <button
            className="btn btn-primary btn-sm mx-1 invisible sm:visible"
            onClick={SignInasAdmin}
          >
            <FaSignInAlt className="mx-2" />
            Sign In Back {master == 1 ? " Master" : null} to Admin
          </button>
        }

        {/* <div className="flex flex-col sm:flex-row sm:items-end xl:mt-5 items-start">
          <div className="flex xl:mt-5">
            <div className="sm:flex items-center sm:mr-5 xl:mt-0">
              {incomingUser != null && callCancel === 0 ? <>
                <div
                  className="btn btn-primary mx-3 btn-sm invisible sm:visible"
                >
                  <span className="headercallno">Unknown <br /><span className="headerfont">{incomingUser}</span></span>
                  {isTimer === 1 ? <h2 style={{ fontSize: "15px", marginRight: "10px" }}>{calltimer}</h2> :
                    <span className="headercallincoming" onClick={acceptcall}><FaPhone className="mx-2" /></span>
                  }
                  <span className="headercallhangup" onClick={hangupcall}><FaPhone className="mx-2" /></span>
                </div>
              </> : null}

            </div>
          </div>
          <div className="flex lg:mt-5 lg:pt-3 sm:mt-0 sm:pt-2 mb-3"> */}
        {/* <div className="sm:flex items-center sm:mr-4 xl:mt-0"> */}
        {/* {localStorage.getItem('isclient') !== "1" &&
              <button
                className="btn btn-primary mx-3 btn-sm invisible sm:visible"
                onClick={openModal}
              >
                <BsDownload className="mx-2" />
                Download Agent
              </button>} */}
        {/* </div>           */}
        {/* </div>
        </div> */}
        <PopupMenu>
          <div
            className="dropdown-toggle w-8 h-8 rounded-full  shadow-lg image-fit zoom-in scale-110"
            role="button"
            aria-expanded="false"
            data-tw-toggle="dropdown"
          >
            {isAuth && isAuth.avatar !== "" && isAuth.avatar !== null && localStorage.getItem('isclient') !== "1" ? (
              <div className="icon-badge-container">
                <img
                  alt="icon"
                  style={{ borderRadius: "50%" }}
                  src={`https://portal.rayvat.com/assets/uploads/avatar/${isAuth.avatar}`}
                />
                {localStorage.getItem('isclient') !== "1" ? regState === "Registered" ?
                  <div className="icon-badge bg-success"></div>
                  : <div className="icon-badge bg-danger"></div> : ""}
              </div>
            ) : (
              <RxAvatar style={{ height: "100%", width: "100%" }} />
            )}
          </div>

          <div className="card text-start intro-x dropdown dropmenu">
            <div className="card-body px-4 py-4">
              <div id="circle-avatar" className="text-center mx-auto mb-4">
                <span>{isAuth && isAuth.name && isAuth.name.substring(0, 1)}</span>
              </div>
              <ul className="">
                <li className="p-2">
                  <div className="font-medium">{isAuth && isAuth.name}</div>
                  <div className="text-xs text-white/60 mt-0.5 dark:text-slate-500">
                    {isAuth && isAuth.dept}
                  </div>
                </li>
                <li className="pb-2">
                  <hr className="dropdown-divider border-white/[0.08]" />
                </li>
                {localStorage.getItem('isclient') !== "1" &&
                  <li className="pb-2 cursor-pointer">
                    <Link to={"/employees/" + isAuth.uuid}>
                      <i data-lucide="lock" className="w-4 h-4 mr-2"></i> Profile
                    </Link>
                  </li>
                }
                <li className="pb-2 cursor-pointer" onClick={logout}>
                  <i data-lucide="lock" className="w-4 h-4 mr-2"></i> Logout
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </PopupMenu>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="row header sticky z-50"
          style={{ padding: "9px", background: "#1e40af", color: "white" }}
        >
          <div className="flex items-center">
            <h2 className="font-medium text-base mr-auto">Download</h2>
            <button
              className=" hidden sm:flex"
              style={{
                border: "1px solid white",
                padding: "2px 10px",
                borderRadius: "5px",
              }}
              onClick={() => closeModal()}
            >
              <span style={{ color: "white", fontSize: "8px" }}> &#9587;</span>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="box p-5 mx-2 flex  flex-col items-center">
            <div className="m-0 p-0">
              <span style={{ color: "#0078d4", fontSize: "65px" }}>
                <BsWindows />
              </span>
            </div>
            <div className="mt-4">Windows</div>
            <div className="mt-5">
              <a
                className="btn btn-primary px-3 py-1 "
                href="https://portal.rayvat.com/JenyaTrack.exe"
                download
                target="_blank" rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
          <div className="box p-5 mx-2 flex  flex-col items-center">
            <div className="m-0 p-0">
              <span style={{ fontSize: "65px" }}>
                <BsApple />
              </span>
            </div>
            <div className="mt-4">macOS </div>
            {/* <div className="mt-5">
              <button className="btn btn-primary px-3 py-1 ">Download</button>
            </div> */}
          </div>
          <div className="box p-5 mx-2 flex  flex-col items-center">
            <div className="m-0 p-0">
              <span style={{ fontSize: "80px" }}>
                <FcLinux />
              </span>
            </div>
            <div className=""> Linux </div>
            <div className="mt-5">
              {/* <a
                className="btn btn-primary px-3 py-1 "
                href={Jenya}
                download
                target="_blank" rel="noreferrer"
              >
                Download
              </a> */}
            </div>
          </div>
        </div>
      </Modal>


      <audio
        controls="controls"
        id="mediaElement"
        className="d-none"
      ></audio>
      <audio
        id="connectinfRigton"
        controls="controls"
        src={ringTone}
        loop
        className="d-none"
      />
    </>
  );
};

export default Header;

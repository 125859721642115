import React, { useEffect } from "react";
import { FaCalendar, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
const OtherBreakTicket = () => {
	const [addShow, setaddShow] = useState(false);
	const [showTicket, setShowticket] = useState([]);
	const [hidedate, setHideDate] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [otherData, setOtherData] = useState({
		break_start_time: "",
		break_end_time: "",
		reason: "",
	});
	const [userdata] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const [updateShow, setUpdateShow] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [punchData, setPunchData] = useState([]);
	const [data, setdata] = useState({
		date: format(new Date(), "yyyy-MM-dd"),
		punch_start_time: "00:00:00",
		punch_end_time: "00:00:00",
		lunch_start_time: "00:00:00",
		lunch_end_time: "00:00:00",
		reason: "",
		status: 0,
	});
	const [ticket, setTicket] = useState([]);

	const handleEdit = (ticket) => {
		setOtherData({
			break_start_time: ticket.break_start_time,
			break_end_time: ticket.break_end_time,
			reason: ticket.reason,
		});
		console.log("Response from the Other Data Funcation", otherData);
		setIsModalOpen(true);
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setdata({ ...data, date: format(date, "yyyy-MM-dd") });
	};
	const fetchPunchInData = async () => {
		const formattedDate = format(selectedDate, "yyyy-MM-dd");
		try {
			const headers = {
				"Content-Type": "application/json",
			};
			if (userdata?.usertoken) {
				headers["Authorization"] = userdata.usertoken;
			}
			const response = await fetch(
				BASEURL + "get-punch-in-data-otherbreak",
				{
					method: "POST",
					headers: headers,
					body: JSON.stringify({
						date: formattedDate,
						username: userdata.username,
					}),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				const breakInfo = responseData?.breakData || [];
				if (responseData.status === 1) {
					setTicket([]);
					setOtherData({
						break_start_time: "00:00:00",
						break_end_time: "00:00:00",
						reason: "",
					});
					setIsModalOpen(true); // Open the form modal
				} else {
					// Data exists, populate the table
					const formattedBreakData = breakInfo.map((breakItem) => ({
						date: formattedDate,
						break_start_time:
							breakItem.break_start_time || "00:00:00",
						break_end_time: breakItem.break_end_time || "00:00:00",
						reason: "",
						status: 0,
					}));
					setTicket(formattedBreakData);
					setIsModalOpen(false); // Ensure the form is closed
				}
				// swal(
				// 	"Success",
				// 	"Punch-in data fetched successfully!",
				// 	"success"
				// );
			} else {
				throw new Error("Failed to fetch punch-in data.");
			}
		} catch (error) {
			swal(
				"Error",
				"Could not fetch punch-in data. Try again later.",
				"error"
			);
		}
	};

	const handleAdd = () => {
		setaddShow(true);
		setUpdateShow(false);
	};
	const handleCancel = () => {
		setaddShow(false);
		setUpdateShow(false);
		setdata({
			date: "",
			punch_start_time: "00:00:00",
			punch_end_time: "00:00:00",
			lunch_start_time: "00:00:00",
			lunch_end_time: "00:00:00",
			reason: "",
			status: 0,
		});
	};
	const handlechange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isAuth = JSON.parse(localStorage.getItem("isAuth"));
		const { name } = isAuth || {};
		const formattedDate = format(selectedDate, "yyyy-MM-dd");

		const payload = {
			username: userdata.username,
			data: {
				date: formattedDate,
				break_start_time: otherData.break_start_time,
				break_end_time: otherData.break_end_time,
				reason: otherData.reason,
				status: 0,
				name,
			},
		};
		console.log("Payload being sent:", payload);
		try {
			const headers = {
				"Content-Type": "application/json",
			};
			if (userdata?.usertoken) {
				headers["Authorization"] = userdata.usertoken;
			}

			const response = await fetch(
				BASEURL + "add-emp-ticket-otherbreak",
				{
					method: "POST",
					headers: headers,
					body: JSON.stringify(payload),
				}
			);

			const responseData = await response.json();

			if (response.ok) {
				console.log("Response received:", responseData);
				swal("Success", responseData.message, "success");
				setShowForm(false);
				GetempTicket();
				handleCancel();
			} else {
				console.error("Error response:", responseData);
				throw new Error(responseData.error || "Failed to save ticket");
			}
		} catch (error) {
			console.error("Error during form submission:", error);
			swal("Error", error.message, "error");
		}
	};



	const GetempTicket = async () => {
		try {
			const response = await fetch(BASEURL + "show-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: userdata.username,
					role: userdata.role,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log("Response from the API:", data);
				const filteredTickets = data?.data?.filter(
					(ticket) =>
						ticket.username === parseInt(userdata.username, 10)
				);
				console.log("Filtered tickets:", filteredTickets);
				setShowticket(filteredTickets);
			} else {
				throw new Error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const handleDelete = (id) => {
		swal({
			title: "Delete",
			text: `Are you sure you want to delete you ticket?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				fetch(BASEURL + `delete-emp-ticket/${id}`, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((response) => response.json())
					.then((res) => {
						// GetempTicket();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
			}
		});
	};
	const editdata = (ticket) => {
		console.log("editing data of emp id", ticket);
		setaddShow(true);
		setUpdateShow(true);
		setdata({
			id: ticket.id,
			date: ticket.date,
			punch_start_time: ticket.punch_start_time,
			punch_end_time: ticket.punch_end_time,
			lunch_start_time: ticket.lunch_start_time,
			lunch_end_time: ticket.lunch_end_time,
			reason: ticket.reason,
			status: 0,
		});
	};
	const UpdateTicket = async (id) => {
		console.log("updating ticket id : " + id);
		try {
			const response = await fetch(BASEURL + `update-emp-ticket/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const data = await response.json();
			if (response) {
				Swal.fire({
					icon: "success",
					title: "Ticket updated successfully",
					showConfirmButton: false,
					timer: 1000,
				});
				GetempTicket();
			} else {
				console.log("Error your leave request is not updated");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};
	useEffect(() => {
		GetempTicket();
	}, []);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataPerPage] = useState(10);
	const indexOfLastData = (currentPage + 1) * dataPerPage;
	const indexOfFirstData = indexOfLastData - dataPerPage;
	const currentData = showTicket.slice(indexOfFirstData, indexOfLastData);
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">
					Other Break Ticket
				</h2>
				{/* <button
					className="btn btn-primary btn-md mr-2"
					onClick={() => handleAdd()}
				>
					Add Other Break Ticket
				</button> */}
			</div>
			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{addShow === true ? (

						""
					) : null}
					<div className="flex" style={{ width: "25%" }}>
						<DatePicker
							value={selectedDate}
							onChange={handleDateChange}
							calendarIcon={
								<FaCalendar
									style={{ color: "rgb(30 64 175)" }}
								/>
							}
							clearIcon=""
							format="y-MM-dd"
							className="form-control"
							style={{ width: "100%" }}
						/>
						<button
							onClick={fetchPunchInData}
							className="btn btn-success"
							style={{
								backgroundColor: "#00b400",
								color: "white",
								marginLeft: "10px",
							}}
						>
							Get
						</button>
					</div>
				</div>
				<br />

				<div>
					{isModalOpen ? (
						<div
							className="flex flex-col-reverse  flex-col text-left"
							style={{
								border: "1px solid #ccc",
								padding: "25px",
								borderRadius: "8px",
							}}
						>
							<form method="POST" onSubmit={handleSubmit}>
								<div className="flex flex-wrap gap-6 mt-3 mb-3">
									<div className="flex-1">
										<label
											htmlFor="update-profile-form-3"
											className="form-label"
											style={{ textAlign: "left" }}
										>
											Other Break Start Time{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-3"
											type="text"
											required
											name="break_start_time"
											className="form-control"
											onChange={(e) =>
												setOtherData({
													...otherData,
													break_start_time:
														e.target.value,
												})
											}
											value={otherData.break_start_time}
										/>
									</div>

									<div className="flex-1">
										<label
											htmlFor="update-profile-form-4"
											className="form-label"
											style={{ textAlign: "left" }}
										>
											Other Break End Time{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-4"
											type="text"
											required
											name="break_end_time"
											className="form-control"
											onChange={(e) =>
												setOtherData({
													...otherData,
													break_end_time:
														e.target.value,
												})
											}
											value={otherData.break_end_time}
										/>
									</div>
								</div>

								<div className="col-span-12 2xl:col-span-3">
									<label
										htmlFor="update-profile-form-2"
										className="form-label"
										style={{ textAlign: "left" }}
									>
										Reason{" "}
										<span className="text-danger">(*)</span>
									</label>
									<textarea
										name="reason"
										className="form-control"
										required
										cols="80"
										rows="2"
										onChange={(e) =>
											setOtherData({
												...otherData,
												reason: e.target.value,
											})
										}
										value={otherData.reason}
									></textarea>
								</div>

								<div className="col-span-12 2xl:col-span-3">
									<button
										type="submit"
										className="btn btn-primary w-30"
										style={{ marginRight: "10px" }}
									>
										Create
									</button>
									<button
										type="button"
										onClick={() => setIsModalOpen(false)}
										className="btn btn-danger w-20"
									>
										Cancel
									</button>
								</div>
							</form>
						</div>
					) : (
						// Render the table
						<div className="overflow-x-auto">

							<table
								className="table table-bordered"
								style={{ width: "100%" }}
							>
								<thead>
									<tr>
										<th className="whitespace-nowrap">
											Date
										</th>
										<th className="whitespace-nowrap">
											Other Break Start
										</th>
										<th className="whitespace-nowrap">
											Other Break End
										</th>
										<th className="whitespace-nowrap">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{Array.isArray(ticket) &&
										ticket.length > 0 ? (
										ticket.map((ticket, index) => (
											<tr key={index}>
												<td>
													{format(
														new Date(ticket.date),
														"yyyy-MM-dd"
													)}
												</td>
												<td>
													{ticket.break_start_time}
												</td>
												<td>{ticket.break_end_time}</td>
												<td>
													<div className="flex">
														<FaEdit
															className="text-primary ml-2"
															onClick={() =>
																handleEdit(
																	ticket
																)
															}
														/>
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td
												colSpan="4"
												style={{ textAlign: "center" }}
											>
												No break data available.
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default OtherBreakTicket;

import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { FaCalendar } from "react-icons/fa";
import { HashLoader } from 'react-spinners';
import SummaryChart from "../dashboard/SummaryChart";
import { BASEURL } from '../BASEURL';
import { format } from 'date-fns';
import { useLoadingContext } from 'react-router-loading';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import SummaryHoursWiseProductivity from "./SummaryHoursWiseProductivity";
import ChartMostAppUsed from "./ChartMostAppUsed";
import SummaryApplications from './SummaryApplications';
import SidebarChart from "./appChart";

// import { PieChart } from 'react-minimal-pie-chart';

function Productivity() {
    const today = new Date();
    const formattedToday = format(today, 'yyyy-MM-dd');
    const loadingContext = useLoadingContext();

    const [chart3, setchart3] = useState([]);
    const [isAuth, setIsAuth] = useState(
        JSON.parse(localStorage.getItem("isAuth"))
    );
      const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [username, setusername] = useState("");
    const [selectedorg, setselectedorg] = React.useState(
        JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
    );
    const [loading1, setLoading1] = useState(false);
    const [chart2, setchart2] = useState([]);
    const [info, setInfo] = useState(null);
    const [currdate] = useState(new Date());
    const [userdatalist, setuserdata] = useState([]);
    const [value, onChange] = useState([currdate, currdate]);
    const [alluser, setalluser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const user_data = JSON.parse(localStorage.getItem("isAuth")); // Parse the user data first
    const userUuid = user_data?.uuid; // Extract uuid from the parsed data
    const userType = user_data?.type;
    const [mostapphrs, setmostapphrs] = useState([]);
    const [mostapp, setmostapp] = useState([]);
      const [appdata, setappdata] = useState([]);
    

    const [selectedMonth, setSelectedMonth] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
    const [data, setData] = useState([
        { value: "", label: "Select All", active: "", uuid: "" },
    ]);
    const [selectedval, setselectedval] = useState(
        userType !== 0 ? { uuid: userUuid } : ""
    );
    const [allpresent, setallpresent] = useState([]);
    const [userNameseelcted, setUserNameseelcted] = useState();
    // const handleOnChange = async (year, month) => {
    //     setSelectedMonth({ year, month });
    //     setIsVisible(false); // Close the picker after selection
    // };

    // const showMonthPicker = (e) => {
    //     e.preventDefault();
    //     setIsVisible(true); // Show the month picker
    // };

    // const handleOnDismiss = () => {
    //     setIsVisible(false); // Close the picker without selection
    // };

    // const getMonthValue = () => {
    //     // Format selected month and year for display in the input
    //     return `${selectedMonth.month}/${selectedMonth.year}`;
    // };

    console.log(info, 'info')

    useEffect(() => {
        if (userType === 0) {
            // getdepartments();
            getUserData();
        }
    }, []);

    const getUserData = async (org = "", departmentId = "") => {
        setLoading(true);
        const myHeaders = new Headers();
        const userdata = JSON.parse(localStorage.getItem("isAuth"));

        if (userdata !== "00:00:00") {
            const token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(BASEURL + `admin-getAllUsers/`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    type: 1,
                    uuid: org || selectedorg, // Use `org` or default to `selectedorg`
                    usertype: userdata.type,
                    department: departmentId || "", // Use department ID or default to all
                }),
            });

            if (!response.ok) {
                throw new Error(
                    `Error: ${response.status} - ${response.statusText}`
                );
            }

            const res = await response.json();

            if (res.status === 201) {
                // Filter out users from department 34
                // const filteredData = res.data.filter((user) => user.department !== 34);
                const filteredData = res.data.filter(
                    (item) => item.department !== 34 && item.username !== "1119"
                );

                // Update user data state with filtered data
                setuserdata(filteredData); // Update user data state
                setalluser(res.alluser); // Update all users state

                // Prepare dropdown options
                const userOptions = filteredData.map((user) => ({
                    value: user.username,
                    label: user.name,
                    active: user.active ? "🟢" : "🔴",
                    uuid: user.uuid,
                }));

                // Add a default option at the beginning of the list
                userOptions.unshift({
                    value: "",
                    label: "Select Employee",
                    active: "",
                });

                // Update select options
                setData(userOptions);

                // Set the default selected username if data is available
                if (res.alluser.length > 0 && filteredData.length > 0) {
                    setusername(res.alluser[0]["username"]);
                }
            }

            setLoading(false);
        } catch (err) {
            console.error("Error fetching user data:", err.message);
            setLoading(false);
        } finally {
            loadingContext.done();
        }
    };

    const handleDateChange = (dates) => {
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        // console.log(userdata, 'userdata')
        onChange(dates); // Update selected date range
        const [startDate, endDate] = dates;
        setDate(dates[0])
        const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
        const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

        const startDateObj = new Date(startDate);
        const year = startDateObj.getFullYear();
        const month = startDateObj.getMonth() + 1; 
        
        // Call getData with the selected date range
        getSummaryProductivity()
        getDateWise(userNameseelcted, formattedStartDate, formattedEndDate);
        getMonthWise({ year, month})
        getchartdata({ year, month })
        getSummaryApplications();
        // getChartdataapp(startDate, userNameseelcted)
    };

    const getDateWise = async (username, date, ldate, org = "") => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
            let token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        await fetch(BASEURL + `get-datewise-productivity`, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                username,
                date,
                ldate,
                company_uuid: org !== "" ? org : selectedorg,
                master: JSON.parse(localStorage.getItem("isAuth")).master,
                usertype: JSON.parse(localStorage.getItem("isAuth")).type,
                department: JSON.parse(localStorage.getItem("isAuth"))
                    .department,
            }),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === 201) {
                    setInfo(res?.actinfo)
                    setchart3(res.totalprodchart);
                    setchart2(res.totalidlechart);
                } else {
                    setInfo()
                    setchart3([]);
                    setchart2([]);
                }
            })
            .catch((err) => console.log(err));
        setLoading(false);
    };

     const getMonthWise = async ({ year, month, org = "" }) => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append('Authorization', token);
        }
        myHeaders.append('Content-Type', 'application/json');
        await fetch(BASEURL + `get-monthwise-productivity`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            month: month, year,
            uuid: org !== '' ? org : selectedorg,
            usertype: JSON.parse(localStorage.getItem("isAuth")).type,
            department: JSON.parse(localStorage.getItem("isAuth")).department,
            // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
          })
    
        }).then(response => response.json()).then(res => {
          if (res.status === 201) {
            // setattdata(res.data)
            // console.log(res.allarr[0].working);
            // setallhrs(res.allarr[0].working)
          } else {
            // setallpresent([]);
            // setattdata([
            //   {
            //     employee: "No data Available",
            //   }
            // ])
          }
        }).catch(err => console.log(err));
        setLoading(false);
      }

    const getchartdata = async ({ year, month, org = "" }) => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
            let token = userdata.usertoken;
            myHeaders.append('Authorization', token);
        }
        myHeaders.append('Content-Type', 'application/json');
        await fetch(BASEURL + `get-chartdata`, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                month: month, year,
                uuid: org !== '' ? org : selectedorg,
                usertype: JSON.parse(localStorage.getItem("isAuth")).type,
                department: JSON.parse(localStorage.getItem("isAuth")).department,

                // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
            })

        }).then(response => response.json()).then(res => {
            if (res.status === 201) {
                let allpresent = res.attdata[0].value.reduce((a, b) => a + b, 0)
                // let allhrs = res.hrsdata[0].value.reduce((a,b)=>a+Number(b),0)
                setallpresent(allpresent);
                // allhrs = allhrs*60;


                // let hours = (allhrs / 60);
                // let rhours = Math.floor(hours);
                // if(rhours<10) rhours = '0'+rhours; 
                // let minutes = (hours - rhours) * 60;
                // let rminutes = Math.round(minutes);
                // if(rminutes<10) rminutes = '0'+rminutes; 
                // if(isNaN(rhours)!==true && isNaN(rminutes)!==true){
                //     setallhrs(rhours + ":" + rminutes + ":00");
                // }else{
                //     setallhrs("00:00:00");
                // }
                // console.log(res.attdata);
                // setchart1(res.attdata)
                // setchart2(res.hrsdata)
            } else {
                // setchart1();
                // setchart2();
                // setallhrs([]);
            }
        }).catch(err => console.log(err));
        setLoading(false);
        loadingContext.done();
    }

    //  const getChartdataapp = async (paramdate, username) => {
    //     setLoading1(true);
    //     const myHeaders = new Headers();
    //     let userdata = JSON.parse(localStorage.getItem("isAuth"));
    //     if (userdata != null) {
    //       let token = userdata.usertoken;
    //       myHeaders.append("Authorization", token);
    //     }
    //     myHeaders.append("Content-Type", "application/json");
    //     await fetch(BASEURL + `get-AppChartData/`, {
    //       method: "POST",
    //       headers: myHeaders,
    //       body: JSON.stringify({
    //         date: paramdate,
    //         username,
    //         uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
    //       }),
    //     })
    //       .then((response) => response.json())
    //       .then((res) => {
    //         if (res.status === 201) {
    //           setmostapp(res.apphourwisearr);
    //           setmostapphrs(res.hourarr);
    //           ChartMostAppUsedfun();
    //         } else {
    //           setmostapphrs([]);
    //           setmostapp([]);
    //         }
    //         setLoading1(false);
    //       })
    //       .catch((err) => console.log(err));
    
    //   };
    

    const getSummaryProductivity = (name="", newdate="") => {
		// if(selusername==""){
		//  let username = username;
		// }
		// if(seldate==""){
		//  let date = date;
		// }
		// console.log(username);
		return <SummaryHoursWiseProductivity username={name ? name : username} date={date} />;
	};


    // const ChartMostAppUsedfun = () => {
    //     return <ChartMostAppUsed hrs={mostapphrs} data={mostapp} />;
    //   };

    const getAppChart = () => {
        let bkpappdata = [...appdata];
        let bkpappdata1 = [...appdata];
        let finaldata = bkpappdata.slice(0, 3);
        // console.log(bkpappdata); return
        let otherdata = bkpappdata1.splice(3);
        let othertotal = otherdata.reduce((a, b) => a + Number(b.usage), 0);
        let first = finaldata.map((a, b) => Number(a.usage));
        let appname = finaldata.map((a, b) => a.appname);
        first.push(othertotal);
        appname.push("Other");
        // console.log(appname);
        // console.log(first); return
        let data = [{ appname: appname, value: first }];
    
        return <SidebarChart data={data} />;
      };

    const getappdata = (data) => {
        setappdata(data);
        getAppChart();
      };

    const getSummaryApplications = () => {
        console.log("username",username, "date", date, "getappdata", getappdata)
        return (
          <SummaryApplications
            getappdata={getappdata}
            username={userNameseelcted}
            date={date}
          />
        );
      };

    useEffect(() => {
        const fetchData = async () => {
            const isAuth = JSON.parse(localStorage.getItem("isAuth")); // Assuming `isAuth` is stored in localStorage
            getSummaryApplications();
    
            // Extract formatted start and end dates
            const formattedStartDate = format(new Date(value[0]), "yyyy-MM-dd");
            const formattedEndDate = format(new Date(value[1]), "yyyy-MM-dd");
    
            if (isAuth.role === 2) {
                setusername(isAuth.username);
                getDateWise(isAuth.username, formattedStartDate, formattedEndDate);
            // getChartdataapp(formattedStartDate, isAuth.username)
            } else {
                getDateWise(username, formattedStartDate, formattedEndDate);
            // getChartdataapp(formattedStartDate)
            }
    
            // Extract year and month from the start date
            const startDateObj = new Date(value[0]);
            const year = startDateObj.getFullYear();
            const month = startDateObj.getMonth() + 1; // getMonth is zero-based
    
            // Call getMonthWise with year and month
            getMonthWise({ year, month });
            getchartdata({ year, month })
    
            // Mark loading as done
            await loadingContext.done();
        };
    
        fetchData();
    }, [value, username, loadingContext]);    

    return (
        <>
            <div className='my-2'>
                <div className='flex justify-between mt-2' style={{
                    justifyContent: 'space-between'
                }}>
                    <div className=''>
                        <Select
                            value={selectedval?.value || ""}
                            onChange={(e) => {
                                const selectedUser = data?.find((item) => item?.value === e.target.value);
                                const selectedValue = selectedUser?.value;
                            
                                // Update selected user
                                setUserNameseelcted(selectedValue);
                                getSummaryProductivity(selectedUser?.value)
                                getSummaryApplications();
                            
                                // Call getDateWise with current date
                                getDateWise(selectedValue, currdate, currdate);
                            
                                // Extract year and month from currdate
                                const currDateObj = new Date(currdate);
                                const year = currDateObj.getFullYear();
                                const month = currDateObj.getMonth() + 1; // getMonth is zero-based
                            
                                // Call getMonthWise with year and month
                                getMonthWise({ year, month });
                                getchartdata({ year, month })
                                // Update selected value state
                                setselectedval(selectedUser || null);
                            }}
                            
                            size="small"
                            displayEmpty
                            style={{
                                minWidth: "150px",
                                marginRight: "1rem",
                                padding: "0px",
                                fontSize: "12px", // Reduced padding
                            }}
                        >
                            <MenuItem value="" style={{ color: "#24a184" }}>
                                Select Employee
                            </MenuItem>
                            {data.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div className="input-group mr-2 ">
                            {/* <input
                                type="text"
                                style={{ fontSize: "12px", backgroundColor: "transparent" }}
                                onFocus={(e) => showMonthPicker(e)}
                                value={getMonthValue()}
                                className="form-control calenderdate1"
                                id="basic-url"
                                aria-describedby="basic-addon3"
                                onChange={() => { }}
                            />
                            <div className="input-group-append calendaricon">
                                <FaCalendar
                                    style={{
                                        fontSize: "12px",
                                    }}
                                    onClick={(e) => showMonthPicker(e)}
                                />
                            </div> */}
                            {/* <ReactMonthPicker
                                show={isVisible}
                                lang={[
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                ]}
                                value={selectedMonth}
                                onChange={handleOnChange}
                                onDismiss={handleOnDismiss}
                            /> */}

                            <DateRangePicker
                                clearIcon=""
                                calendarIcon={
                                    <FaCalendar
                                        style={{
                                            color: "rgb(30 64 175)",
                                        }}
                                    />
                                }
                                // className="desktop-datepicker"
                                onChange={handleDateChange}
                                value={value}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-12 mt-2">
                    <div className="" style={{
                        border: "1px solid #24A184",
                        borderRadius: "5px",
                    }}>
                        <h2
                            style={{
                                background: "#1D46AC",
                                borderRadius: "2px",
                                fontSize: "15px",
                                padding: "10px",
                                fontWeight: 500,
                                color: "#fff",
                                textAlign: "start",
                            }}
                        >
                            Summary
                        </h2>
                        <div>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 gap-6" style={{

                                }}>
                                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
                                        <div className="col-span-12 mt-0">
                                            <div className="col-span-12 sm:col-span-12 xl:col-span-6 ">
                                                <div className='col-span-12 flex'>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px",
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Total Present EMP
                                                            </h6>
                                                            <h6>
                                                            {allpresent && allpresent > 0 ? allpresent : 0} Count
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-8'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "300px"
                                                        }}>
                                                            <h6
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}>
                                                                Total Working Hrs For This Month
                                                            </h6>
                                                            <h6>
                                                                00h:00m:00s
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-12 sm:col-span-12 xl:col-span-6 ">
                                                <div className='col-span-12 flex'>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Working Time
                                                            </h6>
                                                            <h6>
                                                                00h:00m:00s
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Productive Time
                                                            </h6>
                                                            <h6>
                                                                00h:00m:00s
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Idle Time
                                                            </h6>
                                                            <h6>
                                                                00h:00m:00s
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-12 sm:col-span-12 xl:col-span-6 ">
                                                <div className='col-span-12 flex'>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Total Working
                                                            </h6>
                                                            <h6>
                                                                {
                                                                    info?.totalworking ?
                                                                    info?.totalworking :
                                                                    "00:00:00 "
                                                                }{" "}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Total Productive
                                                            </h6>
                                                            <h6>
                                                            {
                                                                    info?.totalprod ?
                                                                    info?.totalprod :
                                                                    "00:00:00 "
                                                                }{" "}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Total Idle
                                                            </h6>
                                                            <h6>
                                                                {info &&
                                                                    info?.totalidle
                                                                    ? info?.totalidle
                                                                    : "00:00:00 "}{" "}
                                                                hh:mm:ss
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-12 sm:col-span-12 xl:col-span-6 ">
                                                <div className='col-span-12 flex'>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Avg. Working
                                                            </h6>
                                                            <h6>
                                                                {info &&
                                                                    info?.avgtotalworking
                                                                    ? info?.avgtotalworking
                                                                    : "00:00:00 "}{" "}
                                                                hh:mm:ss
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Avg. Productive
                                                            </h6>
                                                            <h6>
                                                                {info &&
                                                                    info?.avgtotalprod
                                                                    ? info?.avgtotalprod
                                                                    : " 00:00:00 "}{" "}
                                                                hh:mm:ss
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-12 gap-4'>
                                                        <div className='w-full' style={{
                                                            padding: '15px 0px',
                                                            width: "150px"
                                                        }}>
                                                            <h6 style={{
                                                                fontWeight: 600,
                                                            }}>
                                                                Avg. Idle
                                                            </h6>
                                                            <h6>
                                                                {info &&
                                                                    info?.avgtotalidle
                                                                    ? info?.avgtotalidle
                                                                    : " 00:00:00 "}{" "}
                                                                hh:mm:ss
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" >
                                        {/* <div>
                                                <PieChart
                                                    width={200}
                                                    height={200}
                                                    data={[
                                                        { title: 'One', value: 10, color: '#E38627' },
                                                        { title: 'Two', value: 15, color: '#C13C37' },
                                                        { title: 'Three', value: 20, color: '#6A2135' },
                                                    ]}
                                                />;
                                            </div> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-span-12 mt-2">
                <div className="grid grid-cols-12 gap-6" style={{

                }}>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "1px solid #24A184",
                        borderRadius: "10px",
                    }}>
                        <div
                            style={{
                                border: "2px solid #24A184",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="intro-y box  text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#24A184",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        padding: "10px",
                                        fontWeight: 600,
                                        color: "#fff",
                                    }}
                                >
                                    Productive Time
                                </h2>
                                <div className="overflow-x-auto scrollbar-hidden p-2" style={{
                                    borderRadius: "10px",
                                }}>
                                    {loading ? (
                                        <div className="text-center m-5 p-5">
                                            <HashLoader
                                                color="#5755d9"
                                                size={30}
                                                style={{
                                                    position:
                                                        "absolute",
                                                    right: "50%",
                                                }}
                                            />
                                        </div>
                                    ) : chart3 && chart3.length > 0 ? (
                                        <SummaryChart
                                            attdata={chart3}
                                            type="Productive Time"
                                        ></SummaryChart>
                                    ) : (
                                        "No data available"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "2px solid #19C1E3",
                        borderRadius: "10px",
                    }}>
                        <div>
                            <div className="intro-y box text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#19C1E3",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        padding: "10px",
                                        color: "#fff",
                                    }}
                                >
                                    Idle Time
                                </h2>

                                <div className="overflow-x-auto scrollbar-hidden p-2 bg-white" style={{
                                    height: "100%",
                                    borderRadius: "10px",
                                    marginTop: "2px"
                                }}>
                                    {loading ? (
                                        <div className="text-center m-5 p-5">
                                            <HashLoader
                                                color="#5755d9"
                                                size={30}
                                                style={{
                                                    position:
                                                        "absolute",
                                                    right: "50%",
                                                }}
                                            />
                                        </div>
                                    ) : chart2 && chart2.length > 0 ? (
                                        <SummaryChart
                                            attdata={chart2}
                                            type="Idle Time"
                                        ></SummaryChart>
                                    ) : (
                                        "No data available"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="col-span-12 mt-2">
                <div className="grid grid-cols-12 gap-6" style={{

                }}>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "1px solid #24A184",
                        borderRadius: "10px",
                    }}>
                        <div
                            style={{
                                border: "2px solid #24A184",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="intro-y box  text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#24A184",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        padding: "10px",
                                        fontWeight: 600,
                                        color: "#fff",
                                    }}
                                >
                                    Hour Wise Productivity Statistics
                                </h2>
                                <div className="overflow-x-auto scrollbar-hidden p-2" style={{
                                    borderRadius: "10px",
                                }}>
                                    {loading ? (
                                        <div className="text-center m-5 p-5">
                                            <HashLoader
                                                color="#5755d9"
                                                size={30}
                                                style={{
                                                    position:
                                                        "absolute",
                                                    right: "50%",
                                                }}
                                            />
                                        </div>
                                    ) : chart3 && chart3.length > 0 ? (
                                        <SummaryChart
                                            attdata={chart3}
                                            type="Productive Time"
                                        ></SummaryChart>
                                    ) : (
                                        "No data available"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "2px solid #FC9A30",
                        borderRadius: "10px",
                    }}>
                        <div>
                            <div className="intro-y box text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#FC9A30",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        padding: "10px",
                                        color: "#fff",
                                    }}
                                >
                                    All Emp Producitivity
                                </h2>

                                <div className="overflow-x-auto scrollbar-hidden p-2 bg-white" style={{
                                    height: "100%",
                                    borderRadius: "10px",
                                    marginTop: "2px"
                                }}>
                                    {loading ? (
                                        <div className="text-center m-5 p-5">
                                            <HashLoader
                                                color="#5755d9"
                                                size={30}
                                                style={{
                                                    position:
                                                        "absolute",
                                                    right: "50%",
                                                }}
                                            />
                                        </div>
                                    ) : chart2 && chart2.length > 0 ? (
                                        <SummaryChart
                                            attdata={chart2}
                                            type="Idle Time"
                                        ></SummaryChart>
                                    ) : (
                                        "No data available"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="col-span-12 mt-2">
                <div className="grid grid-cols-12 gap-6" style={{

                }}>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "1px solid #24A184",
                        borderRadius: "10px",
                    }}>
                        <div
                            style={{
                                border: "2px solid #24A184",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="intro-y box  text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#24A184",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        padding: "10px",
                                        fontWeight: 500,
                                        color: "#fff",
                                    }}
                                >
                                    Hour Wise Productivity Statistics
                                </h2>
                                <div className="overflow-x-auto scrollbar-hidden p-2" style={{
                                    borderRadius: "10px",
                                }}>
                                    {getSummaryProductivity()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y" style={{
                        border: "2px solid #FC9A30",
                        borderRadius: "10px",
                    }}>
                        <div>
                            <div className="intro-y box text-left">
                                <h2
                                    style={{
                                        backgroundColor: "#FC9A30",
                                        borderRadius: "2px",
                                        fontSize: "15px",
                                        fontWeight: 500,
                                        padding: "10px",
                                        color: "#fff",
                                    }}
                                >
                                    All Emp Producitivity
                                </h2>

                                <div className="overflow-x-auto scrollbar-hidden p-2 bg-white" style={{
                                    height: "100%",
                                    borderRadius: "10px",
                                    marginTop: "2px"
                                }}>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div >

             <div className='mt-2' style={{
                borderRadius: "10px !important",
                
             }}>
                <div style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    border: "1px solid #1D46AC",
                }}>
                <div>
                    <h2
                        style={{
                            backgroundColor:"#1D46AC",
                            padding: "10px",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            color: "#fff"
                        }}
                    >
                        Application Usage and Stats
                    </h2>
                </div>

                    <div className='p-2 flex' style={{
                        backgroundColor: "#fff",
                        borderRadius: "10px !important"
                    }}>
                        <div className="p-2" style={{
                            width: "760px",
                        }}>
                            <div className="p-5 pb-5">
                                          {loading1 ? (
                                            <div className="text-center m-5 p-5">
                                              <HashLoader
                                                color="#5755d9"
                                                size={30}
                                                style={{ position: "absolute", right: "50%" }}
                                              />
                                            </div>
                                          ) : mostapp && mostapp.length > 0 ? (
                                            getSummaryApplications()
                                          ) : (
                                            "No Data"
                                          )}
                                        </div>
                        </div>
                        <div className="p-2" style={{
                            width: "300px",
                        }}>
                            <div style={{
                                backgroundColor: "#19C2E3",
                                padding: "10px",
                                textAlign: "center",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: "5px",
                                margin: "0px 0px 10px 0px"
                            }}>
                                <div className='m-0'>
                                Microsoft Excel
                                </div>
                                <div className='m-0'>
                                53.20%
                                </div>
                            </div>

                            <div style={{
                                backgroundColor: "#24A184",
                                padding: "10px",
                                textAlign: "center",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: "5px",
                                margin: "0px 0px 10px 0px"
                            }}>
                                <div className='m-0'>
                                Microsoft Excel
                                </div>
                                <div className='m-0'>
                                53.20%
                                </div>
                            </div>

                            <div style={{
                                backgroundColor: "#FC9A30",
                                padding: "10px",
                                textAlign: "center",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: "5px",
                                margin: "0px 0px 10px 0px"
                            }}>
                                <div className='m-0'>
                                Microsoft Excel
                                </div>
                                <div className='m-0'>
                                53.20%
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>                   
        </>
    )
}

export default Productivity

import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import reload from '../../reload.png'

import { FaCalendar } from "react-icons/fa";
import SummaryApplications from "../appinsight/SummaryApplications";
import SidebarChart from "../appinsight/appChart";
import ChartMostAppUsed from "../appinsight/ChartMostAppUsed";
import HashLoader from "react-spinners/HashLoader";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useLoadingContext } from "react-router-loading";
import Select from "react-select";

const Applications = () => {
  const loadingContext = useLoadingContext();
  let [loading, setLoading] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [username, setusername] = useState();
  const [appdata, setappdata] = useState([]);
  const [mostapp, setmostapp] = useState([]);
  const [mostapphrs, setmostapphrs] = useState([]);
  const [alluser, setalluser] = useState(null);
  const [orgs, setorgs] = React.useState([]);
  const [master,] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);

  const currdate = new Date();
  const [value, onChange] = useState([
    new Date(currdate.getFullYear(), currdate.getMonth(), 1),
    new Date(),
  ]);

  useEffect(() => {
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    setorgval({ value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name });
    if (userdata.type == 2) {
      setusername(userdata.username);
    }
    if (master === 1) {
      getOrganizations();
    }
    getUserData();
    getChartdata(
      format(new Date(value[0]), "yyyy-MM-dd"),
      format(new Date(value[1]), "yyyy-MM-dd"),
      username
    );

  }, []);

  const reloadFun = () => {
    getChartdata(
      format(new Date(value[0]), "yyyy-MM-dd"),
      format(new Date(value[1]), "yyyy-MM-dd"),
      username
    );

  }


  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    getUserData(org);
  }

  // const getorg = async (org) => {
  //   // console.log(org);
  //   setselectedorg(org);
  // let value = JSON.parse(localStorage.getItem("monthyear"));
  // if(Object.keys(monthYear).length !== 0){
  //    value = monthYear;
  // }   

  // const month = value ? value.month : format(new Date(), "MM");
  // const year = value ? value.year : format(new Date(), "yyyy");

  // getBirthdayData({ year: year, month: month,org });

  // getHolidayData({ year: year, month: month ,org });

  // getAttendanceData(format(new Date(), "yyyy-MM-dd"),org);

  // getProdData({ year: year, month: month,org });
  // console.log(org);
  // }

  const setUsername = async (username) => {
    setusername(username);
    getChartdata(
      format(new Date(value[0]), "yyyy-MM-dd"),
      format(new Date(value[1]), "yyyy-MM-dd"),
      username
    );
  };

  const getChartdata = async (paramdate, paramldate, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-AppChartDateWiseData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: paramdate,
        ldate: paramldate,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master: JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmostapp(res.apphourwisearr);
          setmostapphrs(res.datearr);
          ChartMostAppUsedfun();
        } else {
          setmostapphrs([]);
          setmostapp([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  const getappdata = (data) => {
    setappdata(data);
    getAppChart();
  };

  const handledate = (e) => {
    onChange(e);
    getChartdata(
      format(new Date(e[0]), "yyyy-MM-dd"),
      format(new Date(e[1]), "yyyy-MM-dd"),
      username
    );
  };

  const getUserData = async (org = "") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 1,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org !== '' ? org : selectedorg,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        // department:JSON.parse(localStorage.getItem("isAuth")).department,
        department: "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setuserdata(res.data);
          setalluser(res.alluser);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getSummaryApplications = () => {
    return (
      <SummaryApplications
        getappdata={getappdata}
        username={username}
        date={format(new Date(value[0]), "yyyy-MM-dd")}
        ldate={format(new Date(value[1]), "yyyy-MM-dd")}
      />
    );
  };

  const getAppChart = () => {
    let bkpappdata = [...appdata];
    let bkpappdata1 = [...appdata];
    let finaldata = bkpappdata.slice(0, 3);

    let otherdata = bkpappdata1.splice(3);
    let othertotal = otherdata.reduce((a, b) => a + Number(b.usage), 0);
    let first = finaldata.map((a, b) => Number(a.usage));
    let appname = finaldata.map((a, b) => a.appname);
    first.push(othertotal);
    appname.push("Other");
    let data = [{ appname: appname, value: first }];
    return <SidebarChart data={data} />;
  };

  const ChartMostAppUsedfun = () => {
    return <ChartMostAppUsed hrs={mostapphrs} data={mostapp} />;
  };

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };

  const filterByReference2 = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };

  let filterarr = filterByReference(userdata, alluser);
  let filterarr2 = filterByReference2(userdata, alluser);

  if (filterarr2 && filterarr2.length > 0) {
    for (let i = 0; i < filterarr2.length; i++) {
      filterarr2[i].active2 = true;
    }
  }

  let finalarr3 = filterarr2.concat(filterarr);
  let data = [{ value: "", label: "Select All", active: "" }];
  for (let i = 0; i < finalarr3.length; i++) {
    data.push({
      value: finalarr3[i].username,
      label: finalarr3[i].name,
      active: finalarr3[i].active2 ? "🟢" : "🔴",
    });
  }

  return (
    <>
      <div className="intro-y flex flex-col md:flex-row items-left mt-8">
        <h2 className="text-lg font-medium mr-auto">Applications</h2>
        {master === 1 &&
          <Select
            value={orgval}
            className="basic-single mr-2"
            onChange={(e) => {
              getorg(e);
            }}
            options={orgs}
            styles={{
              option: (base) => ({
                ...base,
                // height: "100%",                    
                display: "flex",
                textAlign: "left",
              }),
            }}
          />
        }

        <Select
          defaultValue={data[0]}
          className="basic-single mr-2"
          onChange={(e) => {
            setUsername(e.value);
          }}
          options={data}
          getOptionLabel={(option) =>
            ` ${option.active}  ${option.label}`
          }
          styles={{
            option: (base) => ({
              ...base,

              height: "100%",
              display: "flex",
            }),
          }}
        />

        <div className="h-auto">
          <div className="ml-auto md:flex items-center text-primary">



            {/* <div
              className="ml-2"
              style={{
                color: "hsl(0, 0%, 40%)",
                display: "inline-block",
                fontSize: 12,
                width: "400px",
              }}
            > */}


            <DateRangePicker
              clearIcon=""
              calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
              className="form-control sm:mr-3"
              onChange={handledate}
              value={value}
            />

            <button className="ml-1 btn btn-default">
              <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
            </button>


            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="intro-y chat grid grid-cols-12 gap-5 mt-2">
        <div className="intro-y col-span-12 lg:col-span-9 2xl:col-span-9">
          <div className="box">
            <div className="intro-y flex flex-col sm:flex-row items-center">
              <strong className="p-3">
                3 Most Used Application Statistics
              </strong>
            </div>
            <div className="p-5 pb-5">
              {loading ? (
                <div className="text-center m-5 p-5">
                  <HashLoader
                    color="#5755d9"
                    size={30}
                    style={{ position: "absolute", right: "50%" }}
                  />
                </div>
              ) : mostapp && mostapp.length > 0 ? (
                ChartMostAppUsedfun()
              ) : (
                "No Data"
              )}
            </div>
          </div>

          <div className="mt-2">
            <div className="intro-y flex flex-col sm:flex-row items-center">
              <strong className="p-3 ml-0">Applications Stats</strong>
            </div>
            {loading ? (
              <div className="text-center m-5 p-5">
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              </div>
            ) : (
              getSummaryApplications()
            )}
          </div>
        </div>

        <div className="intro-y col-span-12 lg:col-span-3 2xl:col-span-3">
          <div className="box">
            <div className="intro-y flex flex-col sm:flex-row items-center">
              <strong className="p-3">Top 3 Used Applications</strong>
            </div>

            <div className="col-span-12 sm:col-span-6 xl:col-span-2 mt-2 intro-y pb-2">
              {loading ? (
                <div className="text-center m-5 p-5">
                  <HashLoader
                    color="#5755d9"
                    size={30}
                    style={{ position: "absolute", right: "50%" }}
                  />
                </div>
              ) : appdata && appdata.length > 0 ? (
                getAppChart()
              ) : (
                "No Data"
              )}

              {appdata &&
                appdata.map((item, i) => {
                  var date = new Date(0);
                  date.setSeconds(item.time);
                  var timeString = date.toISOString().substring(11, 19);
                  if (i < 3) {
                    return (
                      <div className="intro-y box cardborder" key={i}>
                        <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                          <small>{item.appname}</small>
                          <small className="ml-auto">
                            <img
                              src={item.icon}
                              style={{ width: "20px" }}
                              alt=""
                              srcSet=""
                            />
                          </small>
                        </div>

                        <div id="progressbar-height" className="p-1">
                          <div className="preview">
                            <div className="progress h-3 mb-1 mt-0">
                              <div
                                className="progress-bar w-3/4"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {" "}
                                {item.usage} %
                              </div>
                            </div>
                          </div>
                          <small className="text-left d-flex">
                            {timeString} hh:mm:ss
                          </small>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applications;

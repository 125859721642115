// import React, { useEffect, useState } from "react";
// import { BASEURL } from "./BASEURL";
// import { FaCalendar, FaFileCsv, FaDownload } from "react-icons/fa";
// import { CSVLink } from "react-csv";
// import HashLoader from "react-spinners/HashLoader";
// import { useLoadingContext } from "react-router-loading";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TextField } from "@mui/material";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// const MonthlyAttendanceReport = () => {
// 	let today = new Date();
// 	let month = [];
// 	month[0] = "1";
// 	month[1] = "2";
// 	month[2] = "3";
// 	month[3] = "4";
// 	month[4] = "5";
// 	month[5] = "6";
// 	month[6] = "7";
// 	month[7] = "8";
// 	month[8] = "9";
// 	month[9] = "10";
// 	month[10] = "11";
// 	month[11] = "12";
// 	const loadingContext = useLoadingContext();
// 	let [loading, setLoading] = useState(false);
// 	const [attdata, setattdata] = useState(null);
// 	const [empName, setEmpName] = useState("NA");
// 	// const [name, setName] = useState("");
// 	const [plEligibilityStatus, setPlEligibilityStatus] = useState("");
// 	const [isLoad, setIsLoad] = useState(false);
// 	const [attendanceSummary, setAttendanceSummary] = useState({
// 		presentDays: 0,
// 		halfDays: 0,
// 		absentDays: 0,
// 		holidays: 0,
// 		totalWorkingDays: 0,
// 		isSixMonthsCompleted: false,
// 		isEligibleForPL: false,
// 	});
// 	// const [totalTime, setTotalTime] = useState(null);
// 	const [totalTime, setTotalTime] = useState({});
// 	const [csvData, setcsvData] = useState([]);
// 	const [currdate] = useState(new Date());
// 	const [userdatalist, setuserdata] = useState([]);
// 	const [alluser, setalluser] = useState(null);
// 	const [username, setusername] = useState();
// 	const [data, setData] = useState([
// 		{ value: "", label: "Select All", active: "", uuid: "" },
// 	]);
// 	const [selectedorg, setselectedorg] = React.useState(
// 		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
// 	);
// 	const user_data = JSON.parse(localStorage.getItem("isAuth"));
// 	const userUuid = user_data?.uuid;
// 	const userType = user_data?.type;
// 	const comp_uuid = user_data.companydata[0].uuid;
// 	const [selectedval, setselectedval] = useState(
// 		userType !== 0 ? { uuid: userUuid } : ""
// 	);
// 	const [dept, setdept] = useState(null);
// 	const [departmentData, setDepartmentData] = useState({
// 		name: "",
// 		department: 1,
// 		hod: 2,
// 		contactno: "",
// 		organization: comp_uuid,
// 		email: "",
// 		password: "",
// 		status: true,
// 		uuid: ``,
// 	});
// 	const [selectedDate, setSelectedDate] = useState(new Date());
// 	const handleDateChange = (date) => {
// 		setSelectedDate(date);
// 	};
// 	let monthNumber = month[today.getMonth()];
// 	const [newColumns, setNewColumns] = useState([
// 		{ title: "Total Working Days", field: "workingDays", width: 140 },
// 		{
// 			title: "Total Sandwich Leave Days",
// 			field: "sandwichLeaveDays",
// 			width: 180,
// 		},
// 		{ title: "Present Days", field: "presentDays", width: 140 },
// 		{ title: "Month Leave", field: "monthLeave", width: 140 },
// 		{ title: "Non Working Days", field: "nonWorkingDays", width: 180 },
// 		{ title: "Total Month Days", field: "totalMonthDays", width: 160 },
// 		{ title: "Is Eligible for PL", field: "isEligiblePL", width: 180 },
// 	]);
// 	const [newTableData, setNewTableData] = useState([]);
// 	const currmonth = monthNumber;
// 	const curryear = today.getFullYear();
// 	const [value, onChange] = useState([
// 		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
// 		new Date(),
// 	]);
// 	const [columns, setcolumn] = useState([]);
// 	const [selectedmonth, setselectedmonth] = useState({
// 		year: curryear,
// 		month: Number(currmonth),
// 	});
// 	const [totalAfterHours, setTotalAfterHours] = useState(0);
// 	const [department, setDepartment] = useState([]);
// 	useEffect(() => {}, [csvData]);
// 	useEffect(() => {
// 		const total = attdata
// 			?.flatMap((row) => row.after_office_hours || []) // Flatten after office hours arrays
// 			.reduce((sum, afterHours) => {
// 				const [hours, minutes] = afterHours.duration
// 					.split("h")
// 					.map((part) => parseInt(part.trim().replace("m", "")) || 0);
// 				return sum + hours * 60 + minutes; // Convert hours to minutes and add to total
// 			}, 0);

// 		setTotalAfterHours(total || 0); // Set total in minutes or default to 0
// 	}, [attdata]);

// 	const getdepartments = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `getAllDepartments/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
// 					.uuid,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					setdept(res.data);
// 				}
// 				setLoading(false);
// 			})
// 			.catch((err) => console.log(err));
// 	};
// 	const handleuser = (e) => {
// 		if (e && e.value) {
// 			// Handle <Select> input (React-Select library)
// 			setselectedval(e); // Full object
// 			setusername(e.value); // The selected value or UUID
// 		} else if (e && e.target) {
// 			// Handle native <select> input
// 			const selected = data.find((item) => item.uuid === e.target.value);
// 			setselectedval(selected || null); // Find the full object or set to null
// 			setusername(e.target.value || ""); // Set the selected value or empty string
// 		}
// 	};

// 	const calculateTotalNeedHours = (attendanceData) => {
// 		const totalSeconds = attendanceData.reduce((total, day) => {
// 			if (!day.is_weekoff && day.shift) {
// 				// Parse shift times
// 				const [shiftStartHours, shiftStartMinutes, shiftStartSeconds] =
// 					day.shift.shift_start_time.split(":").map(Number);
// 				const [shiftEndHours, shiftEndMinutes, shiftEndSeconds] =
// 					day.shift.shift_end_time.split(":").map(Number);
// 				const shiftStart =
// 					shiftStartHours * 3600 +
// 					shiftStartMinutes * 60 +
// 					shiftStartSeconds;
// 				const shiftEnd =
// 					shiftEndHours * 3600 +
// 					shiftEndMinutes * 60 +
// 					shiftEndSeconds;
// 				let workTimeInSeconds = shiftEnd - shiftStart;
// 				if (day.lunch_break_duration) {
// 					const [lunchHours, lunchMinutes, lunchSeconds] =
// 						day.lunch_break_duration.split(":").map(Number);
// 					workTimeInSeconds -=
// 						lunchHours * 3600 + lunchMinutes * 60 + lunchSeconds;
// 				}
// 				if (day.other_break_duration) {
// 					const [breakHours, breakMinutes, breakSeconds] =
// 						day.other_break_duration.split(":").map(Number);
// 					workTimeInSeconds -=
// 						breakHours * 3600 + breakMinutes * 60 + breakSeconds;
// 				}
// 				return total + workTimeInSeconds;
// 			}
// 			return total;
// 		}, 0);
// 		const hours = Math.floor(totalSeconds / 3600);
// 		const minutes = Math.floor((totalSeconds % 3600) / 60);
// 		const seconds = totalSeconds % 60;

// 		return `${hours.toString().padStart(2, "0")}:${minutes
// 			.toString()
// 			.padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
// 	};
// 	const handlechangeDepartment = (e) => {
// 		console.log("Function Called");
// 		const departmentId = e.target.value; // Selected department ID
// 		setDepartmentData(departmentId); // Update department state
// 		getUserData(departmentId === "0" ? "" : selectedorg, departmentId); // Call API with selected department ID

// 		// Logging updated values
// 		// Assuming userData is a state variable
// 		console.log("Department Data:", departmentData); // Updated department state
// 	};

// 	const getUserData = async (org = "", departmentId = "") => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));

// 		if (userdata !== "00:00:00") {
// 			const token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		try {
// 			const response = await fetch(BASEURL + `admin-getAllUsers/`, {
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify({
// 					type: 1,
// 					uuid: org || selectedorg, // Use `org` or default to `selectedorg`
// 					usertype: userdata.type,
// 					department: departmentId || "", // Use department ID or default to all
// 				}),
// 			});

// 			if (!response.ok) {
// 				throw new Error(
// 					`Error: ${response.status} - ${response.statusText}`
// 				);
// 			}

// 			const res = await response.json();

// 			if (res.status === 201) {
// 				setuserdata(res.data); // Update user data state
// 				setalluser(res.alluser); // Update all users state

// 				// Prepare dropdown options
// 				const userOptions = res.data.map((user) => ({
// 					value: user.username,
// 					label: user.name,
// 					active: user.active ? "🟢" : "🔴",
// 					uuid: user.uuid,
// 				}));
// 				userOptions.unshift({
// 					value: "",
// 					label: "Select Employee",
// 					active: "",
// 				});
// 				setData(userOptions);
// 				if (res.alluser.length > 0 && res.data.length > 0) {
// 					setusername(res.alluser[0]["username"]);
// 				}
// 			}
// 			setLoading(false);
// 		} catch (err) {
// 			console.error("Error fetching user data:", err.message);
// 			setLoading(false);
// 		} finally {
// 			loadingContext.done();
// 		}
// 	};

// 	const csvHeaders = [
// 		{ label: "Date", key: "Date" },
// 		{ label: "Shift Start Time", key: "Shift_Start_Time" },
// 		{ label: "Shift In Time", key: "Shift_In_Time" },
// 		{ label: "Late Coming", key: "Late_Coming" },
// 		{ label: "Lunch Break Hrs", key: "Lunch_Break_Hrs" },
// 		{ label: "Other Break Hrs", key: "Other_Break_Hrs" },
// 		{ label: "After Office Hours", key: "After_Office_Hours" },
// 		{ label: "Shift End Time", key: "Shift_End_Time" },
// 		{ label: "Shift Out Time", key: "Shift_Out_Time" },
// 		{ label: "Pending Time", key: "Pending_Time" },
// 		{ label: "Total Working Hours", key: "Total_working_Hours" },
// 		{ label: "Half Day Type", key: "Half_Day_Type" },
// 		{ label: "Is Weekoff", key: "Is_Weekoff" },
// 		{ label: "On Leave", key: "On_Leave" },
// 	];

// 	const handleKeyDown = (e) => {
// 		if (e.key === "Tab") {
// 			e.preventDefault(); // Prevent default Tab behavior

// 			// Find current index of the selected option
// 			const currentIndex = data.findIndex(
// 				(option) => option.value === selectedval?.value
// 			);

// 			// Calculate the next index (loop back to 0 if at the end)
// 			const nextIndex = (currentIndex + 1) % data.length;

// 			// Update selected value to the next option
// 			setselectedval(data[nextIndex]);
// 		}
// 	};

// 	// console.log("usertype " + userType);
// 	useEffect(() => {
// 		if (userType === 0) {
// 			getdepartments();
// 			getUserData();
// 		}
// 	}, []);
// 	const options = {
// 		pagination: "local",
// 		paginationSize: 10,
// 	};
// 	function DetailVal(props) {
// 		const rowData = props.cell._cell.row.data;
// 		if (rowData.employee !== "") {
// 			return (
// 				<a
// 					href={"/employees/" + rowData.uuid}
// 					rel="noreferrer"
// 					target="_blank"
// 					alt=""
// 				>
// 					<u>{rowData.name}</u>
// 				</a>
// 			);
// 		} else {
// 			return "No data Available";
// 		}
// 	}
// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setDepartmentData(value);
// 	};

// 	const getMonthWiseData = async () => {
// 		if (!selectedDate || !(selectedDate instanceof Date)) {
// 			console.error("Invalid date selected");
// 			return;
// 		}

// 		const selectedMonth = selectedDate.getMonth() + 1; // Get the month from the Date object
// 		const selectedYear = selectedDate.getFullYear(); // Get the year from the Date object

// 		if (!selectedorg) {
// 			console.error("Company UUID is missing");
// 			return;
// 		}

// 		setIsLoad(true); // Set loading state to true

// 		const myHeaders = new Headers();
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata) {
// 			myHeaders.append("Authorization", userdata.usertoken); // Append the user token
// 		}
// 		myHeaders.append("Content-Type", "application/json"); // Set content type to JSON

// 		try {
// 			const response = await fetch(
// 				`${BASEURL}/get-monthwise-attendance-report`,
// 				{
// 					method: "POST",
// 					headers: myHeaders,
// 					body: JSON.stringify({
// 						month: selectedMonth,
// 						year: selectedYear,
// 						company_uuid: selectedorg,
// 					}),
// 				}
// 			);
// 			if (!response.ok) {
// 				throw new Error(
// 					`Error: ${response.status} - ${response.statusText}`
// 				);
// 			}
// 			const result = await response.json();
// 			if (result?.data?.length > 0) {
// 				const processedAttendanceSummary = result.data.map((user) => ({
// 					userId: user.userId,
// 					username: user.username,
// 					name: user.name,
// 					attendance_summary: user.attendance_summary,
// 				}));
// 						setAttendanceSummary(processedAttendanceSummary);
// 			} else {
// 				console.warn("No attendance data found	 for the specified month.");
// 			}

// 		} catch (err) {
// 			console.error("Error fetching month-wise data:", err.message || err);
// 		} finally {
// 			setIsLoad(false); // Set loading state to false
// 		}
// 	};
// 		return (
// 		<>
// 			<div className="grid grid-cols-12 gap-6">
// 				<div className="col-span-12 2xl:col-span-12 bg-white p-0 rounded-lg shadow-lg">
// 					<h2 className="text-lg font-medium text-left p-2">
// 						Department Monthly Attendance Report
// 					</h2>
// 					<div className="intro-y flex flex-col sm:flex-row items-center ">
// 						<div className="flex items-center text-primary gap-4">
// 							{userType !== 2 && (
// 								<>
// 									<select
// 										className="form-select"
// 										name="department"
// 										onChange={handlechangeDepartment}
// 										value={departmentData}
// 										style={{
// 											margin: "5px 5px",
// 										}}
// 									>
// 										<option key={-1} value="0">
// 											All department
// 										</option>
// 										{dept &&
// 											dept.map((item, i) => (
// 												<option
// 													key={i}
// 													value={item.department_id}
// 												>
// 													{item.deptname}
// 												</option>
// 											))}
// 									</select>

// 									{/* <select
// 										className="form-select"
// 										name="user"
// 										onChange={(e) =>
// 											handleuser(
// 												data.find(
// 													(item) =>
// 														item.uuid ===
// 														e.target.value
// 												)
// 											)
// 										}
// 										value={
// 											selectedval ? selectedval.uuid : ""
// 										}
// 									>
// 										{data &&
// 											data.map((item, i) => (
// 												<option
// 													key={i}
// 													value={item.uuid}
// 												>

// 													{item.label}
// 												</option>
// 											))}
// 									</select> */}
// 								</>
// 							)}
// 							<div className="mx-1 w-full max-w-[100px]">
// 								<LocalizationProvider
// 									dateAdapter={AdapterDateFns}
// 								>
// 									<DatePicker
// 										slotProps={{
// 											textField: { size: "small" },
// 										}}
// 										sx={{
// 											"& .MuiInputBase-input": {
// 												height: "20px",
// 											},
// 										}}
// 										views={["month", "year"]}
// 										value={selectedDate}
// 										onChange={handleDateChange}
// 										renderInput={(params) => (
// 											<TextField {...params} fullWidth />
// 										)}
// 									/>
// 								</LocalizationProvider>
// 							</div>

// 							<button
// 								className="btn btn-primary"
// 								onClick={getMonthWiseData}
// 								style={{ borderRadius: "5px" }}
// 							>
// 								Get
// 							</button>
// 							<CSVLink
// 								filename={`${empName}-Monthly-Attendance-${selectedDate.getFullYear()}-${
// 									selectedDate.getMonth() + 1
// 								}.csv`}
// 								data={csvData}
// 								headers={csvHeaders}
// 							>
// 								<button
// 									style={{
// 										display: "flex",
// 										alignItems: "center",
// 										justifyContent: "center",
// 										padding: "10px 18px",
// 										backgroundColor: "rgb(30 64 175)",
// 										color: "white",
// 										border: "none",
// 										borderRadius: "5px",
// 										fontSize: "16px",
// 										cursor: "pointer",
// 										boxShadow:
// 											"0 4px 8px rgba(0, 0, 0, 0.1)",
// 										transition:
// 											"background-color 0.3s ease",
// 									}}
// 									onMouseOver={(e) => {
// 										e.target.style.backgroundColor =
// 											"rgb(29, 53, 153)";
// 									}}
// 									onMouseOut={(e) => {
// 										e.target.style.backgroundColor =
// 											"rgb(30 64 175)";
// 									}}
// 								>
// 									<FaDownload />
// 								</button>
// 							</CSVLink>
// 						</div>
// 					</div>

// 					{isLoad ? (
// 						<div className="text-center m-5 p-5">
// 							<HashLoader
// 								color="#5755d9"
// 								size={30}
// 								style={{
// 									position: "absolute",
// 									right: "50%",
// 								}}
// 								HashLoader
// 							/>
// 						</div>
// 					) : (
// 						<div></div>
// 					)}
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default MonthlyAttendanceReport;

import React, { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Select, MenuItem, Button } from "@mui/material";
import { BASEURL } from "./BASEURL";
import { CSVLink } from "react-csv"; // Import CSVLink from react-csv
import { FaCalendar, FaFileCsv, FaDownload, FaTimes } from "react-icons/fa";
import { HashLoader } from "react-spinners"; // Import the HashLoader
import Modal from "react-modal";
import { useLoadingContext } from "react-router-loading";
import moment from "moment";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		padding: "0%",
		borderRadius: "5px",
	},
};


const headers = [
	{ label: "Date", key: "date" },
	{ label: "In Time", key: "start_time" },
	{ label: "Late", key: "late_coming" },
	{ label: "Lunch HRS", key: "lunch_break_duration" },
	{ label: "Out Time", key: "end_time" },
	{ label: "Pending HRS", key: "pending_time" },
	{ label: "Total HRS", key: "total_working_hours" },
	{ label: "Other Break", key: "other_break_duration" },
	{ label: "After Office HRS", key: "after_office_hours" },
	{ label: "1st/2nd Half", key: "half_day_type" },
]
const AttendanceReport = () => {
	const [departments, setDepartments] = useState([]);
	const loadingContext = useLoadingContext();
	const [alluser, setalluser] = useState(null);
	const [data, setData] = useState([
		{ value: "", label: "Select All", active: "", uuid: "" },
	]);
	const [selectedDepartment, setSelectedDepartment] =
		useState("All department");
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
	// console.log(selectedMonth, 'selectedMonth')
	const [isVisible, setIsVisible] = useState(false);

	const handleOnChange = async (year, month) => {
		setSelectedMonth({ year, month });
		setIsVisible(false); // Close the picker after selection
		await fetchAttendanceData(selectedDepartment, month, year)
	};

	const showMonthPicker = (e) => {
		e.preventDefault();
		setIsVisible(true); // Show the month picker
	};

	const handleOnDismiss = () => {
		setIsVisible(false); // Close the picker without selection
	};

	const getMonthValue = () => {
		// Format selected month and year for display in the input
		return `${selectedMonth.month}/${selectedMonth.year}`;
	};
	const [selectedButton, setSelectedButton] = useState(true)
	// const userType = user_data?.type;
	// const [selectedval, setselectedval] = useState(
	// 	userType !== 0 ? { uuid: userUuid } : ""
	// );
	// const userUuid = user_data?.uuid;
	// const userType = user_data?.type;
	const [activeTab, setActiveTab] = useState('Department'); // Track which tab is active
	const data1 = [
		{ uuid: '1', label: 'John Doe' },
		{ uuid: '2', label: 'Jane Smith' },
	];

	const departments1 = [
		{ department_id: '1', deptname: 'HR' },
		{ department_id: '2', deptname: 'Engineering' },
	];

	const user_data = JSON.parse(localStorage.getItem("isAuth")); // Parse the user data first
	const userUuid = user_data?.uuid; // Extract uuid from the parsed data
	const userType = user_data?.type; // Extract userType from the parsed data

	const [selectedval, setselectedval] = useState(
		userType !== 0 ? { uuid: userUuid } : ""
	);

	const comp_uuid = user_data.companydata[0].uuid;
	// const [selectedval, setselectedval] = useState(
	// 	userType !== 0 ? { uuid: userUuid } : ""
	// );
	// const user_data = JSON.parse(localStorage.getItem("isAuth"));
	// const userUuid = user_data?.uuid;
	const [attendanceData, setAttendanceData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [username, setusername] = useState();
	const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
	const [departmentLoading, setdepartmentLoading] = useState(false);
	const [departmentChangeLoading, setdepartmentChangeLoading] = useState(false);
	const [showDepartmentSelectuser, setShowDepartmentSelectuser] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [newModalIsOpen, setnewModalIsOpen] = useState(false);
	const [attdata, setAttdata] = useState([]);
	const [pendingHours, setPendingHours] = useState();
	const [totalHours, setTotalHours] = useState();
	const [shiftStartTime, setshiftStartTime] = useState();
	const [shiftEndTime, setshiftEndTime] = useState();
	const [totalAfterHours, setTotalAfterHours] = useState(0);
	const [selectedEmpName, setSelectedEmpName] = useState("");
	const [isLoad, setIsLoad] = useState(false);
	const [userdatalist, setuserdata] = useState([]);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	// console.log(modalIsOpen, 'modalIsOpen')
	const closeModal = () => setnewModalIsOpen(false);

	const handleViewDetails = async (uuid) => {
		console.log("UUID passed to handleViewDetails:", uuid);
		setnewModalIsOpen(true);
		setIsLoad(true);

		try {
			await getMonthWiseData(uuid);
		} catch (err) {
			console.error("Error fetching details:", err.message);
			setModalIsOpen(false);
		} finally {
			setIsLoad(false); // Stop loading spinner
		}
	};
	function formatTime(time) {
		if (!time || typeof time !== "string") return "";
		return time.slice(0, 2) + ":" + time.slice(3, 5);
	}
	const handleDepartmentChange = async (event) => {
		const selectedValue = event.target.value; // Extract the value from the event
		setSelectedDepartment(selectedValue); // Update the state

		try {
			// Call the API with the selected value
			getUserData("", selectedValue)
			await fetchAttendanceData(selectedValue);
		} catch (error) {
			console.error("Error fetching attendance data:", error.message);
		}
	};


	const getUserData = async (org = "", departmentId = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + `admin-getAllUsers/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					type: 1,
					uuid: org || selectedorg, // Use `org` or default to `selectedorg`
					usertype: userdata.type,
					department: departmentId || "", // Use department ID or default to all
				}),
			});

			if (!response.ok) {
				throw new Error(
					`Error: ${response.status} - ${response.statusText}`
				);
			}

			const res = await response.json();

			if (res.status === 201) {
				// Filter out users from department 34
				// const filteredData = res.data.filter((user) => user.department !== 34);
				const filteredData = res.data.filter(
					(item) => item.department !== 34 && item.username !== "1119"
				);

				// Update user data state with filtered data
				setuserdata(filteredData); // Update user data state
				setalluser(res.alluser); // Update all users state

				// Prepare dropdown options
				const userOptions = filteredData.map((user) => ({
					value: user.username,
					label: user.name,
					active: user.active ? "🟢" : "🔴",
					uuid: user.uuid,
				}));

				// Add a default option at the beginning of the list
				userOptions.unshift({
					value: "",
					label: "Select Employee",
					active: "",
				});

				// Update select options
				setData(userOptions);

				// Set the default selected username if data is available
				if (res.alluser.length > 0 && filteredData.length > 0) {
					setusername(res.alluser[0]["username"]);
				}
			}

			setLoading(false);
		} catch (err) {
			console.error("Error fetching user data:", err.message);
			setLoading(false);
		} finally {
			loadingContext.done();
		}
	};

	useEffect(() => {
		if (userType === 0) {
			// getdepartments();
			getUserData();
		}
	}, []);

	const formatTotalHours = (totalMinutes) => {
		const hours = Math.floor(totalMinutes / 60)
			.toString()
			.padStart(2, "0"); // Zero-padded hours
		const minutes = (totalMinutes % 60).toString().padStart(2, "0"); // Zero-padded minutes
		return `${hours}:${minutes}`;
	};

	const getMonthWiseData = async (uuid) => {
		// if (!uuid) {
		// 	console.error("UUID is missing");
		// 	return;
		// }
		setIsLoad(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");
		try {
			const response = await fetch(`${BASEURL}get-monthwise-attendance/${uuid}`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					month: selectedDate.getMonth() + 1,
					year: selectedDate.getFullYear(),
					company_uuid: selectedorg,
				}),
			});
			if (!response.ok) {
				throw new Error(`Error: ${response.status} - ${response.statusText}`);
			}
			const result = await response.json();
			setPendingHours(result?.total_pending_time)
			setTotalHours(result?.totalTime?.totalWorkingHours)
			setshiftEndTime(result?.shiftEndTime)
			setshiftStartTime(result?.shiftStartTime)

			const total = result?.attendance
				?.flatMap((row) => row?.after_office_hours || []) // Flatten after office hours arrays
				.reduce((sum, afterHours) => {
					const duration = afterHours?.duration || "0h 0m"; // Fallback for missing duration
					const [hoursPart, minutesPart] = duration.split("h").map((part) => part.trim());

					const hours = parseInt(hoursPart) || 0; // Parse hours, default to 0
					const minutes = parseInt(minutesPart?.replace("m", "")) || 0; // Parse minutes, default to 0

					return sum + hours * 60 + minutes; // Convert hours to minutes and add to total
				}, 0);

			setTotalAfterHours(total || 0);
			// console.log(total, 'total')
			console.log(result?.attendance, 'result')
			setSelectedEmpName(result?.attendance?.[0]?.name || "NA");
			setAttdata(result.attendance);
		} catch (err) {
			console.error("Error fetching month-wise data:", err.message);
		} finally {
			setIsLoad(false);
		}
	};
	const csvData = attdata.map((row) => ({
		date: row?.date ? moment(row.date).format("DD/MM/YY") : "-",
		start_time: row?.start_time || "-",
		late_coming: row?.late_coming || "-",
		lunch_break_duration: row?.lunch_break_duration || "-",
		end_time: row?.end_time || "-",
		pending_time: row?.pending_time || "-",
		total_working_hours: row?.total_working_hours || "-",
		other_break_duration: row?.other_break_duration || "-",
		after_office_hours: row?.after_office_hours || "-",
		half_day_type: row?.on_leave
			? "A" // If on leave, show A
			: row?.half_day_type === "First Half"
				? "A/P" // If First Half, show A/P
				: row?.half_day_type === "Second Half"
					? "P/A" // If Second Half, show P/A
					: "P/P", // Default: P/P
	}));

	const footerRow = {
		date: "Total",
		start_time: "-",
		late_coming: "-",
		lunch_break_duration: "-",
		end_time: "-",
		pending_time: pendingHours, // Replace with your calculated pending hours
		total_working_hours: totalHours, // Replace with your calculated total hours
		other_break_duration: "-",
		after_office_hours: formatTotalHours(totalAfterHours), // Replace with your formatted total after-hours
		half_day_type: "-", // Placeholder for the last column
	};

	// Append the footer row to the csvData array
	csvData.push(footerRow);
	// Fetch departments on component mount
	useEffect(() => {
		const getDepartments = async () => {
			setLoading(true);
			const myHeaders = new Headers();
			let userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (userdata) {
				let token = userdata.usertoken;
				myHeaders.append("Authorization", token);
			}
			myHeaders.append("Content-Type", "application/json");
			try {
				const response = await fetch(`${BASEURL}getAllDepartments/`, {
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						uuid: userdata.companydata[0].uuid,
					}),
				});
				const res = await response.json();
				if (res.status === 201) {
					setDepartments(res.data);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};
		getDepartments();
	}, []);

	useEffect(() => {
		fetchAttendanceData();
	}, []);
	const fetchAttendanceData = async (departmentId = selectedDepartment, month = selectedMonth?.month, year = selectedMonth?.year) => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		const selectedMonthvalue = month;
		const selectedYear = year;
		setdepartmentLoading(true);

		try {
			const response = await fetch(
				`${BASEURL}get-monthwise-attendance/all`,
				{
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						month: selectedMonthvalue,
						year: selectedYear,
						departmentId:
							departmentId === "All department" ? null : departmentId,
					}),
				}
			);
			const res = await response.json();
			setAttendanceData(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
			setdepartmentLoading(false);
		}
	};

	const handleSearch = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredData = attendanceData.filter((user) =>
		user.name?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	// Format the data for CSV export
	const formattedData = attendanceData.map((user) => ({
		Name: user.name,
		Username: user.username,
		// "Month Days": user.attendance_summary.totalDays,
		// "Week Off": user.attendance_summary.weekOffs,
		// // "Public Holiday": user.attendance_summary.holidays,
		// "Public Holiday":0,
		// "Total Working Days": user.attendance_summary.totalWorkingDays,
		// "Present Days": user.attendance_summary.presentDays,
		// "Absent Days": user.attendance_summary.absentDays,
		// "Sandwich Days": user.attendance_summary.sandwichLeave,
		// "Month Leaves": user.attendance_summary.monthLeaves,
	}));

	const handleOpenModal = () => {
		setnewModalIsOpen(true)
	}

	return (
		<div className="intro-y box lg:mt-5 p-5">
			{/* Filters Section */}
			{/* <div className="filters-section flex items-center justify-between mb-5">
				<Select
					value={selectedDepartment}
					onChange={(e) => setSelectedDepartment(e.target.value)}
					size="small"
					style={{
						minWidth: "150px",
						marginRight: "1rem",
					}}
				>
					<MenuItem value="All department" style={{ color: "#24a184" }}>All Department</MenuItem>
					{departments.map((dept) => (
						<MenuItem key={dept.department_id} value={dept.department_id}>
							{dept.deptname}
						</MenuItem>
					))}
				</Select>

				<Button
					variant="contained"
					color="primary"
					onClick={fetchAttendanceData}
					style={{
						padding: "8px 20px",
						backgroundColor: "#1e40af",
						color: "#fff",
					}}
				>
					Get
				</Button>

				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						views={["month", "year"]}
						value={selectedDate}
						onChange={(newValue) => setSelectedDate(newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								sx={{
									minWidth: "180px",
									marginLeft: "auto",
								}}
							/>
						)}
					/>
				</LocalizationProvider>
			</div> */}
			<div className="filters-section flex items-center mb-5" style={{
				flexWrap: "wrap",
			}}>
				{/* Department Button */}
				<Button
					variant="contained"
					color=""
					onClick={() => {
						setActiveTab("Individual");
						setShowDepartmentSelectuser(false);
						setShowDepartmentSelect(true);
						setSelectedButton(true)
					}}
					style={{
						backgroundColor: selectedButton ? "#24A184" : "#fff",
						color: selectedButton ? "#fff" : "#000",
						width: "140px",
						padding: "3px 0px",
						fontSize: "12px",
						height: "30px",
						borderTopRightRadius: "0", // Removes top-right border radius
						borderBottomRightRadius: "0",
						// border: "1px solid #000"
					}}
					sx={{
						'.MuiSelect-select': {
							paddingTop: '2px',
							paddingBottom: '2px',
							fontSize: '0.875rem',
						},
						'.MuiMenuItem-root': {
							fontSize: '0.875rem',
							padding: '6px 10px',
						},
					}}
				>
					Individual
				</Button>
				{/* Individual Button */}
				<Button
					variant="contained"

					onClick={() => {
						setActiveTab("Individual");
						setShowDepartmentSelect(true);
						setShowDepartmentSelectuser((prev) => !prev);
						setSelectedButton(false)
					}}
					style={{
						backgroundColor: selectedButton ? "#fff" : "#24A184",
						color: selectedButton ? "#000" : "#fff",
						width: "140px",
						padding: "3px 0px",
						marginRight: "10px",
						fontSize: "12px",
						height: "30px",
						borderTopLeftRadius: "0", // Removes top-Left border radius
						borderBottomLeftRadius: "0",
					}}
					sx={{
						'.MuiSelect-select': {
							paddingTop: '2px',
							paddingBottom: '2px',
							fontSize: '0.875rem',
						},
						'.MuiMenuItem-root': {
							fontSize: '0.875rem',
							padding: '6px 10px',
						},
					}}
				>
					ALl Department
				</Button>
				{/* Department Dropdown */}
				{/* {activeTab === "Individual" && showDepartmentSelectuser && (
					<Select
						value={selectedDepartment || "All department"}
						onChange={handleDepartmentChange} // Correctly pass the event
						size="small"
						style={{
							minWidth: "150px",
							marginRight: "1rem",
							padding: "0px",
							fontSize: "12px", // Reduced padding
						}}
					>
						<MenuItem value="All department" style={{ color: "#24a184" }}>
							All Department
						</MenuItem>
						{departments.map((dept) => (
							<MenuItem key={dept.department_id} value={dept.department_id}>
								{dept.deptname}
							</MenuItem>
						))}
					</Select>
				)} */}

				{/* Individual User Dropdown */}
				{activeTab === "Individual" && showDepartmentSelectuser && (
					<div>
						{/* <Select
							value={selectedval?.uuid || ""}
							onChange={(e) => {
								const selectedUser = data.find((item) => item.uuid === e.target.value);
								setselectedval(selectedUser || null);
							}}
							size="small"
							displayEmpty
							style={{
								minWidth: "150px",
								marginRight: "1rem",
							}}
						>
							<MenuItem value="" style={{ color: "#24a184" }}>
								Select Employee
							</MenuItem>
							{data.map((item) => (
								<MenuItem key={item.uuid} value={item.uuid}>
									{item.label}
								</MenuItem>
							))}
						</Select> */}
						{/* <Select
							value={selectedval?.uuid || ""}
							onChange={async (e) => {
								setnewModalIsOpen(true)
								handleOpenModal()
								const selectedUser = data.find((item) => item.uuid === e.target.value);
								setselectedval(selectedUser || null);
								if (selectedUser) {
									await getMonthWiseData(selectedUser.uuid);
									// setModalIsOpen(true);
								}
							}}
							size="small"
							displayEmpty
							style={{
								minWidth: "150px",
								marginRight: "1rem",
								padding: "0px",
								fontSize: "12px" // Reduced padding
							}}
						>
							<MenuItem value="" style={{ color: "#24a184" }}>
								Select Employee
							</MenuItem>
							{data.map((item) => (
								<MenuItem key={item.uuid} value={item.uuid}>
									{item.label}
								</MenuItem>
							))}
						</Select> */}

					</div>
				)}

				{/* DatePicker aligned to the extreme right */}
				<div style={{ marginLeft: "auto" }}>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							views={["month", "year"]}
							value={selectedDate}
							onChange={(newValue) => setSelectedDate(newValue)}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									sx={{
										minWidth: "80px", // Reduced width
										"& .MuiInputBase-root": {
											fontSize: "10px", // Smaller font size
											padding: "0px 8px", // Reduced padding
										},
										"& .MuiSvgIcon-root": {
											fontSize: "12px", // Smaller icon size
										},
									}}
								/>
							)}
						/>
					</LocalizationProvider> */}

					{/* <button
						onClick={() => setIsVisible(true)} // Show the picker on button click
						style={{
							fontSize: "10px",
							padding: "4px 8px",
							minWidth: "80px",
						}}
					>
						{`${selectedMonth.month}/${selectedMonth.year}`}
					</button> */}
					<div className="flex">
						<div>
							<input
								type="text"
								className="mr-1"
								placeholder="Search"
								style={{
									fontSize: "12px",
									height: "37px",
									borderRadius: "5px",
									width: "200px",
								}}
								value={searchQuery}
								onChange={handleSearch}
							/>
						</div>
						<div className="input-group mr-2 calenderdate">
							<input
								type="text"
								style={{ fontSize: "12px" }}
								onFocus={(e) => showMonthPicker(e)}
								value={getMonthValue()}
								className="form-control calenderdate1"
								id="basic-url"
								aria-describedby="basic-addon3"
								onChange={() => { }}
							/>
							<div className="input-group-append calendaricon">
								<FaCalendar
									style={{
										fontSize: "12px",
									}}
									onClick={(e) => showMonthPicker(e)}
								/>
							</div>
							<ReactMonthPicker
								show={isVisible}
								lang={[
									"Jan",
									"Feb",
									"Mar",
									"Apr",
									"May",
									"Jun",
									"Jul",
									"Aug",
									"Sep",
									"Oct",
									"Nov",
									"Dec",
								]}
								value={selectedMonth}
								onChange={handleOnChange}
								onDismiss={handleOnDismiss}
							/>
						</div>
					</div>
				</div>
			</div>

			{departmentLoading ? (
				<div className="loader-container flex justify-center content-center items-center" style={{ height: "100vh" }}>
					<HashLoader color="#5755d9" className="mt-8" size={30} />
				</div>
			) : (
				<>
					{/* Attendance Table */}
					<div className="attendance-table">
						<div
							className="rounded-t-lg"
							style={{
								backgroundColor: "#fc9a30",
								padding: "8px 16px",
								borderRadius: "10px 10px 0 0",

							}}
						>
							<h2 className="font-medium text-lg text-white text-left">Attendance Report</h2>
						</div>
						{filteredData?.length > 0 ? (
							<table
								className="table-auto w-full border-collapse border border-gray-200 text-sm rounded-b-lg"
								style={{ borderRadius: "10px" }}
							>
								<thead className="bg-gray-100">
									<tr>
										<th className="border border-gray-200 p-2 text-left">Name</th>
										<th className="border border-gray-200 p-2 text-left">Working Days</th>
										<th className="border border-gray-200 p-2 text-left">Sandwich Days</th>
										<th className="border border-gray-200 p-2 text-left">Present Days</th>
										<th className="border border-gray-200 p-2 text-left">Month Leaves</th>
										<th className="border border-gray-200 p-2 text-left">Non WD</th>
										<th className="border border-gray-200 p-2 text-center">Eligible for PL</th>
										<th className="border border-gray-200 p-2 text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{filteredData?.map((user, index) => (
										<tr
											key={user.uuid}
											className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"
												}`}
										>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.name || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-gray-700">
												{user.attendance_summary?.totalWorkingDays || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-gray-700">
												{user.attendance_summary?.sandwichLeave || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-gray-700">
												{user.attendance_summary?.totalPresentDays || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-gray-700">
												{user.attendance_summary?.monthLeaves || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-gray-700">
												{user.attendance_summary?.weekOffs +
													(user.attendance_summary?.holidays || 0) || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-center text-gray-700">
												Yes
											</td>
											<td className="border border-gray-200 p-2 text-center">
												<Button
													variant="outlined"
													size="small"
													style={{
														color: "#fc9a30",
														borderColor: "#fc9a30",
													}}
													onClick={() => handleViewDetails(user.uuid)}
												>
													View Details
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-gray-500">No attendance data available</p>
						)}

					</div>
					<Modal
						isOpen={newModalIsOpen}
						onRequestClose={closeModal}
						style={{
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
							},
							content: {
								top: "50%",
								left: "50%",
								right: "auto",
								bottom: "auto",
								marginRight: "-50%",
								transform: "translate(-50%, -50%)",
								maxWidth: "90%", // Increased width
								maxHeight: "90%", // Increased height
								width: "90%", // Set width to 90%
								height: "98%", // Set height to 90%
								padding: "0",
								borderRadius: "12px",
								backgroundColor: "#f9f9f9",
								boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
							},
						}}
					>
						{isLoad ? (
							<div className="flex justify-center items-center" style={{ height: "300px" }}>
								<HashLoader color="#5755d9" size={20} style={{ marginTop: "140px" }} />
							</div>
						) : (
							<div className="intro-y overflow-x-auto">
								{/* Header Section */}
								<div
									style={{ backgroundColor: "#fc9a30" }}
									className="flex justify-between p-2 items-center text-gray-700 bg-[#fc9a30] px-1"
								>
									<div className="flex justify-center content-center">
										<div>
											<h2 className="text-lg text-white font-semibold pl-3">{selectedEmpName}</h2>
										</div>
										<div className="ml-3 content-center">
											<CSVLink
												data={csvData}
												headers={headers}
												style={{ backgroundColor: "#24a184" }}
												filename={`${selectedEmpName || "Employee"}_Attendance_Report.csv`}
												className="bg-green text-white content-center"
											>
												{/* Export Monthly Report */}
												<div
													className="flex items-center justify-center px-2 py-1 rounded bg-green-500"
													style={{ border: "1px solid #fff", borderRadius: "5px", fontWeight: "bold" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="#fff"
														className="bi bi-download"
														viewBox="0 0 16 16"
													>
														<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
														<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
													</svg>
												</div>
											</CSVLink>
										</div>
									</div>
									<div className="ml-auto pr-2 flex content-center">
										<h2 className="text-white content-center mt-1">Shift Time: {formatTime(shiftStartTime)} - {formatTime(shiftEndTime)}</h2>
										<button
											className="  text-white bg-red-500 hover:bg-red-600 rounded-full focus:outline-none mx-2"
											onClick={closeModal}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												fill="currentColor"
												className="bi bi-x"
												viewBox="0 0 16 16"
											>
												<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
											</svg>
										</button>
									</div>
								</div>

								{/* Table Section */}
								<table
									className="table-auto w-full text-center border-collapse border border-gray-300"
									style={{
										borderRadius: "10px 10px 0 0",
										width: "100%",
										overflow: "hidden",
										backgroundColor: "#ffffff",
									}}
								>
									<thead>
										<tr className="bg-gray-100 text-gray-700">
											{[
												"Date",
												"In Time",
												"Late",
												"Lunch HRS",
												"Out Time",
												"Pending HRS",
												"Total HRS",
												"Other Break",
												"After Office HRS",
												"1st/2nd Half",
											].map((header, idx) => (
												<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "12px" }}>
													{header}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{attdata?.map((row, index) => (
											<tr key={index} className="bg-white hover:bg-gray-100 text-gray-700">
												{[
													row?.date ? moment(row.date).format("DD/MM/YY") : "-",
													row?.start_time || "-",
													row?.late_coming || "-",
													row?.lunch_break_duration || "-",
													row?.end_time || "-",
													row?.pending_time || "-",
													row?.total_working_hours || "-",
													row?.other_break_duration || "-",
													row?.after_office_hours || "-",
													// Check for half_day_type and on_leave
													row?.on_leave
														? "A"  // If on leave, show A
														: row?.half_day_type === "First Half"
															? "A/P"  // If First Half, show A/P
															: row?.half_day_type === "Second Half"
																? "P/A"  // If Second Half, show P/A
																: "P/P",  // Default: P/P if no half_day_type or on_leave
												].map((cell, idx) => (
													<td
														key={idx}
														className="border border-gray-300 p-1"
														style={{
															fontWeight: 500,
															fontSize: "11px",
															color: "#8F8F8F",
															textAlign: "center",
														}}
													>
														{cell}
													</td>
												))}
											</tr>

										))}
									</tbody>

									<tfoot style={{ backgroundColor: "#fc9a30" }}>
										<tr className="bg-orange-500 text-white">
											{[
												"Total",
												"-",
												"-",
												"-",
												"-",
												pendingHours,
												totalHours,
												"-",
												"-",
												"-",
											].map((footer, idx) => (
												<td
													key={idx}
													className="border border-gray-300 p-2"
													style={{
														fontFamily: "Inter",
														fontWeight: 500,
														fontSize: "15px",
														lineHeight: "21.78px",
														color: "white",
														textAlign: "center",
													}}
												>
													{footer}
												</td>
											))}
										</tr>
									</tfoot>
								</table>

								{/* Export Button */}
								{/* <div className="flex justify-center mt-4">
									<CSVLink
										data={csvData}
										headers={headers}
										style={{ backgroundColor: "#24a184" }}
										filename={`${selectedEmpName || "Employee"}_Attendance_Report.csv`}
										className="bg-green-500 text-white font-semibold px-4 py-2 rounded hover:bg-green-600"
									>
										Export Monthly Report
									</CSVLink>
								</div> */}

							</div>
						)}
					</Modal>


				</>
			)
			}
		</div >

	);
};

export default AttendanceReport;

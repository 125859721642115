import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";

import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";

const JobVacancies = () => {
	const [notidata, setnotidata] = useState(null);

	const [data, setdata] = useState({
		id: "",
		title: "",
		description: "",
	});

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState(false);
	let [isauth] = useState(JSON.parse(localStorage.getItem("isAuth")));

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllPositions/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: userdata.companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setnotidata(res.data);
				} else {
					setnotidata({});
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const handlechange = (e) => {
		let value = e.target.value;
		setdata({ ...data, [e.target.name]: value });
	};

	const savedata = async () => {
		if (data.title === "" || data.description === "") {
			swal({
				title: "Error",
				text: "Please Enter value",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `save-position/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: userdata.companydata[0].uuid,
				data,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setmsg(res.message);
					setdata({
						title: "",
						description: "",
					});
					getData();
					swal({
						title: "Success",
						text: res.message,
						icon: "success",
						timer: 2000,
					});
					setTimeout(() => {
						setmsg("");
						setisadd(!isadd);
					}, 1000);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const editdata = (data) => {
		setisadd(true);
		setdata({
			id: data.id,
			title: data.name,
			description: data.descriptions,
		});
	};

	const adddata = (data) => {
		setisadd(!isadd);
		setdata({
			id: "",
			title: "",
			description: "",
		});
	};

	const deletedata = (uuid) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Position?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-position/`, {
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						uuid,
					}),
				})
					.then((response) => response.json())
					.then((res) => {
						getData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">Job Vacancies</h2>
				{(isauth.master == 1 || isauth.master == 0) && (
					<button
						type="button"
						onClick={() => adddata()}
						className="btn btn-primary w-50"
					>
						Add Vacancy
					</button>
				)}
			</div>
			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div className="flex-1 mt-6 xl:mt-0">
							<div className="grid grid-cols-12 gap-x-5">
                <div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Positions
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.title}
                      type="text"
                      name="title"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    Description
                  </label>
                  <br />
                  <textarea
                    name="description"
                    className="form-control"
                    cols="80"
                    rows="2"
                    onChange={handlechange}
                    value={data && data.description}
                  ></textarea>
                </div>
              </div>
							<button
								type="button"
								onClick={() => savedata()}
								className="btn btn-primary w-20 mt-3"
							>
								Save
							</button>
							<button
								type="button"
								onClick={() => adddata()}
								className="btn btn-danger w-20 mt-3 ml-2"
							>
								Cancel
							</button>
							<br />
							<br />
							<span className="text-success">
								{msg ? msg : null}
							</span>
						</div>
					) : null}
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<table
							className="table table-bordered"
							style={{ width: "100%" }}
						>
							<thead>
								<tr>
									{/* <th className="whitespace-nowrap">#</th> */}
									<th className="whitespace-nowrap">
										Position
									</th>
									{(isauth.master == 1 ||
										isauth.role == 1 ||
										isauth.role == 0) && (
										<th className="whitespace-nowrap">
											Actions
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{notidata && notidata.length > 0 ? (
									notidata.map((item, i) => {
										return (
											<tr>
												{/* <td>{item.id}</td> */}
												<td>{item.name}</td>
												{(isauth.master == 1 ||
													isauth.role == 1 ||
													isauth.role == 0) && (
													<td>
														<div className="flex lg:justify-center items-center">
															<Link
																to={
																	"/admin/14/" +
																	item.uuid
																}
																className="edit flex items-center mr-3"
																style={{
																	cursor: "pointer",
																}}
															>
																{item.isaddedque ==
																1 ? (
																	<span className="text-success">
																		+ Add
																		Questions
																	</span>
																) : (
																	<span className="text-danger">
																		+ Add
																		Questions
																	</span>
																)}
															</Link>
															{isauth.master ==
																0 ||
																(isauth.role ==
																	0 && (
																	<>
																		<div
																			className="edit flex items-center mr-3"
																			onClick={() =>
																				editdata(
																					item
																				)
																			}
																			style={{
																				cursor: "pointer",
																			}}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="24"
																				height="24"
																				viewBox="0 0 24 24"
																				fill="none"
																				stroke="currentColor"
																				stroke-width="2"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																				icon-name="check-square"
																				data-lucide="check-square"
																				className="lucide lucide-check-square w-4 h-4 mr-1"
																			>
																				<polyline points="9 11 12 14 22 4"></polyline>
																				<path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
																			</svg>
																			Edit
																		</div>

																		<div
																			className="delete flex items-center text-danger"
																			onClick={() =>
																				deletedata(
																					item.uuid
																				)
																			}
																			style={{
																				cursor: "pointer",
																			}}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="24"
																				height="24"
																				viewBox="0 0 24 24"
																				fill="none"
																				stroke="currentColor"
																				stroke-width="2"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																				icon-name="trash-2"
																				data-lucide="trash-2"
																				className="lucide lucide-trash-2 w-4 h-4 mr-1"
																			>
																				<polyline points="3 6 5 6 21 6"></polyline>
																				<path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
																				<line
																					x1="10"
																					y1="11"
																					x2="10"
																					y2="17"
																				></line>
																				<line
																					x1="14"
																					y1="11"
																					x2="14"
																					y2="17"
																				></line>
																			</svg>
																			Delete
																		</div>
																	</>
																))}
														</div>
													</td>
												)}
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan="5">No data available</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	);
};

export default JobVacancies;

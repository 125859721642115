import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { FaCalendar, FaFileCsv } from "react-icons/fa";
import { ReactTabulator } from "react-tabulator";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";

const Summary = () => {
	let [loading, setLoading] = useState(false);
	const [attdata, setattdata] = useState(null);

	const [csvData, setcsvData] = useState([]);
	const [currdate] = useState(new Date());
	const [value, onChange] = useState([
		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
		new Date(),
	]);
	const [columns, setcolumn] = useState([]);

	useEffect(() => {
		getData(
			format(new Date(value[0]), "yyyy-MM-dd"),
			format(new Date(value[1]), "yyyy-MM-dd")
		);
	}, []);

	const options = {
		pagination: "local",
		paginationSize: 50,
	};

	const getData = async (date, ldate) => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-datewise-attreport`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				username: "",
				date,
				ldate,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setattdata(res.data);
					var columns = [
						{ title: "Employee", field: "name", width: 220 },
						{ title: "Username", field: "username", width: 120 },
						{ title: "Status", field: "status", width: 90 },
					];
					res.datearr.forEach((element) => {
						columns.push({
							title: element,
							field: element,
							width: 140,
						});
					});

					columns.push(
						{ title: "Working Time", field: "working", width: 160 },
						{ title: "Idle Time", field: "idletime", width: 140 },
						{ title: "Productive Time", field: "prod", width: 180 },
						{ title: "Productive %", field: "prodper", width: 140 },
						{ title: "Absent", field: "absent", width: 140 }
					);
					setcolumn(columns);
					setcsvData([...res.reparr]);
				} else {
					setattdata([
						{
							date: "No data Available",
						},
					]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const handledate = (e) => {
		onChange(e);
		getData(
			format(new Date(e[0]), "yyyy-MM-dd"),
			format(new Date(e[1]), "yyyy-MM-dd")
		);
	};

	return (
		<>
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 2xl:col-span-12">
					<div className="intro-y flex flex-col sm:flex-row items-center mt-8">
						<h2 className="text-lg font-medium mr-auto">
							Attendance Report ++
						</h2>
						<div className="ml-auto flex items-center text-primary">
							<DateRangePicker
								clearIcon=""
								calendarIcon={
									<FaCalendar
										style={{ color: "rgb(30 64 175)" }}
									/>
								}
								className="form-control"
								onChange={handledate}
								value={value}
							/>
							<CSVLink
								filename={"All-Employees-Attendance.csv"}
								className="ml-3 mr-3"
								data={csvData}
							>
								<FaFileCsv
									style={{
										color: "rgb(30 64 175)",
										fontSize: "25px",
									}}
								/>
							</CSVLink>
						</div>
					</div>
					{loading === true ? (
						<div
							className="mt-5 "
							style={{ display: "-webkit-inline-box" }}
						>
							<HashLoader color="#5755d9" size={30} />
						</div>
					) : (
						<div className="intro-y">
							<div className="overflow-x-auto scrollbar-hidden">
								{attdata ? (
									<ReactTabulator
										columns={columns}
										data={attdata}
										options={options}
										className="mt-5 table-report table-report--tabulator"
									/>
								) : (
									<div className="text-danger text-left mt-5 mb-5">
										No Data Available
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Summary;


// import React, { useState } from "react";
// import {
// 	FaBars,
// 	FaTimesCircle,
// 	FaChevronDown,
// 	FaChevronUp,
// 	FaHome,
// 	FaUser,
// 	FaClock,
// 	FaProjectDiagram,
// 	FaTasks,
// 	FaFile,
// 	FaUserCircle,
// } from "react-icons/fa";
// import { Link, useLocation } from "react-router-dom";

// const MobileNav = () => {
// 	const location = useLocation();
// 	const [menu1, setmenu1] = useState(false);
// 	const [menu2, setmenu2] = useState(false);
// 	const [menu3, setmenu3] = useState(false);
// 	const [ismenu, setismenu] = useState(false);
// 	const [isAuth, setIsAuth] = useState(
// 		JSON.parse(localStorage.getItem("isAuth"))
// 	);
// 	const [master] = React.useState(
// 		localStorage.getItem("isAuth") &&
// 			JSON.parse(localStorage.getItem("isAuth")).master
// 	);

// 	return (
// 		<>
// 			<div
// 				className={
// 					ismenu === true
// 						? "mobile-menu mobile-menu--active"
// 						: "mobile-menu md:hidden"
// 				}
// 			>
// 				<div className="mobile-menu-bar">
// 					<Link to={"/"} className="flex mr-auto">
// 						<img
// 							alt="Midone - HTML Admin Template"
// 							className="w-6"
// 							src="../images/logo.svg"
// 						/>
// 						<span className="xl:block text-white text-lg ml-3">
// 							{isAuth &&
// 								isAuth.companydata &&
// 								isAuth.companydata[0].name}{" "}
// 							Tracking
// 						</span>
// 					</Link>
// 					<div className="mobile-menu-toggler">
// 						<FaBars
// 							onClick={() => setismenu(!ismenu)}
// 							className="text-white"
// 						></FaBars>
// 					</div>
// 				</div>
// 				<div className="scrollable" style={{ overflowY: "overlay" }}>
// 					<div className="mobile-menu-toggler">
// 						<FaTimesCircle
// 							onClick={() => setismenu(!ismenu)}
// 							className="text-white mt-2"
// 						></FaTimesCircle>
// 					</div>

// 					<ul>
// 						<li>
// 							<Link
// 								onClick={() => setismenu(!ismenu)}
// 								to={"/"}
// 								className={
// 									location.pathname === "/"
// 										? "menu menu--active"
// 										: "menu"
// 								}
// 							>
// 								<div className="menu__icon">
// 									<FaHome />
// 								</div>
// 								<div className="menu__title">
// 									Home
// 									<div className="menu__sub-icon transform rotate-180">
// 										<i data-lucide="chevron-down"></i>
// 									</div>
// 								</div>
// 							</Link>

// 							{isAuth.role === 0 ? (
// 								<>
// 									<div
// 										className="menu"
// 										onClick={() => setmenu3(!menu3)}
// 									>
// 										<div className="menu__icon">
// 											<FaProjectDiagram />
// 										</div>
// 										<div className="menu__title">
// 											Dashboard
// 											<div className="menu__sub-icon">
// 												{menu3 ? (
// 													<FaChevronUp />
// 												) : (
// 													<FaChevronDown />
// 												)}	
// 											</div>
// 										</div>
// 									</div>

// 									<ul
// 										className={
// 											menu3 ? "menu__sub-open" : null
// 										}
// 									>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/dashboard/summary"
// 												className={
// 													location.pathname ===
// 													"/dashboard/summary"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Summary
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/dashboard/desktop-activity"
// 												className={
// 													location.pathname ===
// 													"/dashboard/desktop-activity"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Desktop Activity
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/dashboard/app-usage"
// 												className={
// 													location.pathname ===
// 													"/dashboard/app-usage"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													App Usages
// 												</div>
// 											</Link>
// 										</li>
// 									</ul>

// 									{/* className={location.pathname==="/as" ? 'menu menu--active': 'menu'} */}
// 									<div
// 										className="menu"
// 										onClick={() => setmenu1(!menu1)}
// 									>
// 										<div className="menu__icon">
// 											<FaProjectDiagram />
// 										</div>
// 										<div className="menu__title">
// 											App Insights
// 											<div className="menu__sub-icon">
// 												{menu1 ? (
// 													<FaChevronUp />
// 												) : (
// 													<FaChevronDown />
// 												)}
// 											</div>
// 										</div>
// 									</div>

// 									<ul
// 										className={
// 											menu1 ? "menu__sub-open" : null
// 										}
// 									>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/insights/summary"
// 												className={
// 													location.pathname ===
// 													"/insights/summary"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Summary
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/insights/timeline"
// 												className={
// 													location.pathname ===
// 													"/insights/timeline"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Timeline
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/insights/productivity"
// 												className={
// 													location.pathname ===
// 													"/insights/productivity"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Productivity
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/insights/screenshots"
// 												className={
// 													location.pathname ===
// 													"/insights/screenshots"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Screenshots
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/insights/applications"
// 												className={
// 													location.pathname ===
// 													"/insights/applications"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Applications
// 												</div>
// 											</Link>
// 										</li>
// 									</ul>

// 									<Link
// 										onClick={() => setismenu(!ismenu)}
// 										to={"/employees"}
// 										className={
// 											location.pathname === "/employees"
// 												? "menu menu--active"
// 												: "menu"
// 										}
// 									>
// 										<div className="menu__icon">
// 											<FaUser />
// 										</div>
// 										<div className="menu__title">
// 											Employee
// 											<div className="menu__sub-icon transform rotate-180">
// 												<i data-lucide="chevron-down"></i>
// 											</div>
// 										</div>
// 									</Link>
// 								</>
// 							) : null}
// 							{master !== 1 && (
// 								<>
// 									<Link
// 										onClick={() => setismenu(!ismenu)}
// 										to={"/leaves"}
// 										className={
// 											location.pathname === "/leaves"
// 												? "menu menu--active"
// 												: "menu"
// 										}
// 									>
// 										<div className="menu__icon">
// 											<FaUser />
// 										</div>
// 										<div className="menu__title">
// 											Leaves
// 											<div className="menu__sub-icon transform rotate-180">
// 												<i data-lucide="chevron-down"></i>
// 											</div>
// 										</div>
// 									</Link>

// 									<Link
// 										to="#"
// 										className={"menu"}
// 										onClick={() => setmenu2(!menu2)}
// 									>
// 										<div className="menu__icon">
// 											<FaClock />
// 										</div>
// 										<div className="menu__title">
// 											Attendance
// 											<div className="menu__sub-icon transform rotate-180">
// 												{!menu2 ? (
// 													<FaChevronUp />
// 												) : (
// 													<FaChevronDown />
// 												)}
// 											</div>
// 										</div>
// 									</Link>

// 									<ul
// 										className={
// 											menu2 ? "menu__sub-open" : null
// 										}
// 									>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/attendance/attendance-details"
// 												className={
// 													location.pathname ===
// 													"/attendance/attendance-details"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Attendance Details
// 												</div>
// 											</Link>
// 										</li>
// 										<li>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to="/attendance/monthly-records"
// 												className={
// 													location.pathname ===
// 													"/attendance/monthly-records"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<i data-lucide="activity"></i>
// 												</div>
// 												<div className="menu__title">
// 													Monthly Records
// 												</div>
// 											</Link>
// 										</li>
// 									</ul>
// 								</>
// 							)}

// 							{isAuth.role === 0 ? (
// 								<>
// 									{master !== 1 && (
// 										<>
// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to={"/feeds"}
// 												className={
// 													location.pathname ===
// 													"/feeds"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<FaUser />
// 												</div>
// 												<div className="menu__title">
// 													Feeds
// 													<div className="menu__sub-icon transform rotate-180">
// 														<i data-lucide="chevron-down"></i>
// 													</div>
// 												</div>
// 											</Link>

// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to={"/employee-activity"}
// 												className={
// 													location.pathname ===
// 													"/employee-activity"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<FaTasks />
// 												</div>
// 												<div className="menu__title">
// 													Employee Activity
// 													<div className="menu__sub-icon transform rotate-180">
// 														<i data-lucide="chevron-down"></i>
// 													</div>
// 												</div>
// 											</Link>

// 											<Link
// 												onClick={() =>
// 													setismenu(!ismenu)
// 												}
// 												to={"/report"}
// 												className={
// 													location.pathname ===
// 													"/report"
// 														? "menu menu--active"
// 														: "menu"
// 												}
// 											>
// 												<div className="menu__icon">
// 													<FaFile />
// 												</div>
// 												<div className="menu__title">
// 													Report
// 													<div className="menu__sub-icon transform rotate-180">
// 														<i data-lucide="chevron-down"></i>
// 													</div>
// 												</div>
// 											</Link>
// 										</>
// 									)}

// 									<Link
// 										onClick={() => setismenu(!ismenu)}
// 										to={"/admin"}
// 										className={
// 											location.pathname === "/admin"
// 												? "menu menu--active"
// 												: "menu"
// 										}
// 									>
// 										<div className="menu__icon">
// 											<FaUserCircle />
// 										</div>
// 										<div className="menu__title">
// 											Admin
// 											<div className="menu__sub-icon transform rotate-180">
// 												<i data-lucide="chevron-down"></i>
// 											</div>
// 										</div>
// 									</Link>
// 								</>
// 							) : null}
// 						</li>
// 					</ul>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default MobileNav;



import React, { useState } from "react";
import {
	FaBars,
	FaTimesCircle,
	FaChevronDown,
	FaChevronUp,
	FaHome,
	FaUser,
	FaClock,
	FaProjectDiagram,
	FaTasks,
	FaFile,
	FaUserCircle,
	FaCalendarAlt,
	FaRegCalendarCheck,
	FaRegCalendarAlt,
	FaUserShield,
	FaUserTie,
	FaSearch,
	FaPersonBooth,
	FaTicketAlt,
	FaSignOutAlt,
	FaTools,
} from "react-icons/fa";
import {
	BsReception4,
	BsFillCalendarCheckFill,
	// IoTicketSharp,
	BsFillCalendar2WeekFill,
} from "react-icons/bs";
import { IoLaptopOutline } from "react-icons/io5";
import {
	MdTimeline,
	MdOutlineScreenshot,
	MdApps,
	MdSummarize,
	MdDynamicFeed,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const MobileNav = () => {
	const location = useLocation();
	const [menu1, setmenu1] = useState(false);
	const [menu4, setmenu4] = useState(false);
	const [menu2, setmenu2] = useState(false);
	const [menu3, setmenu3] = useState(false);
	const [ismenu, setismenu] = useState(false);
	const [menuAttendance, setmenuAttendance] = useState(false);
	const [menuattendanceproject, setmenuattendanceproject] = useState(false);
	// const [menuattendanceemployee, setmenuattendanceemploye] = useState(false);
	const [menuattendanceemployee, setmenuattendanceemployee] = useState(false);
	const [menuattendancejob, setmenuattendancejob] = useState(false);
	const [menu5, setmenu5] = useState(false);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [master] = React.useState(
		localStorage.getItem("isAuth") &&
		JSON.parse(localStorage.getItem("isAuth")).master
	);

	const [type] = React.useState(
		localStorage.getItem("isAuth") &&
		JSON.parse(localStorage.getItem("isAuth")).type
	);

	let [tabtype, settabtype] = useState(
		JSON.parse(localStorage.getItem("isAuth")).master == 1 ? 3 : 0
	);
	return (
		<>
			<div
				className={
					ismenu === true
						? "mobile-menu mobile-menu--active"
						: "mobile-menu md:hidden"
				}
			>
				<div className="mobile-menu-bar">
					<Link to={"/"} className="flex mr-auto">
						<img
							alt="Midone - HTML Admin Template"
							className="w-6"
							src="../images/logo.svg"
						/>
						<span className="xl:block text-white text-lg ml-3">
							{isAuth &&
								isAuth.companydata &&
								isAuth.companydata[0].name}{" "}
							Tracking
						</span>
					</Link>
					<div className="mobile-menu-toggler">
						<FaBars
							onClick={() => setismenu(!ismenu)}
							className="text-white"
						></FaBars>
					</div>
				</div>
				<div className="scrollable" style={{ overflowY: "overlay" }}>
					<div className="mobile-menu-toggler">
						<FaTimesCircle
							onClick={() => setismenu(!ismenu)}
							className="text-white mt-2"
						></FaTimesCircle>
					</div>

					<ul>
						<li>
							<Link
								onClick={() => setismenu(!ismenu)}
								to={"/"}
								className={
									location.pathname === "/"
										? "menu menu--active"
										: "menu"
								}
							>
								<div className="menu__icon">
									<FaHome />
								</div>
								<div className="menu__title">
									Home
									<div className="menu__sub-icon transform rotate-180">
										<i data-lucide="chevron-down"></i>
									</div>
								</div>
							</Link>

							{/* Productivity Mobile Menu */}
							{/* =========================================================================== */}
							{/* {isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenu3(!menu3)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											Productivity
											<div className="menu__sub-icon">
												{menu3 ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>

									<ul className={menu3 ? "menu__sub-open" : null}>
										<li>
											<Link
												onClick={() => setismenu(!ismenu)}
												to="/dashboard/summary"
												className={location.pathname === "/dashboard/summary" ? "menu menu--active" : "menu"}
											>
												<div className="menu__icon">
													<MdSummarize />
												</div>
												<div className="menu__title">Team Overview</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() => setismenu(!ismenu)}
												to="/insights/summary"
												className={location.pathname === "/insights/summary" ? "menu menu--active" : "menu"}
											>
												<div className="menu__icon">
													<MdSummarize />
												</div>
												<div className="menu__title">Employee Overview</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() => setismenu(!ismenu)}
												to="/dashboard/desktop-activity"
												className={location.pathname === "/dashboard/desktop-activity" ? "menu menu--active" : "menu"}
											>
												<div className="menu__icon">
													<IoLaptopOutline />
												</div>
												<div className="menu__title">Employee Activity</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() => setismenu(!ismenu)}
												to="/insights/productivity"
												className={location.pathname === "/insights/productivity" ? "menu menu--active" : "menu"}
											>
												<div className="menu__icon">
													<BsReception4 />
												</div>
												<div className="menu__title">Emp Productivity</div>
											</Link>
										</li>
									</ul>
								</>
							) : null} */}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenu3(!menu3)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											Productivity
											<div className="menu__sub-icon">
												{menu3 ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>
									<ul className={menu3 ? "menu__sub-open" : null}>
										{type !== 2 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/dashboard/summary"
													className={
														location.pathname === "/dashboard/summary"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<MdSummarize />
													</div>
													<div className="menu__title">Team Overview</div>
												</Link>
											</li>
										)}
										{type !== 2 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/insights/summary"
													className={
														location.pathname === "/insights/summary"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<MdSummarize />
													</div>
													<div className="menu__title">Employee Overview</div>
												</Link>
											</li>
										)}
										<li>
											<Link
												onClick={() => setismenu(false)}
												to="/dashboard/desktop-activity"
												className={
													location.pathname === "/dashboard/desktop-activity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<IoLaptopOutline />
												</div>
												<div className="menu__title">Employee Activity</div>
											</Link>
										</li>
										{type !== 2 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/insights/productivity"
													className={
														location.pathname === "/insights/productivity"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<BsReception4 />
													</div>
													<div className="menu__title">Emp Productivity</div>
												</Link>
											</li>
										)}
										{type !== 2 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/dashboard/app-usage"
													className={
														location.pathname === "/dashboard/app-usage"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<MdApps />
													</div>
													<div className="menu__title">App Usages</div>
												</Link>
											</li>
										)}
										{(type === 0 && master === 0) && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/insights/screenshots"
													className={
														location.pathname === "/insights/screenshots"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<MdOutlineScreenshot />
													</div>
													<div className="menu__title">Screenshots</div>
												</Link>
											</li>
										)}
										{master === 1 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/insights/ScreenshotsAdmin"
													className={
														location.pathname === "/insights/ScreenshotsAdmin"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<MdOutlineScreenshot />
													</div>
													<div className="menu__title">ScreenshotsAdmin</div>
												</Link>
											</li>
										)}
									</ul>
								</>
							) : null}


							{/* Attendance Table */}

							{/* ===================================================================== */}

							{type !== 3 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenuAttendance(!menuAttendance)}
									>
										<div className="menu__icon">
											<FaRegCalendarAlt />
										</div>
										<div className="menu__title">
											Attendance
											<div className="menu__sub-icon">
												{menuAttendance ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>
									<ul className={menuAttendance ? "menu__sub-open" : null}>
										<li>
											<Link
												onClick={() => setismenu(!ismenu)}
												to="/attendance/monthly-records"
												className={
													location.pathname === "/attendance/monthly-records"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<BsFillCalendar2WeekFill />
												</div>
												<div className="menu__title">Emp Monthly Records</div>
											</Link>
										</li>
										{type !== 2 && type !== 3 ? (
											<li>
												<Link
													onClick={() => setismenu(!ismenu)}
													to="/attendance/monthly-attendance-report"
													className={
														location.pathname ===
															"/attendance/monthly-attendance-report"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<FaRegCalendarCheck />
													</div>
													<div className="menu__title">
														Dept Monthly Att Report
													</div>
												</Link>
											</li>
										) : null}
									</ul>
								</>
							) : null}

							{/* Project */}

							{/* ================================================================================= */}
							{/* {type !== 3 && type !== 2 ? (
								<>
									<div
										className="side-menu"
										onClick={() =>
											setmenuattendanceproject(
												!menuattendanceproject
											)
										}
									>
										<div className="side-menu__icon">
											<FaTasks />
										</div>
										<div
											className="side-menu__title"
											style={{ cursor: "pointer" }}
										>
											Project
											<div className="side-menu__sub-icon">
												{menuattendanceproject ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</div>
									<ul
										className={
											menuattendanceproject
												? "side-menu__sub-open"
												: null
										}
									>
										<li>
											<Link
												to={"/clients"}
												className={
													location.pathname ===
														"/clients"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaUserCircle />
												</div>
												<div className="side-menu__title">
													Clients List
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>
										</li>

										<li>
											<Link
												to={"/admin/21"}
												className={
													tabtype === 21 ||
														tabtype === 22
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaUserShield />
												</div>
												<div className="side-menu__title">
													Projects List
												</div>
											</Link>
										</li>
										<li>
											<Link
												to={"/admin/18"}
												className={
													tabtype === 18
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaUserShield />
												</div>
												<div className="side-menu__title">
													Manage Project Hours
												</div>
											</Link>
										</li>
										<li>
											<Link
												to={"/assign-project-to-client"}
												className={
													location.pathname ===
														"/assign-project-to-client"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaProjectDiagram />
												</div>
												<div className="side-menu__title">
													Assign Project to Client
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>
										</li>
									</ul>
								</>
							) : null} */}


							{type !== 3 && type !== 2 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenuattendanceemployee(!menuattendanceemployee)}
									>
										<div className="menu__icon">
											<FaUserTie />
										</div>
										<div className="menu__title">
											Employee
											<div className="menu__sub-icon">
												{menuattendanceemployee ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>
									<ul className={menuattendanceemployee ? "menu__sub-open" : null}>
										<li>
											{type !== 2 && type !== 3 && (
												<Link
													onClick={() => setismenu(false)}
													to="/admin/3"
													className={
														location.pathname === "/admin/3"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<FaUserShield />
													</div>
													<div className="menu__title">Employees List</div>
												</Link>
											)}
										</li>
										<li>
											<Link
												onClick={() => setismenu(false)}
												to="/employees"
												className={
													location.pathname === "/employees"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaUserShield />
												</div>
												<div className="menu__title">Employees View</div>
											</Link>
										</li>
										{type !== 2 && type !== 3 && (
											<li>
												<Link
													onClick={() => setismenu(false)}
													to="/UserLoginDetails"
													className={
														location.pathname === "/UserLoginDetails"
															? "menu menu--active"
															: "menu"
													}
												>
													<div className="menu__icon">
														<FaUserShield />
													</div>
													<div className="menu__title">Exercise Opt-Outs</div>
												</Link>
											</li>
										)}
									</ul>
								</>
							) : null}



							{/* ================================================================================= */}

							{/* ===================================================================== */}

							{/* =========================================================================== */}

							{/* {isAuth.role === 0 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenu3(!menu3)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											Dashboard
											<div className="menu__sub-icon">
												{menu3 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</div>

									<ul
										className={
											menu3 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/summary"
												className={
													location.pathname ===
														"/dashboard/summary"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Summary
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/desktop-activity"
												className={
													location.pathname ===
														"/dashboard/desktop-activity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Desktop Activity
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/app-usage"
												className={
													location.pathname ===
														"/dashboard/app-usage"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													App Usages
												</div>
											</Link>
										</li>
									</ul>

									
									<div
										className="menu"
										onClick={() => setmenu1(!menu1)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											App Insights
											<div className="menu__sub-icon">
												{menu1 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</div>

									<ul
										className={
											menu1 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/summary"
												className={
													location.pathname ===
														"/insights/summary"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Summary
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/timeline"
												className={
													location.pathname ===
														"/insights/timeline"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Timeline
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/productivity"
												className={
													location.pathname ===
														"/insights/productivity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Productivity
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/screenshots"
												className={
													location.pathname ===
														"/insights/screenshots"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Screenshots
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/applications"
												className={
													location.pathname ===
														"/insights/applications"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Applications
												</div>
											</Link>
										</li>
									</ul>

									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/employees"}
										className={
											location.pathname === "/employees"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUser />
										</div>
										<div className="menu__title">
											Employee
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null} */}
							{master !== 1 && (
								<>
									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/leaves"}
										className={
											location.pathname === "/leaves"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUser />
										</div>
										<div className="menu__title">
											Leaves
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>

									<Link
										to="#"
										className={"menu"}
										onClick={() => setmenu2(!menu2)}
									>
										<div className="menu__icon">
											<FaClock />
										</div>
										<div className="menu__title">
											Attendance
											<div className="menu__sub-icon transform rotate-180">
												{!menu2 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</Link>

									<ul
										className={
											menu2 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/attendance/attendance-details"
												className={
													location.pathname ===
														"/attendance/attendance-details"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Attendance Details
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/attendance/monthly-records"
												className={
													location.pathname ===
														"/attendance/monthly-records"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Monthly Records
												</div>
											</Link>
										</li>
									</ul>
								</>
							)}









							{/* {isAuth.role === 0 ? (
								<>
									{master !== 1 && (
										<>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/feeds"}
												className={
													location.pathname ===
														"/feeds"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaUser />
												</div>
												<div className="menu__title">
													Feeds
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>

											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/employee-activity"}
												className={
													location.pathname ===
														"/employee-activity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaTasks />
												</div>
												<div className="menu__title">
													Employee Activity
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>

											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/report"}
												className={
													location.pathname ===
														"/report"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaFile />
												</div>
												<div className="menu__title">
													Report
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>
										</>
									)}

									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/admin"}
										className={
											location.pathname === "/admin"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUserCircle />
										</div>
										<div className="menu__title">
											Admin
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null} */}


							{/* Job Application */}

							{type !== 3 && type !== 2 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenuattendancejob(!menuattendancejob)}
									>
										<div className="menu__icon">
											<FaSearch />
										</div>
										<div className="menu__title">
											Job Application
											<div className="menu__sub-icon">
												{menuattendancejob ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>
									<ul className={menuattendancejob ? "menu__sub-open" : null}>
										<li>
											<Link
												onClick={() => setismenu(false)}
												to="/job-applications"
												className={
													location.pathname === "/job-applications"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaPersonBooth />
												</div>
												<div className="menu__title">
													Job Applications List
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() => setismenu(false)}
												to="/admin/13"
												className={
													tabtype === 13 ? "menu menu--active" : "menu"
												}
											>
												<div className="menu__icon">
													<FaUserShield />
												</div>
												<div className="menu__title">Job Vacancies</div>
											</Link>
										</li>
									</ul>
								</>
							) : null}
							{/* tickets */}

							{master !== 2 && (
								<>
									{isAuth.role !== 3 && (
										<>
											<div
												className="menu"
												onClick={() => setmenu4(!menu4)}
											>
												<div className="menu__icon">
													<FaTicketAlt />
												</div>
												<div className="menu__title">
													Tickets
													<div className="menu__sub-icon">
														{menu4 ? <FaChevronUp /> : <FaChevronDown />}
													</div>
												</div>
											</div>
											<ul className={menu4 ? "menu__sub-open" : null}>
												{isAuth.role === 2 ? (
													<>
														<li>
															<Link
																onClick={() => setismenu(false)}
																to="/punch-ticket"
																className={
																	location.pathname === "/punch-ticket"
																		? "menu menu--active"
																		: "menu"
																}
															>
																<div className="menu__icon">
																	<BsFillCalendarCheckFill />
																</div>
																<div className="menu__title">
																	Punch/Break Tickets
																</div>
															</Link>
														</li>
														<li>
															<Link
																onClick={() => setismenu(false)}
																to="/OtherBreakTicket"
																className={
																	location.pathname === "/OtherBreakTicket"
																		? "menu menu--active"
																		: "menu"
																}
															>
																<div className="menu__icon">
																	<BsFillCalendarCheckFill />
																</div>
																<div className="menu__title">
																	Other Break Tickets
																</div>
															</Link>
														</li>
													</>
												) : (
													<>
														<li>
															<Link
																onClick={() => setismenu(false)}
																to="/punch-ticket"
																className={
																	location.pathname === "/punch-ticket"
																		? "menu menu--active"
																		: "menu"
																}
															>
																<div className="menu__icon">
																	<BsFillCalendarCheckFill />
																</div>
																<div className="menu__title">
																	Attendance Tickets
																</div>
															</Link>
														</li>
														<li>
															<Link
																onClick={() => setismenu(false)}
																to="/punch-ticket-other-break"
																className={
																	location.pathname ===
																		"/punch-ticket-other-break"
																		? "menu menu--active"
																		: "menu"
																}
															>
																<div className="menu__icon">
																	<BsFillCalendarCheckFill />
																</div>
																<div className="menu__title">
																	Other Break Tickets
																</div>
															</Link>
														</li>
													</>
												)}
											</ul>
										</>
									)}

									{isAuth.role !== 3 && (
										<>
											<Link
												onClick={() => setismenu(false)}
												to="/leaves"
												className={
													location.pathname === "/leaves"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaSignOutAlt />
												</div>
												<div className="menu__title">Leaves</div>
											</Link>
										</>
									)}
								</>
							)}
							{type !== 2 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenu5(!menu5)}
									>
										<div className="menu__icon">
											<FaTools />
										</div>
										<div className="menu__title">
											Settings
											<div className="menu__sub-icon">
												{menu5 ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</div>
									<ul className={menu5 ? "menu__sub-open" : null}>
										{type !== 2 && (
											<>
												<li>
													<Link
														onClick={() => setismenu(false)}
														to="/admin/0"
														className={
															tabtype === 0
																? "menu menu--active"
																: "menu"
														}
													>
														<div className="menu__icon">
															<FaProjectDiagram />
														</div>
														<div className="menu__title">
															Organization Details
														</div>
													</Link>
												</li>
												<li>
													<Link
														onClick={() => setismenu(false)}
														to="/admin/1"
														className={
															tabtype === 1
																? "menu menu--active"
																: "menu"
														}
													>
														<div className="menu__icon">
															<FaUserShield />
														</div>
														<div className="menu__title">
															Departments
														</div>
													</Link>
												</li>
												<li>
													<Link
														onClick={() => setismenu(false)}
														to="/admin/4"
														className={
															tabtype === 4
																? "menu menu--active"
																: "menu"
														}
													>
														<div className="menu__icon">
															<FaUserShield />
														</div>
														<div className="menu__title">
															Working Shifts
														</div>
													</Link>
												</li>
											</>
										)}
										<li>
											<Link
												onClick={() => setismenu(false)}
												to="/admin/9"
												className={
													tabtype === 9
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaUserShield />
												</div>
												<div className="menu__title">Holidays</div>
											</Link>
										</li>
									</ul>
								</>
							) : null}



						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default MobileNav;

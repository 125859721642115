// new side bar
import React, { useEffect, useState } from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";


import { ReactComponent as AttendanceIcon } from '../assets/attendance.svg';
import { ReactComponent as Dashboard } from '../assets/Dashboard.svg';
import { ReactComponent as ProductivityIcon } from '../assets/Productivity.svg';
import { ReactComponent as ProjectIcon } from '../assets/Project.svg';
import { ReactComponent as ChatsIcon } from '../assets/Chats.svg';
import { ReactComponent as ScreenshotsIcon } from '../assets/screenshots.svg';
import { ReactComponent as UserIcon } from '../assets/User.svg';
import { ReactComponent as ExerciseOptOutsIcon } from '../assets/ExerciseOptOuts.svg';
import { ReactComponent as ClientIcon } from '../assets/Client.svg';
import { ReactComponent as JobapplicationIcon } from '../assets/Jobapplication.svg';
import { ReactComponent as ReportIcon } from '../assets/Report.svg';
import { ReactComponent as EMPActivityIcon } from '../assets/EMPActivity.svg';
import { ReactComponent as LeavesIcon } from '../assets/Leaves.svg';
import { ReactComponent as EmployeIcon } from '../assets/Employe.svg';
import { ReactComponent as CommentsIcon } from '../assets/Comments.svg';
import { ReactComponent as TaskIcon } from '../assets/Task.svg';
import { ReactComponent as TicketIcon } from '../assets/Ticket.svg';
import './sidebar.css';



// Dashboard




import {
	FaHome,
	FaProjectDiagram,
	FaUserShield,
	FaChalkboardTeacher,
	FaTasks,
	FaFile,
	FaChevronUp,
	FaChevronDown,
	FaClock,
} from "react-icons/fa";
import { IoLaptopOutline } from "react-icons/io5";
import { FcLeave } from "react-icons/fc";
import { FcRating } from "react-icons/fc";
import {
	MdTimeline,
	MdOutlineScreenshot,
	MdApps,
	MdSummarize,
	MdDynamicFeed,
} from "react-icons/md";
import {
	BsReception4,
	BsFillCalendarCheckFill,
	// IoTicketSharp,
	BsFillCalendar2WeekFill,
} from "react-icons/bs";
import {
	FaRegLightbulb,
	FaRegClock,
	FaCalendarAlt,
	FaSearch,
	FaBriefcase,
	FaWrench,
	FaTools,
	FaUserTie,
	FaClipboardList,
	FaIdBadge,
	FaSignOutAlt,
} from "react-icons/fa";
import { BASEURL } from "./BASEURL";

const Sidenav = () => {
	const location = useLocation();
	const [menu1, setmenu1] = useState(false);
	const [menuAttendance, setmenuAttendance] = useState(false);
	const [menuattendanceproject, setmenuattendanceproject] = useState(false);
	const [menuattendanceemployee, setmenuattendanceemploye] = useState(false);
	const [menuattendancejob, setmenuattendancejob] = useState(false);
	const [project, setProject] = useState(false);
	const [menu2, setmenu2] = useState(false);
	const [menu3, setmenu3] = useState(false);
	const [menu4, setmenu4] = useState(false);
	const [menu5, setmenu5] = useState(false);
	const params = useParams();
	// const navigate = useNavigate();
	let [tabtype, settabtype] = useState(
		JSON.parse(localStorage.getItem("isAuth")).master == 1 ? 3 : 0
	);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [master] = React.useState(
		localStorage.getItem("isAuth") &&
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [type] = React.useState(
		localStorage.getItem("isAuth") &&
		JSON.parse(localStorage.getItem("isAuth")).type
	);

	const isDashboardAccessible = type !== 2;

	// useEffect(() => {
	// 	if (isAuth.role === 1) {
	// 		settabtype(12);
	// 	}
	// 	if (isAuth.role === 2) {
	// 		Navigate("/");
	// 	}

	// 	if (params.type) {
	// 		settabtype(+params.type);
	// 	}
	// }, []);

	useEffect(() => {
		if (params.type) {
			settabtype(+params.type);
		}
	}, [params.type]);

	// console.log(isAuth.username);
	return (
		<>
			<nav className="side-nav">
				<Link to={"/"} className="intro-x flex items-center px-3">
					{isAuth &&
						isAuth.companydata &&
						isAuth.companydata[0]?.orglogo !== "" ? (
						<>
							<img
								style={{
									maxWidth: "180px",
									maxHeight: "100px",
								}}
								src={
									BASEURL +
									`uploads/orglogo/${isAuth.companydata[0]?.orglogo}`
								}
								alt="logo"
								className="image-fit"
								srcSet=""
							/>
							{isAuth.companydata[0]?.orglogo == "" && (
								<span
									className="hidden xl:block text-white  ml-3"
									style={{
										fontSize: "1rem",
										textAlign: "left",
									}}
								>
									{isAuth && isAuth.companydata[0]?.name}{" "}
									Tracking
								</span>
							)}
						</>
					) : (
						<>
							{/* <img
								alt="Admin"
								className="w-6"
								src="../images/logo.svg"
							/> */}
							<span
								className="hidden xl:block text-white  ml-3"
								style={{ fontSize: "1rem", textAlign: "left" }}
							>
								{isAuth &&
									isAuth.companydata &&
									isAuth.companydata[0]?.name}{" "}
								Tracking
							</span>
						</>
					)}
				</Link>
				<div className="side-nav__devider my-6"></div>
				{localStorage.getItem("isclient") !== "1" ? (
					<ul className="sidebar">
						<li>

							{isAuth.role !== 3 ? (
								<>
									<Link
										to={"/"}
										className={
											location.pathname === "/"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <FaHome /> */}
											{/* <Dashboard /> */}

											{/* <Dashboard
												className={
													location.pathname.startsWith("/")
														? "icon-active"
														: "icon-inactive"
												}
											/> */}
											<Dashboard
												className={
													location.pathname === "/"
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<div className="side-menu__title">
											{/* Home */}
											Dashboard
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}


							{/* <li>
								<Link
									to="/attendance/monthly-records"
									className={
										location.pathname ===
											"/attendance/monthly-records"
											? "side-menu side-menu--active"
											: "side-menu"
									}
								>
									<div className="side-menu__icon">
										<i data-lucide="activity">
											<BsFillCalendar2WeekFill />
										</i>
									</div>
									<div className="side-menu__title">
										Monthly Records
									</div>
								</Link>
							</li> */}

							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<Link
										to="/productivity"
										className={
											location.pathname === "/productivity"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<ProductivityIcon
												className={
													location.pathname === "/productivity"
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<div className="side-menu__title">
											Productivity
										</div>
									</Link>
								</>
							) : null}


							{/* {isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className="side-menu"

									>
										<div className="side-menu__icon">
											<AttendanceIcon />
										</div>
										<Link to='/attendance/monthly-records'>
										
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>

											
												Attendance

											</div>
										</Link>
									</div>

								</>
							) : null} */}


							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									{/* <Link to='/attendance/monthly-attendance-report'> */}
									{/* to={"/attendance/monthly-records"} */}
									<Link
										// to="/attendance/monthly-records"
										to="/attendance/monthly-attendance-report"
										className={
											// location.pathname === "/attendance/monthly-records"
											location.pathname === "/attendance/monthly-attendance-report"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <AttendanceIcon /> */}
											<AttendanceIcon
												className={
													// location.pathname.startsWith("/attendance/monthly-records")
													location.pathname.startsWith("/attendance/monthly-attendance-report")
														? "icon-active"
														: "icon-inactive"
												}
											/>

										</div>
										<div className="side-menu__title">
											Attendance
										</div>
									</Link>
								</>
							) : null}

							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											// location.pathname.startsWith("/manage-project")
											location.pathname.startsWith("/manage-project")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <ProjectIcon /> */}
											<ProjectIcon
												className={
													location.pathname.startsWith("/manage-project")
														? "icon-active"
														: "icon-inactive"
												}
											/>

										</div>
										<Link to={"/manage-project"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>

												Project
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("#")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <TaskIcon /> */}
											<TaskIcon
												className={
													location.pathname.startsWith("#")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"#"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Task
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("#")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<CommentsIcon />
										</div>
										<Link to={"#"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Chat
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/employeeList")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <EmployeIcon /> */}
											<EmployeIcon
												className={
													location.pathname.startsWith("/employeeList")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/employeeList"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Employee
											</div>
										</Link>
									</div>
								</>
							) : null}













							{/* 
							{isAuth.role === 2 || isAuth.role === 2 ? (
								<>
									<Link
										to={"/punch-ticket"}
										className={
											location.pathname ===
												"/punch-ticket"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<i data-lucide="activity">
												<BsFillCalendarCheckFill />
											</i>
										</div>
										<div className="side-menu__title">
											Punch Tickets
										</div>
									</Link></>

							) : null} */}

							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/punch-ticket")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <TicketIcon /> */}
											<TicketIcon
												className={
													location.pathname.startsWith("/punch-ticket")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/punch-ticket"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Tickets
											</div>
										</Link>
									</div>
								</>
							) : null}

							{master !== 1 && (
								<>
									{/* Tickets Menu */}
									<Link
										to="#"
										className={"side-menu"}
										onClick={() => setmenu4(!menu4)}
									>
										<div className="side-menu__icon">
											<FaClock
												className={
													menu4 ? "icon-active" : "icon-inactive"
												}
											/>
										</div>
										<div className="side-menu__title">
											Tickets
											<div className="side-menu__sub-icon transform rotate-180">
												{!menu4 ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</Link>

									<ul className={menu4 ? "side-menu__sub-open" : null}>
										{isAuth.role !== 2 ? (
											<li>
												<Link
													to={"/punch-ticket"}
													className={
														location.pathname === "/punch-ticket"
															? "side-menu side-menu--active"
															: "side-menu"
													}
												>
													<div className="side-menu__icon">
														<BsFillCalendarCheckFill
															className={
																location.pathname === "/punch-ticket"
																	? "icon-active"
																	: "icon-inactive"
															}
														/>
													</div>
													<div className="side-menu__title">
														Punch/Break Tickets
													</div>
												</Link>
											</li>
										) : (
											<>
												<li>
													<Link
														to={"/punch-ticket"}
														className={
															location.pathname === "/punch-ticket"
																? "side-menu side-menu--active"
																: "side-menu"
														}
													>
														<div className="side-menu__icon">
															<BsFillCalendarCheckFill
																className={
																	location.pathname === "/punch-ticket"
																		? "icon-active"
																		: "icon-inactive"
																}
															/>
														</div>
														<div className="side-menu__title">
															Punch Tickets
														</div>
													</Link>
												</li>

												<li>
													<Link
														to="/OtherBreakTicket"
														className={
															location.pathname === "/OtherBreakTicket"
																? "side-menu side-menu--active"
																: "side-menu"
														}
													>
														<div className="side-menu__icon">
															<BsFillCalendar2WeekFill
																className={
																	location.pathname ===
																		"/OtherBreakTicket"
																		? "icon-active"
																		: "icon-inactive"
																}
															/>
														</div>
														<div className="side-menu__title">
															OtherBreakTicket
														</div>
													</Link>
												</li>
											</>
										)}
									</ul>

									{/* Leaves Menu */}
									<Link
										to={"/leaves"}
										className={
											location.pathname === "/leaves"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<FcLeave
												className={
													location.pathname === "/leaves"
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<div className="side-menu__title">
											Leaves
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>

									{/* Attendance Menu */}
									<Link
										to="#"
										className={"side-menu"}
										onClick={() => setmenu2(!menu2)}
									>
										<div className="side-menu__icon">
											<FaClock
												className={
													menu2 ? "icon-active" : "icon-inactive"
												}
											/>
										</div>
										<div className="side-menu__title">
											Attendance
											<div className="side-menu__sub-icon transform rotate-180">
												{!menu2 ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>
									</Link>

									<ul className={menu2 ? "side-menu__sub-open" : null}>
										{isAuth.role !== 2 ? (
											<li>
												<Link
													to="/attendance/attendance-details"
													className={
														location.pathname ===
															"/attendance/attendance-details"
															? "side-menu side-menu--active"
															: "side-menu"
													}
												>
													<div className="side-menu__icon">
														<BsFillCalendarCheckFill
															className={
																location.pathname ===
																	"/attendance/attendance-details"
																	? "icon-active"
																	: "icon-inactive"
															}
														/>
													</div>
													<div className="side-menu__title">
														Attendance Details
													</div>
												</Link>
											</li>
										) : null}
										<li>
											<Link
												to="/attendance/monthly-records"
												className={
													location.pathname ===
														"/attendance/monthly-records"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<BsFillCalendar2WeekFill

													/>
												</div>
												<div className="side-menu__title">
													Monthly Records
												</div>
											</Link>
										</li>
									</ul>
								</>
							)}






							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/leaves")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <LeavesIcon /> */}
											<LeavesIcon
												className={
													location.pathname.startsWith("/leaves")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/leaves"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Leaves
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/dashboard/desktop-activity")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <EMPActivityIcon /> */}

											<EMPActivityIcon
												className={
													location.pathname.startsWith("/dashboard/desktop-activity")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/dashboard/desktop-activity"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Emp Activity
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/dashboard/summary")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <ReportIcon /> */}
											<ReportIcon
												className={
													location.pathname.startsWith("/dashboard/summary")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/dashboard/summary"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Report
											</div>
										</Link>
									</div>
								</>
							) : null}
							{/* ICON COLOR CHNAGE */}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/job-applications")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<JobapplicationIcon
												className={
													location.pathname.startsWith("/job-applications")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/job-applications"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Job Application
											</div>
										</Link>
									</div>
								</>
							) : null}


							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/clients")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>


										<div className="side-menu__icon">
											<ClientIcon
												className={
													location.pathname.startsWith("/clients")
														? "icon-active"
														: "icon-inactive"
												}
											/>
											{/* <ProductivityIcon
												className={
													location.pathname === "/productivity"
														? "icon-active"
														: "icon-inactive"
												}
											/> */}
										</div>
										<Link to={"/clients"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Clients
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/UserLoginDetails")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <ExerciseOptOutsIcon /> */}

											<ExerciseOptOutsIcon
												className={
													location.pathname.startsWith("/UserLoginDetails")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/UserLoginDetails"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Exercise Opt Outs
											</div>
										</Link>
									</div>
								</>
							) : null}
							{/* {isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className="side-menu"
									>
										<div className="side-menu__icon">
											<UserIcon />
										</div>
										<Link to={"/UserLoginDetails"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Admin
											</div>
										</Link>
									</div>
								</>
							) : null} */}


							{isAuth.role === 0 ? (
								<>
									{master !== 1 && (
										<>
											{/* <Link
												to={"/feeds"}
												className={
													location.pathname ===
													"/feeds"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<MdDynamicFeed />
												</div>
												<div className="side-menu__title">
													Feeds
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link> */}

											<Link
												to={"/employee-activity"}
												className={
													location.pathname ===
														"/employee-activity"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaTasks />
												</div>
												<div className="side-menu__title">
													Employee Activity
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>

											{/* <Link
												to={"/ReportAll"}
												className={
													location.pathname ===
													"/ReportAll"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaFile />
												</div>
												<div className="side-menu__title">
													Report All
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link> */}

											<Link
												to={"/report"}
												className={
													location.pathname ===
														"/report"
														? "side-menu side-menu--active"
														: "side-menu"
												}
											>
												<div className="side-menu__icon">
													<FaFile />
												</div>
												<div className="side-menu__title">
													Report
													<div className="side-menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>
										</>
									)}

									{/* <Link
										to={"/admin/20"}
										className={
											location.pathname === "/admin/20"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											
											<FaHandshake />
										</div>
										<div className="side-menu__title">
											Clients
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link> */}

									{/* <Link
										to={"/admin"}
										className={
											location.pathname === "/admin"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<FaUserCircle />
										</div>
										<div className="side-menu__title">
											Admin
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link> */}

									{type !== 2 ? (
										<>
											<div
												className="side-menu"
												onClick={() => setmenu5(!menu5)}
											>
												<div className="side-menu__icon">
													{/* <FaUserShield /> */}
													<UserIcon />
												</div>
												<div
													className="side-menu__title"
													style={{
														cursor: "pointer",
													}}
												>
													Admin
													{/* Settings */}
													<div className="side-menu__sub-icon">
														{menu5 ? (
															<FaChevronUp />
														) : (
															<FaChevronDown />
														)}
													</div>
												</div>
											</div>

											<ul
												className={
													menu5
														? "side-menu__sub-open"
														: null
												}
											>
												{type !== 2 ? (
													<>
														<li>
															<Link
																to={"/admin/0"}
																className={
																	tabtype ===
																		0
																		? "side-menu side-menu--active"
																		: "side-menu"
																}
															>
																<div className="side-menu__icon">
																	<i data-lucide="activity">
																		<FaProjectDiagram />
																	</i>
																</div>
																<div className="side-menu__title">
																	Organization
																	Details
																</div>
															</Link>
														</li>

														<li>
															<Link
																to={"/admin/1"}
																className={
																	tabtype ===
																		1
																		? "side-menu side-menu--active"
																		: "side-menu"
																}
															>
																<div className="side-menu__icon">
																	<i data-lucide="activity">
																		<FaUserShield />
																	</i>
																</div>
																<div className="side-menu__title">
																	Departments
																</div>
															</Link>
														</li>

														<li>
															<Link
																to={"/admin/4"}
																className={
																	tabtype ===
																		4
																		? "side-menu side-menu--active"
																		: "side-menu"
																}
															>
																<div className="side-menu__icon">
																	<i data-lucide="activity">
																		<FaUserShield />
																	</i>
																</div>
																<div className="side-menu__title">
																	Working
																	Shifts
																</div>
															</Link>
														</li>

														{/* <li>
															<Link
																to={"/admin/5"}
																className={
																	tabtype ===
																	5
																		? "side-menu side-menu--active"
																		: "side-menu"
																}
															>
																<div className="side-menu__icon">
																	<i data-lucide="activity">
																		<FaUserShield />
																	</i>
																</div>
																<div className="side-menu__title">
																	Desktop Apps
																</div>
															</Link>
														</li> */}
													</>
												) : null}

												{/* Other links */}

												<li>
													<Link
														to={"/admin/9"}
														className={
															tabtype === 9
																? "side-menu side-menu--active"
																: "side-menu"
														}
													>
														<div className="side-menu__icon">
															<i data-lucide="activity">
																<FaUserShield />
															</i>
														</div>
														<div className="side-menu__title">
															Holidays
														</div>
													</Link>
												</li>

												{/* <li>
													<Link
														to={"/admin/19"}
														className={
															tabtype === 19
																? "side-menu side-menu--active"
																: "side-menu"
														}
													>
														<div className="side-menu__icon">
															<i data-lucide="activity">
																<FaUserShield />
															</i>
														</div>
														<div className="side-menu__title">
															Assign Project to
															Client
														</div>
													</Link>
												</li> */}

												{/* <li>
													<Link
														to={"/admin/23"}
														className={
															tabtype === 23
																? "side-menu side-menu--active"
																: "side-menu"
														}
													>
														<div className="side-menu__icon">
															<i data-lucide="activity">
																<FaUserShield />
															</i>
														</div>
														<div className="side-menu__title">
															Evolution Points
														</div>
													</Link>
												</li> */}
											</ul>
										</>
									) : null}
								</>
							) : null}

							{isAuth.role === 0 || isAuth.role === 1 ? (
								<>
									<div
										className={
											location.pathname.startsWith("/insights/ScreenshotsAdmin")
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											{/* <ScreenshotsIcon /> */}
											<ScreenshotsIcon
												className={
													location.pathname.startsWith("/insights/ScreenshotsAdmin")
														? "icon-active"
														: "icon-inactive"
												}
											/>
										</div>
										<Link to={"/insights/ScreenshotsAdmin"}>
											<div
												className="side-menu__title"
												style={{
													cursor: "pointer",
												}}
											>
												Screenshots
											</div>
										</Link>
									</div>
								</>
							) : null}
							{isAuth.username == "1150" && (
								<Link
									to={"/admin"}
									className={
										location.pathname === "/admin"
											? "side-menu side-menu--active"
											: "side-menu"
									}
								>
									<div className="side-menu__icon">
										{/* <FaUserCircle /> */}
										<FaChalkboardTeacher />
									</div>
									<div className="side-menu__title">
										Admin
										<div className="side-menu__sub-icon transform rotate-180">
											<i data-lucide="chevron-down"></i>
										</div>
									</div>
								</Link>
							)}

							{isAuth.role === 1 ? (
								<>
									<Link
										to={"/admin"}
										className={
											location.pathname === "/admin"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										{/* <div className="side-menu__icon">
											<FaUserCircle />
											
										</div> */}
										<div className="side-menu__sub-icon transform rotate-180">
											<i data-lucide="chevron-down"></i>
										</div>
										<div className="side-menu__title">
											Admin Settings
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}
							{isAuth.role === 3 ? (
								<>
									<Link
										to={"/admin/16"}
										className={
											location.pathname === "EmployeeList"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon mr-3">
											<FaUserShield />
										</div>
										<div className="side-menu__title">
											SMS
										</div>
									</Link>
								</>
							) : null}
						</li>
					</ul>
				) : (
					<ul>
						{/* <li>
							{isAuth.role !== 3 ? (
								<>
									<Link
										to={"/"}
										className={
											location.pathname === "/"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<FaHome />
										</div>
										<div className="side-menu__title">
											Home
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}
						</li> */}
						<li>
							{isAuth.role !== 3 ? (
								<>
									<Link
										to={"/"}
										className={
											location.pathname === "/"
												? "side-menu side-menu--active"
												: "side-menu"
										}
									>
										<div className="side-menu__icon">
											<FaHome />
										</div>
										<div className="side-menu__title">
											Home
											<div className="side-menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}
						</li>

						{/* <li>    
            <Link
              to={"/"}
              className={
                location.pathname === "/"
                  ? "side-menu side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                <FaHome />
              </div>
              <div className="side-menu__title">
                Achievements
                <div className="side-menu__sub-icon transform rotate-180">
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
            </li>  */}

						<li>
							<Link
								to={"/monthlywork"}
								className={
									location.pathname === "/monthlywork"
										? "side-menu side-menu--active"
										: "side-menu"
								}
							>
								<div className="side-menu__icon">
									<FaHome />
								</div>
								<div className="side-menu__title">
									Monthly Work
									<div className="side-menu__sub-icon transform rotate-180">
										<i data-lucide="chevron-down"></i>
									</div>
								</div>
							</Link>
						</li>
					</ul>
				)}
			</nav>
		</>
	);
};

export default Sidenav;
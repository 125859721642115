import React, { useEffect, useState } from 'react'
import { BASEURL } from '../BASEURL';
import SummaryChart from './SummaryChart'

import { FaCalendar } from 'react-icons/fa';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from 'date-fns'
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import reload from '../../reload.png'



const Summary = () => {
  const loadingContext = useLoadingContext();
  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  let monthNumber = month[today.getMonth()];

  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [active, setactive] = useState(0);
  const [orgval, setorgval] = React.useState([]);

  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  const currmonth = monthNumber;
  const curryear = today.getFullYear();
  const [chart1, setchart1] = useState([]);
  const [chart2, setchart2] = useState([]);
  const [allpresent, setallpresent] = useState([]);
  const [allhrs, setallhrs] = useState([]);
  const [orgs, setorgs] = React.useState([]);
  const [master,] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("monthyear"))
    const month = value ? value.month : format(new Date(), 'MM')
    const year = value ? value.year : format(new Date(), 'yyyy')
    setorgval({ value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name });
    if (master === 1) {
      getOrganizations()
    }
    getData({ year, month });
    getchartdata({ year, month });

  }, [])

  const reloadFun = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");

    getData({ year, month });
    getchartdata({ year, month });
  }


  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.employee}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const columns = [
    { title: "Employee", field: "employee", width: 500, formatter: reactFormatter(<DetailVal />), },
    { title: "Working Time", field: "working", width: 350, },
    { title: "Idle Time", field: "idletime", width: 350 },
    { title: "Productive Time", field: "prod", width: 320 },
  ];

  const options = {
    pagination: 'local',
    paginationSize: 50
  };

  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    let value = JSON.parse(localStorage.getItem("monthyear"));
    if (Object.keys(monthYear).length !== 0) {
      value = monthYear;
    }

    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");

    getData({ year, month, org });
    getchartdata({ year, month, org });
  }

  const getData = async ({ year, month, org = "" }) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append('Authorization', token);
    }
    myHeaders.append('Content-Type', 'application/json');
    await fetch(BASEURL + `get-monthwise-productivity`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month: month, year,
        uuid: org !== '' ? org : selectedorg,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        department: JSON.parse(localStorage.getItem("isAuth")).department,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      })

    }).then(response => response.json()).then(res => {
      if (res.status === 201) {
        setattdata(res.data)
        // console.log(res.allarr[0].working);
        setallhrs(res.allarr[0].working)
      } else {
        setallpresent([]);
        setattdata([
          {
            employee: "No data Available",
          }
        ])
      }
    }).catch(err => console.log(err));
    setLoading(false);
  }

  const getchartdata = async ({ year, month, org = "" }) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append('Authorization', token);
    }
    myHeaders.append('Content-Type', 'application/json');
    await fetch(BASEURL + `get-chartdata`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month: month, year,
        uuid: org !== '' ? org : selectedorg,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        department: JSON.parse(localStorage.getItem("isAuth")).department,

        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      })

    }).then(response => response.json()).then(res => {
      if (res.status === 201) {
        let allpresent = res.attdata[0].value.reduce((a, b) => a + b, 0)
        // let allhrs = res.hrsdata[0].value.reduce((a,b)=>a+Number(b),0)
        setallpresent(allpresent);
        // allhrs = allhrs*60;


        // let hours = (allhrs / 60);
        // let rhours = Math.floor(hours);
        // if(rhours<10) rhours = '0'+rhours; 
        // let minutes = (hours - rhours) * 60;
        // let rminutes = Math.round(minutes);
        // if(rminutes<10) rminutes = '0'+rminutes; 
        // if(isNaN(rhours)!==true && isNaN(rminutes)!==true){
        //     setallhrs(rhours + ":" + rminutes + ":00");
        // }else{
        //     setallhrs("00:00:00");
        // }
        // console.log(res.attdata);
        setchart1(res.attdata)
        setchart2(res.hrsdata)
      } else {
        setchart1();
        setchart2();
        setallhrs([]);
      }
    }).catch(err => console.log(err));
    setLoading(false);
    loadingContext.done();
  }

  const handleOnDismiss = () => {
    setisVisible(false);
  };

  const handleOnChange = (year, month) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let monthname = monthNames[month - 1];
    setisVisible(false);
    localStorage.setItem("monthyear", JSON.stringify({ year, monthname, month: month }))
    setmonthYear({ year, month: monthname });
    getData({ year, month });
    getchartdata({ year, month });
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"))
    const month = value ? value.monthname : (monthYear && monthYear.month ? monthYear.month : 0)
    const year = value ? value.year : (monthYear && monthYear.year ? monthYear.year : 0)
    let date = new Date();
    let curryear = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return month && year ? `${month}-${year}` : monthNames[currmonth - 1] + "-" + curryear;
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">


          <div className="grid grid-cols-12 gap-6">

            <div className="col-span-12 mt-8 ">
              <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-5">
                <div className="flex mt-5 sm:mt-0">
                  {master === 1 &&
                    <Select
                      value={orgval}
                      className="basic-single mr-2"
                      onChange={(e) => {
                        getorg(e);
                      }}
                      options={orgs}
                      // getOptionLabel={(option) =>
                      //   ` ${option.active}   ${option.label}`
                      // }
                      styles={{
                        option: (base) => ({
                          ...base,
                          // height: "100%",
                          display: "flex",
                          textAlign: "left",
                        }),
                      }}
                    />
                  }
                </div>
                <div className="flex mt-5 sm:mt-0">
                  <div className="input-group mr-2 calenderdate">
                    <input type="text" style={{ fontSize: '12px' }} onFocus={(e) => showMonthPicker(e)} value={getMonthValue()} className="form-control calenderdate1" id="basic-url" aria-describedby="basic-addon3" onChange={() => { }} />
                    <div className="input-group-append calendaricon">
                      <FaCalendar style={{ fontSize: '12px' }} onClick={(e) => showMonthPicker(e)} />
                    </div>
                  </div>
                  <ReactMonthPicker
                    show={isVisible}
                    lang={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    value={{ year: curryear, month: currmonth }}
                    onChange={handleOnChange}
                    onDismiss={handleOnDismiss}
                  />
                  <button className="ml-1 btn btn-default">
                    <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
                  </button>

                </div>




              </div>

              <div className="flex flex-col md:flex-row md:items-center" style={{ zIndex: -1 }}>
                <div className="flex">

                  <div className={!active ? 'box p-5 ml-3 activeborder' : 'box activeborder1 p-5 ml-3'} onClick={() => setactive(0)}>
                    <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">Total Present Employee</div>
                    <div className="mt-0.5 ">  {loading ?
                      <div className=' flex justify-center'>
                        <HashLoader color='#5755d9' size={30} />
                      </div>
                      : `${allpresent && allpresent > 0 ? allpresent : 0} Count`}</div>
                  </div>



                  <div className={active ? 'box p-5 ml-3 activeborder' : 'box activeborder1 p-5 ml-3'} onClick={() => setactive(1)}>
                    <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">Total Working Hours For This Month</div>
                    <div className="mt-0.5 text-slate-500"> {loading ?
                      <div className='flex justify-center'>
                        <HashLoader color='#5755d9' size={30} />
                      </div>
                      : `${allhrs && allhrs.length > 0 ? allhrs : '00:00:00'} hh:mm:ss`}</div>
                  </div>
                </div>
              </div>


            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
                  <div className="intro-y box p-5">


                    {loading ?
                      <div className='text-center m-5 p-5'>
                        <HashLoader color='#5755d9' size={30} style={{ position: "absolute", right: "50%" }} />
                      </div>
                      :
                      <div className="overflow-x-auto scrollbar-hidden">



                        {active === 0 && chart1 && chart1.length > 0 ?
                          <SummaryChart attdata={chart1} type="Employee Present"></SummaryChart>
                          : null}
                        {active === 1 && chart2 && chart2.length > 0 ?
                          <SummaryChart attdata={chart2} type="Working Hours"></SummaryChart>
                          : null}
                        <br />
                        <ReactTabulator
                          columns={columns}
                          data={attdata}
                          options={options}
                          className="mt-5 table-report table-report--tabulator"
                        />
                      </div>
                    }
                  </div>
                </div>

              </div>
            </div>






          </div>
        </div>

      </div>
    </>
  )
}

export default Summary
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
// import Masteradmin from "../Masteradmin";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";

import {
	FaPhone,
	FaCalendar,
	FaEnvelope,
	FaMapMarker,
	FaAddressCard, FaExternalLinkAlt,
	FaCheckCircle
} from "react-icons/fa";
const Departments = () => {
	const [showTicket, setShowTicket] = useState([]);
	const [attdata, setattdata] = useState(null);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState([]);
	const [userdata, setuserdata] = useState([]);
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	useEffect(() => {
		fetchData();
		fetchTickets();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 20;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 20));
	}, [itemOffset, items]);

	// const handleApprove = (id) => {
	// 	alert(`Ticket ${id} approved.`);
	// 	// Add logic to update the status in the backend
	// };

	// const handleReject = (id) => {
	// 	alert(`Ticket ${id} rejected.`);
	// 	// Add logic to update the status in the backend
	// };

	const getUserData = async (e = "") => {
		let isactive = 1;
		if (e !== "") {
			isactive = e.target.value;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				master,
				isactive,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					// setitems(arr);
					// setCurrentItems(arr1);
					// setPageCount(Math.ceil(arr.length / 10));
					setuserdata(res.data);
					// setbkpdata(res.data);
				}
				// console.log("response from the user data" + setuserdata);
				console.log("response from the user data" + userdata);
				console.log("response from the user data" + userdata);
				console.log("response from the user data" + userdata);
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};
	useEffect(() => {
		getUserData();
	}, []);

	// handle Approve With swal Confirmation
	// const handleApprove = async (
	// 	id,
	// 	punchStartTime,
	// 	punchEndTime,
	// 	lunchStartTime,
	// 	lunchEndTime,
	// 	date,
	// 	selectedUsername,
	// 	companyUuid,
	// 	user_department,
	// 	user_type
	// ) => {
	// 	const willApprove = await swal({
	// 		title: "Are you sure?",
	// 		text: "Do you want to approve this ticket?",
	// 		icon: "warning",
	// 		buttons: {
	// 			cancel: {
	// 				text: "Cancel",
	// 				value: null,
	// 				visible: true,
	// 				className: "swal-button-cancel",
	// 			},
	// 			confirm: {
	// 				text: "Approve",
	// 				value: true,
	// 				visible: true,
	// 				className: "swal-button-approve",
	// 			},
	// 		},
	// 		className: "custom-swal",
	// 	});
	// 	if (!willApprove) {
	// 		return;
	// 	}
	// 	setLoading(true);
	// 	try {
	// 		const authData = JSON.parse(localStorage.getItem("isAuth"));
	// 		const userToken = authData?.usertoken;
	// 		if (!selectedUsername || !companyUuid || !date) {
	// 			swal(
	// 				"Error",
	// 				"Required fields are missing. Please check all details.",
	// 				"error"
	// 			);
	// 			setLoading(false);
	// 			return;
	// 		}
	// 		const formattedDate = format(new Date(date), "dd-MM-yyyy");
	// 		const response = await fetch(
	// 			`${BASEURL}admin-update-ticket/${id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: userToken,
	// 				},
	// 				body: JSON.stringify({
	// 					action: "accept",
	// 					punch_start_time: punchStartTime,
	// 					punch_end_time: punchEndTime,
	// 					lunch_start_time: lunchStartTime,
	// 					lunch_end_time: lunchEndTime,
	// 					username: selectedUsername,
	// 					company_uuid: companyUuid,
	// 					date: formattedDate,
	// 					department:user_department,
	// 					role:user_type,
	// 				}),
	// 			}
	// 		);
	// 		const result = await response.json();
	// 		if (response.status === 200) {
	// 			swal("Success", result.message, "success");
	// 			fetchTickets();
	// 		} else {
	// 			swal(
	// 				"Error",
	// 				result.error || "Failed to approve ticket",
	// 				"error"
	// 			);
	// 		}
	// 	} catch (error) {
	// 		console.error("Error:", error);
	// 		swal("Error", "Something went wrong! Please try again.", "error");
	// 	}
	// 	setLoading(false);
	// };
	const handleApprove = async (
		id,
		punchStartTime,
		punchEndTime,
		lunchStartTime,
		lunchEndTime,
		date,
		selectedUsername,
		companyUuid,
		user_department,
		user_type,
		breaktype // Add breaktype to the function parameters
	) => {
		const willApprove = await swal({
			title: "Are you sure?",
			text: "Do you want to approve this ticket?",
			icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "swal-button-cancel",
				},
				confirm: {
					text: "Approve",
					value: true,
					visible: true,
					className: "swal-button-approve",
				},
			},
			className: "custom-swal",
		});

		if (!willApprove) {
			return;
		}

		setLoading(true);

		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;

			if (!selectedUsername || !companyUuid || !date) {
				swal(
					"Error",
					"Required fields are missing. Please check all details.",
					"error"
				);
				setLoading(false);
				return;
			}

			const formattedDate = format(new Date(date), "dd-MM-yyyy");

			// Use a default value of 0 for breaktype if undefined
			const response = await fetch(
				`${BASEURL}admin-update-ticket/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: userToken,
					},
					body: JSON.stringify({
						action: "accept",
						punch_start_time: punchStartTime,
						punch_end_time: punchEndTime,
						lunch_start_time: lunchStartTime,
						lunch_end_time: lunchEndTime,
						username: selectedUsername,
						company_uuid: companyUuid,
						date: formattedDate,
						department: user_department || 0, // Default 0 if undefined
						role: user_type || 0, // Default 0 if undefined
						breaktype: breaktype || 0, // Default 0 if undefined
					}),
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				swal("Success", result.message, "success");
				fetchTickets();
			} else {
				swal(
					"Error",
					result.error || "Failed to approve ticket",
					"error"
				);
			}
		} catch (error) {
			console.error("Error:", error);
			swal("Error", "Something went wrong! Please try again.", "error");
		}

		setLoading(false);
	};

	// const handleApprove = async (
	// 	id,
	// 	punchStartTime,
	// 	punchEndTime,
	// 	lunchStartTime,
	// 	lunchEndTime,
	// 	date,
	// 	selectedUsername,
	// 	companyUuid
	// ) => {
	// 	setLoading(true);
	// 	try {
	// 		const authData = JSON.parse(localStorage.getItem("isAuth"));
	// 		const userToken = authData?.usertoken;

	// 		if (!selectedUsername || !companyUuid || !date) {
	// 			alert(
	// 				"Required fields are missing. Please check username, company UUID, and date."
	// 			);
	// 			setLoading(false);
	// 			return;
	// 		}

	// 		// Ensure the date is in the correct format
	// 		const formattedDate = format(new Date(date), "dd-MM-yyyy");

	// 		const response = await fetch(
	// 			`${BASEURL}admin-update-ticket/${id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: userToken,
	// 				},
	// 				body: JSON.stringify({
	// 					action: "accept",
	// 					punch_start_time: punchStartTime,
	// 					punch_end_time: punchEndTime,
	// 					lunch_start_time: lunchStartTime,
	// 					lunch_end_time: lunchEndTime,
	// 					username: selectedUsername, // Corrected to pass the actual username
	// 					company_uuid: companyUuid, // Corrected to pass the UUID
	// 					date: formattedDate, // Pass the properly formatted date
	// 				}),
	// 			}
	// 		);

	// 		const result = await response.json();
	// 		if (response.status === 200) {
	// 			alert(result.message);
	// 			fetchTickets();
	// 		} else {
	// 			alert(result.error || "Failed to approve ticket");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error approving ticket:", error);
	// 		alert("An error occurred while processing the request");
	// 	}
	// 	setLoading(false);
	// };

	// const handleReject = async (id) => {
	// 	setLoading(true);
	// 	try {
	// 		const response = await fetch(
	// 			`${BASEURL}admin-update-ticket/${id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: JSON.parse(
	// 						localStorage.getItem("isAuth")
	// 					).usertoken,
	// 				},
	// 				body: JSON.stringify({
	// 					action: "reject",
	// 				}),
	// 			}
	// 		);
	// 		const result = await response.json();
	// 		if (response.status === 200) {
	// 			alert(result.message);
	// 			fetchTickets(); // Refresh the ticket list
	// 		} else {
	// 			alert(result.error || "Failed to reject ticket");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error rejecting ticket:", error);
	// 		alert("An error occurred while processing the request");
	// 	}
	// 	setLoading(false);
	// };

	const handleReject = async (id) => {
		// Show confirmation prompt using SweetAlert
		const willReject = await swal({
			title: "Are you sure?",
			text: "Do you want to reject this ticket?",
			icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "swal-button-cancel",
				},
				confirm: {
					text: "Reject",
					value: true,
					visible: true,
					className: "swal-button-reject",
				},
			},
			className: "custom-swal",
			dangerMode: true,
		});
		if (!willReject) {
			return;
		}
		setLoading(true);
		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;

			const response = await fetch(
				`${BASEURL}admin-update-ticket/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: userToken,
					},
					body: JSON.stringify({ action: "reject" }),
				}
			);

			const result = await response.json();
			if (response.status === 200) {
				swal("Rejected", result.message, "success");
				fetchTickets();
			} else {
				swal(
					"Error",
					result.error || "Failed to reject ticket",
					"error"
				);
			}
		} catch (error) {
			console.error("Error rejecting ticket:", error);
			swal(
				"Error",
				"An error occurred while processing the request",
				"error"
			);
		}
		setLoading(false);
	};

	const getStatusText = (status) => {
		switch (status) {
			case 0:
				return "Pending";
			case 1:
				return "Resolved";
			case 2:
				return "Rejected";
			default:
				return "Unknown";
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 0:
				return "badge bg-warning";
			case 1:
				return "badge bg-success";
			case 2:
				return "badge bg-danger";
			default:
				return "badge bg-secondary";
		}
	};

	const fetchData = async () => {
		setLoading(true);
		// Fetch data for "All Tickets"
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		// await fetch(BASEURL + `show-emp-ticket`, {
		await fetch(BASEURL + `show-emp-ticket`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 0,
				uuid: userdata.companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					const keys = Object.keys(res.data);
					setItems(keys);
					setCurrentItems(keys.slice(0, 20));
					setPageCount(Math.ceil(keys.length / 20));
					setattdata(res.data);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const fetchTickets = async () => {
		setLoading(true);
		// Fetch data for "Employee Tickets"
		await fetch(BASEURL + `show-emp-ticket`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: JSON.parse(localStorage.getItem("isAuth")).username,
				role: JSON.parse(localStorage.getItem("isAuth")).role,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				setShowTicket(res.data);
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 20) % items.length;
		setItemOffset(newOffset);
	};

	return (
		<div className="intro-y box lg:mt-5">
			<div className="p-5">
				<div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
					<h2 className="font-medium text-base mb-4">
						Employee Ticket
					</h2>
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						// <HashLoader color="#5755d9" size={30} />
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<table
							className="table table-bordered"
							style={{ width: "100%" }}
						>
							<thead>
								<tr>
									<th>Action</th>
									<th>Name</th>
									{/* <th>ID</th>
									<th>Username</th>
									<th>Created At</th> */}
									<th>Date</th>
									<th>Punch Start Time</th>
									<th>Punch End Time</th>
									<th>Lunch Start Time</th>
									<th>Lunch End Time</th>
									<th>Status</th>
									<th>Reason</th>
								</tr>
							</thead>
							<tbody>
								{showTicket.length === 0 ? (
									<tr>
										<td colSpan="11">No data available</td>
									</tr>
								) : (
									showTicket.map((ticket) => (
										<tr key={ticket.id}>
											{/* <td className="flex justify-between items-center ">
												{ticket.status === 0 && (
													<>
														<div className="mr-4">
															
															<button
																className="btn btn-primary text-white btn-sm"
																onClick={() =>
																	handleApprove(
																		ticket.id,
																		ticket.punch_start_time,
																		ticket.punch_end_time,
																		ticket.lunch_start_time,
																		ticket.lunch_end_time,
																		ticket.date,
																		ticket.username,
																		JSON.parse(
																			localStorage.getItem(
																				"isAuth"
																			)
																		)
																			.companydata[0]
																			?.uuid,
																		ticket.user_department,
																		ticket.user_type
																	)
																}
															>
																Approve
															</button>
														</div>
														<div>
															

															<button
																className="btn btn-danger text-white btn-sm"
																onClick={() =>
																	handleReject(
																		ticket.id
																	)
																}
															>
																Reject
															</button>
														</div>
													</>
												)}
											</td> */}

											<td className="flex justify-between items-center">
												{ticket.status === 0 ? (
													<>
														<div className="mr-4">
															<button
																className="btn btn-primary text-white btn-sm"
																onClick={() =>
																	handleApprove(
																		ticket.id,
																		ticket.punch_start_time,
																		ticket.punch_end_time,
																		ticket.lunch_start_time,
																		ticket.lunch_end_time,
																		ticket.date,
																		ticket.username,
																		JSON.parse(localStorage.getItem("isAuth")).companydata[0]?.uuid,
																		ticket.user_department,
																		ticket.user_type
																	)
																}
															>
																Approve
															</button>
														</div>
														<div>
															<button
																className="btn btn-danger text-white btn-sm"
																onClick={() => handleReject(ticket.id)}
															>
																Reject
															</button>
														</div>
													</>
												) : (
													<div className="flex items-center justify-center text-green-500">
														<FaCheckCircle className="text-lg mr-2" />
														<span>Action Done</span>
													</div>
												)}
											</td>


											<td>{ticket.user_name}</td>
											<td>
												{new Date(
													ticket.date
												).toLocaleDateString()}
											</td>
											<td>{ticket.punch_start_time}</td>
											<td>{ticket.punch_end_time}</td>
											<td>{ticket.lunch_start_time}</td>
											<td>{ticket.lunch_end_time}</td>
											<td>
												<span
													className={`px-2 py-1 rounded text-white ${getStatusColor(
														ticket.status
													)}`}
												>
													{getStatusText(
														ticket.status
													)}
												</span>
											</td>
											<td>{ticket.reason}</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	);
};

export default Departments;

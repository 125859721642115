import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar, FaTimes } from "react-icons/fa";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import Modal from "react-modal";
import { GrNext, GrPrevious } from "react-icons/gr";
// import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import ProductivityChart from "./ProductivityChart";
import AttendanceChart from "./AttendanceChart";
import reload from "../reload.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		padding: "0%",
		borderRadius: "5px",
	},
};

const Home = (props) => {
	//  console.log(theme);
	// const loadingContext = useLoadingContext();
	let today = new Date();
	let month = [];
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";

	let monthNumber = month[today.getMonth()];

	let [loading, setLoading] = useState(false);
	let [dateloading, setdateLoading] = useState(false);
	const [date, setdate] = useState(new Date());
	const [isVisible, setisVisible] = useState(false);
	const [holidayisVisible, setholidayisVisible] = useState(false);
	const [prodisVisible, setprodisVisible] = useState(false);
	const [monthYear, setmonthYear] = useState({});

	let currmonth = monthNumber;
	let curryear = today.getFullYear();
	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: currmonth,
	});

	const holidaycurrmonth = monthNumber;
	const holidaycurryear = today.getFullYear();

	const prodcurrmonth = monthNumber;
	const prodcurryear = today.getFullYear();

	const [bdaydata, setbdaydata] = useState([]);
	const [holidaydata, setholidaydata] = useState([]);
	const [attdata, setattdata] = useState([]);
	const [proddata, setproddata] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [absentmodalIsOpen, setabsentIsOpen] = React.useState(false);
	const [onleavemodalIsOpen, setleaveIsOpen] = React.useState(false);
	const [unknownAbsentModalIsOpen, setUnknownAbsentModalIsOpen] =
		React.useState(false);

	const [lateArrivedModalIsOpen, setLateArrivedIsOpen] =
		React.useState(false);

	const [orgs, setorgs] = React.useState([]);
	const [master] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [orgval, setorgval] = React.useState([]);
	// console.log(props.isdark);
	// useEffect(() => {
	//   // let mode=props.isdarmode()
	//   console.log(props.toggle);
	//   // console.log(mode);
	//   // ahi update nai avti value ni
	// }, [props.toggle]);
	useEffect(() => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");
		setselectedmonth({ year: year, month: month });

		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		if (master === 1) {
			getOrganizations();
		}
		getBirthdayData({ year: year, month: month });
		getHolidayData({ year: year, month: month });
		getAttendanceData(format(new Date(), "yyyy-MM-dd"));
		getProdData({ year: year, month: month });
	}, []);

	const reloadFun = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");

		getOrganizations();
		getBirthdayData({ year: year, month: month });
		getHolidayData({ year: year, month: month });
		getAttendanceData(format(new Date(), "yyyy-MM-dd"));
		getProdData({ year: year, month: month });
	};

	function openModal() {
		setIsOpen(true);
	}
	function absentopenModal() {
		setabsentIsOpen(true);
		setIsOpen(false);
	}
	function leaveopenModal() {
		setleaveIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
		setabsentIsOpen(false);
		setleaveIsOpen(false);
	}

	function openLateArrivedModal() {
		setLateArrivedIsOpen(true);
	}

	function closeLateArrivedModal() {
		setLateArrivedIsOpen(false);
	}

	const getSummaryProductivity = () => {
		return <ProductivityChart proddata={proddata} />;
	};

	const getSummaryAttendance = () => {
		return <AttendanceChart attdata={attdata} />;
	};

	function openUnknownAbsentModal() {
		setUnknownAbsentModalIsOpen(true);
	}

	function closeUnknownAbsentModal() {
		setUnknownAbsentModalIsOpen(false);
	}

	const handledate = (e) => {
		let date;
		if (e !== null) {
			setdate(e);
			date = format(new Date(e), "yyyy-MM-dd");
		} else {
			date = format(new Date(), "yyyy-MM-dd");
		}
		getAttendanceData(date);
	};

	const nexTDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() + 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getAttendanceData(date2);
	};

	const prevDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() - 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getAttendanceData(date2);
	};

	const getMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const getHolidayMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const getProdMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const handleOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setisVisible(false);
		setprodisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);
		setmonthYear({ year, month: monthname });
		setselectedmonth({ year, month: Number(month) });
		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const handleHolidayOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setholidayisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);

		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const handleProdOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setisVisible(false);
		setprodisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);

		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const showMonthPicker = (e) => {
		setisVisible(!isVisible);
		e.preventDefault();
	};
	const hideMonthPicker = () => {
		setisVisible(false);
	};
	const showHolidayMonthPicker = (e) => {
		setholidayisVisible(true);
		e.preventDefault();
	};

	const showProdMonthPicker = (e) => {
		setprodisVisible(true);
		e.preventDefault();
	};
	const handleOnDismiss = () => {
		setisVisible(false);
		setholidayisVisible(false);
		setprodisVisible(false);
	};

	const getBirthdayData = async ({ year, month, org = "" }) => {
		setLoading(true);

		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		// console.log(selectedorg);
		await fetch(BASEURL + `get-birthdaydata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					function compare(a, b) {
						if (
							new Date(a.dob).toString().slice(8, 10) <
							new Date(b.dob).toString().slice(8, 10)
						) {
							return -1;
						}
						if (
							new Date(a.dob).toString().slice(8, 10) >
							new Date(b.dob).toString().slice(8, 10)
						) {
							return 1;
						}
						return 0;
					}

					setbdaydata(res.data.sort(compare));
				} else {
					setbdaydata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getHolidayData = async ({ year, month, org = "" }) => {
		setLoading(true);

		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-holidaydata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				// uuid: selectedorg
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setholidaydata(res.data);
				} else {
					setholidaydata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getOrganizations = async () => {
		setLoading(true);

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-organizations/`, {
			method: "POST",
			headers: myHeaders,
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setorgs(res.data);
				} else {
					setorgs([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getorg = async (org) => {
		// console.log(org);
		setorgval(org);
		org = org.value;
		setselectedorg(org);
		let value = JSON.parse(localStorage.getItem("monthyear"));
		if (Object.keys(monthYear).length !== 0) {
			value = monthYear;
		}

		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");

		getBirthdayData({ year: year, month: month, org });

		getHolidayData({ year: year, month: month, org });

		getAttendanceData(format(new Date(), "yyyy-MM-dd"), org);

		getProdData({ year: year, month: month, org });
		// console.log(org);
	};

	const getAttendanceData = async (date, org = "") => {
		setdateLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-attendancedata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setattdata(res.arr[0]);
				} else {
					setattdata([]);
				}
			})
			.catch((err) => console.log(err));

		setdateLoading(false);
	};

	const getProdData = async ({ year, month, org = "" }) => {
		setLoading(true);
		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-monthwise-productivity/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
				// uuid: selectedorg
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setproddata(res.allarr[0]);
				} else {
					setproddata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
		// loadingContext.done();
	};
	// console.log(orgs);
	return (
		<>
			<div className="grid grid-cols-12 gap-1">
				<div className="col-span-12 2xl:col-span-12 text-left">
					<div className="grid grid-cols-12 gap-0">
						<div className="col-span-12 lg:col-span-12 mt-0">
							<div className="intro-y block ">
								<div className="sm:ml-auto sm:mt-0">
									<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-1 mt-1">
										<div className="flex sm:mt-0">
											{master === 1 && (
												<>


													<Select
														value={orgval}
														className="basic-single mr-2"
														onChange={(e) => {
															getorg(e);
														}}
														options={orgs}
														styles={{
															menu: (base) => ({
																...base,
																backgroundColor:
																	"#ffffff", // Solid white background
																zIndex: 1, // High z-index to ensure it appears above other elements
																border: "1px solid #ddd", // Light border for clear boundaries
																boxShadow:
																	"0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for better visibility
															}),
															option: (
																base,
																state
															) => ({
																...base,
																backgroundColor:
																	state.isFocused
																		? "#f0f0f0"
																		: "#ffffff",
																color: "#000000",
															}),
															control: (
																base
															) => ({
																...base,
																backgroundColor:
																	"#ffffff", // White background for the select control
																border: "1px solid #ddd", // Add a border to the dropdown control
															}),
														}}
													/>

													<div className="flex mt-2 sm:mt-0">
														<div className="input-group mr-2 calenderdate">
															<input
																type="text"
																style={{
																	fontSize:
																		"12px",
																}}
																onFocus={(e) =>
																	showMonthPicker(
																		e
																	)
																}
																value={getMonthValue()}
																onDismiss={() =>
																	hideMonthPicker(
																		false
																	)
																}
																className="form-control calenderdate1 "
																id="basic-url"
																aria-describedby="basic-addon3"
															/>
															<div className="input-group-append calendaricon">
																<FaCalendar
																	style={{
																		fontSize:
																			"12px",
																	}}
																	onClick={(
																		e
																	) =>
																		showMonthPicker(
																			e
																		)
																	}
																/>
															</div>
														</div>
														<ReactMonthPicker
															show={isVisible}
															lang={[
																"Jan",
																"Feb",
																"Mar",
																"Apr",
																"May",
																"Jun",
																"Jul",
																"Aug",
																"Sep",
																"Oct",
																"Nov",
																"Dec",
															]}
															value={
																selectedmonth
															}
															onChange={
																handleOnChange
															}
															onDismiss={
																handleOnDismiss
															}
														/>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-12 lg:col-span-4 mt-1 tblborder">
							<div className="intro-y block sm:flex items-center h-10 relative">
								<h2 className="text-md font-medium truncate pt-3 mr-5">Birthdays</h2>
								{master === 0 && (
									<div className="flex items-center sm:ml-auto sm:mt-0">
										<div className="flex sm:mt-0">
											<div className="input-group mr-2 calenderdate">
												<input
													type="text"
													style={{ fontSize: "12px" }}
													onFocus={(e) => showMonthPicker(e)}
													value={getMonthValue()}
													className="form-control calenderdate1"
													id="basic-url"
													aria-describedby="basic-addon3"
													onChange={() => { }}
												/>
												<div className="input-group-append calendaricon">
													<FaCalendar
														style={{
															fontSize: "12px",
														}}
														onClick={(e) => showMonthPicker(e)}
													/>
												</div>
											</div>
											<ReactMonthPicker
												show={isVisible}
												lang={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
												value={selectedmonth}
												onChange={handleOnChange}
												onDismiss={handleOnDismiss}
												style={{
													position: "absolute",
													zIndex: 9999,
													top: "40px",
													left: "0",
												}}
											/>
											<button className="btn btn-default">
												<img width={15} onClick={reloadFun} src={reload} alt="" />
											</button>
										</div>
									</div>
								)}
							</div>

							{loading ? (
								<div className="flex justify-center">
									<HashLoader color="#5755d9" size={30} />
								</div>
							) : (
								<div className="intro-y mt-5 sm:mt-2">
									<table
										className="table-auto border-spacing-2 table-report sm:mt-2"
										style={{ minWidth: "100%" }}
									>
										<thead>
											<tr className="box">
												<th className="whitespace-nowrap p-3">Employee Name</th>
												<th className="text-center whitespace-nowrap p-3">Date</th>
											</tr>
										</thead>
										<tbody>
											{bdaydata && bdaydata.length > 0 ? (
												bdaydata.map((item, i) => (
													<tr className="intro-x" key={i}>
														<td className="p-3">
															<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5 text-left">
																{item.name} 🎂
															</div>
														</td>
														<td className="text-center p-">
															{format(new Date(item.dob), "dd MMM, yyyy")}
														</td>
													</tr>
												))
											) : (
												<tr className="intro-x">
													<td colSpan={2}>
														<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
															No data Found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							)}
							<div className="intro-y block sm:flex items-center h-10 relative">
								<h2 className="text-md font-medium truncate pt-3 mr-5">Holidays</h2>
							</div>

							{loading ? (
								<div className="flex justify-center">
									<HashLoader color="#5755d9" size={30} />
								</div>
							) : (
								<div className="intro-y mt-5 sm:mt-2">
									<table
										className="table-auto border-spacing-2 table-report sm:mt-2"
										style={{ minWidth: "100%" }}
									>
										<thead>
											<tr className="box">
												<th className="whitespace-nowrap p-3">Holiday Name</th>
												<th className="text-center whitespace-nowrap p-3">Date</th>
											</tr>
										</thead>
										<tbody>
											{holidaydata && holidaydata.length > 0 ? (
												holidaydata.map((item, i) => (
													<tr className="intro-x" key={i}>
														<td className="p-3">
															<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5 text-left">
																{item.name}
															</div>
														</td>
														<td className="text-center p-3">
															{format(new Date(item.date), "dd MMM, yyyy")}
														</td>
													</tr>
												))
											) : (
												<tr className="intro-x">
													<td colSpan={2}>
														<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
															No data Found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							)}
						</div>



						<div className="col-span-12 lg:col-span-4 mt-1 tblborder">
							<div className="intro-y block sm:flex items-center h-7">
								<h2 className="text-md font-medium truncate mr-5">
									Productivity
								</h2>
								<div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
									<div className="flex mt-2 sm:mt-0">
										{master === 0 && (
											<>
												{/* <div className="lg:ml-2  ">
                      <Select
                        defaultValue={orgs[0]}
                        className="basic-single"
                        onChange={(e) => {
                          getorg(e.value);
                        }}
                        options={orgs}
                        // getOptionLabel={(option) =>
                        //   ` ${option.active}   ${option.label}`
                        // }
                        styles={{
                          option: (base) => ({
                            ...base,
                            height: "100%",
                            display: "flex",
                            textAlign: "left",
                          }),
                        }}
                      /> */}
												{/* </div> */}

												<div className="input-group ml-2 calenderdate">
													<input
														type="text"
														style={{
															fontSize: "12px",
														}}
														onFocus={(e) =>
															showProdMonthPicker(
																e
															)
														}
														value={getProdMonthValue()}
														className="form-control calenderdate1"
														id="basic-url"
														aria-describedby="basic-addon3"
														onChange={() => { }}
													/>

													<div className="input-group-append calendaricon">
														<FaCalendar
															style={{
																fontSize:
																	"12px",
															}}
															onClick={(e) =>
																showProdMonthPicker(
																	e
																)
															}
														/>
													</div>
												</div>

												<ReactMonthPicker
													show={prodisVisible}
													lang={[
														"Jan",
														"Feb",
														"Mar",
														"Apr",
														"May",
														"Jun",
														"Jul",
														"Aug",
														"Sep",
														"Oct",
														"Nov",
														"Dec",
													]}
													// value={{ year: prodcurryear, month: prodcurrmonth }}
													value={selectedmonth}
													onChange={handleOnChange}
													onDismiss={handleOnDismiss}
												/>
											</>
										)}
										{master === 0 && (
											<button className="ml-2 btn btn-default">
												<img
													width={15}
													onClick={reloadFun}
													src={reload}
													alt=""
												/>
											</button>
										)}
									</div>
								</div>
							</div>

							<div className="intro-y box py-2 px-2 mt-12 sm:mt-5">
								<div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium text-left">
									Total Productivity
								</div>
								<div id="progressbar-height" className="p-1">
									<div className="intro-y grid grid-cols-12 gap-5 mt-2">
										<div className="col-span-11 lg:col-span-8 2xl:col-span-8">
											<div className="preview">
												<div className="progress h-2">
													<div
														className="progress-bar"
														style={{
															width:
																proddata &&
																proddata.prodper +
																"%",
														}}
														role="progressbar"
														aria-valuenow="0"
														aria-valuemin="0"
														aria-valuemax="100"
													>
														{" "}
													</div>
												</div>
											</div>
										</div>
										<div className="col-span-2 lg:col-span-2 2xl:col-span-2">
											{loading ? (
												<div className="flex justify-center">
													<HashLoader
														color="#5755d9"
														size={30}
													/>
												</div>
											) : proddata && proddata.prodper ? (
												proddata.prodper
											) : (
												"0"
											)}
											%
										</div>
									</div>
								</div>{" "}
								{/* <br />
                <hr />
                <br />
                <div
                  className="flex flex-col md:flex-row md:items-center"
                  style={{ justifyContent: "center" }}
                >
                  <div className="md:flex">
                    <div className=" p-1">
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Working Time
                      </div>
                      <div className="mt-0.5">
                        <span style={{ color: "green" }}>
                          {proddata && proddata.working
                            ? proddata.working
                            : "00:00:00"}
                        </span>{" "}
                        <br /> <span>hh:mm:ss</span>
                      </div>
                    </div>
                    <div className="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-2  xxl:mx-5"></div>
                    <div className=" p-1">
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Productive Time
                      </div>
                      <div className="mt-0.5">
                        <span style={{ color: "green" }}>
                          {proddata && proddata.prodtime
                            ? proddata.prodtime
                            : "00:00:00"}
                        </span>{" "}
                        <br />{" "}
                        <span style={{ fontSize: "11px" }}>hh:mm:ss</span>
                      </div>
                    </div>
                    <div className="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-2  xxl:mx-5"></div>
                    <div className=" p-1">
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Idle Time
                      </div>
                      <div className="mt-0.5">
                        <span style={{ color: "red" }}>
                          {proddata && proddata.idletime
                            ? proddata.idletime
                            : "00:00:00"}
                        </span>{" "}
                        <br />
                        <span style={{ fontSize: "11px" }}>hh:mm:ss</span>
                      </div>
                    </div>
                  </div> */}
								{/* </div> */}
							</div>
							<div className="col-span-12 grid grid-cols-12 gap-2 mt-2">
								<div
									className="col-span-12 sm:col-span-4 2xl:col-span-4 intro-y"
									style={{ zIndex: 0 }}
								>
									<div className="box px-5 py-3 flex-1 zoom-in">
										<div className="flex items-center">
											<div className="font-medium">
												Working
											</div>
										</div>
										<div className="text-slate-500 mt-1">
											{loading || !proddata?.working ? (
												<div className="flex justify-center">
													<HashLoader
														color="#5755d9"
														size={30}
													/>
												</div>
											) : (
												<>
													<span
														style={{
															color: "green",
														}}
													>
														{proddata.working ||
															"00:00:00"}
													</span>
													<br />
													<span>hh:mm:ss</span>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="col-span-12 sm:col-span-4 2xl:col-span-4 intro-y">
									<div className="box px-5 py-3 flex-1 zoom-in">
										<div className="flex items-center">
											<div className="font-medium">
												Productive
											</div>
										</div>
										<div className="text-slate-500 mt-1">
											{loading || !proddata?.prodtime ? (
												<div className="flex justify-center">
													<HashLoader
														color="#5755d9"
														size={30}
													/>
												</div>
											) : (
												<>
													<span
														style={{
															color: "green",
														}}
													>
														{proddata &&
															proddata.prodtime
															? proddata.prodtime
															: "00:00:00"}
													</span>{" "}
													{/* <span style={{ fontSize: "11px" }}> */}
													<span>
														<br /> hh:mm:ss
													</span>
												</>
											)}
										</div>
									</div>
								</div>

								<div className="col-span-12 sm:col-span-4 2xl:col-span-4 intro-y">
									<div className="box px-5 py-3 flex-1 zoom-in">
										<div className="flex items-center">
											<div className="font-medium">
												Idle
											</div>
										</div>
										<div className="text-slate-500 mt-1">
											{loading || !proddata?.idletime ? (
												<div className="flex justify-center">
													<HashLoader
														color="#5755d9"
														size={30}
													/>
												</div>
											) : (
												<>
													<span
														style={{ color: "red" }}
													>
														{proddata &&
															proddata.idletime
															? proddata.idletime
															: "00:00:00"}
													</span>{" "}
													<br />
													<span>hh:mm:ss</span>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-12  lg:col-span-4 mt-1 tblborder">
							<div className="intro-y block sm:flex items-center h-7">
								<h2 className="text-md font-medium truncate mr-5">
									Attendance
								</h2>
								<div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
									<button className="mx-2" onClick={prevDay}>
										<GrPrevious />
									</button>{" "}
									<button className="mx-2" onClick={nexTDay}>
										<GrNext />
									</button>{" "}
									<DatePicker
										clearIcon=""
										calendarIcon={
											<FaCalendar
												style={{
													color: "rgb(30 64 175)",
												}}
											/>
										}
										onChange={handledate}
										value={date}
									/>
									<button className="ml-2 btn btn-default">
										<img
											width={15}
											onClick={reloadFun}
											src={reload}
											alt=""
										/>
									</button>
								</div>
							</div>
							{dateloading ? (
								<div className="flex justify-center mt-3">
									<HashLoader color="#5755d9" size={30} />
								</div>
							) : (
								<div className="col-span-12 grid grid-cols-12 gap-2 mt-5">
									<div
										className="col-span-12  sm:col-span-6 2xl:col-span-6 intro-y"
										style={{ zIndex: 0 }}
										onClick={openModal}
									>
										<div className="box px-5 py-3 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													Present
												</div>
												<div className="text-xs text-slate-500 ml-auto">
													<div
														style={{
															width: 30,
															height: 30,
														}}
													>
														<CircularProgressbar
															value={
																attdata &&
																attdata.presentper
															}
															text={`${attdata &&
																attdata.presentper
																}%`}
															styles={{
																text: {
																	fontSize:
																		"30px",
																	fill: "green",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{" "}
												{attdata &&
													attdata.present}{" "}
												Count
											</div>
										</div>
									</div>
									<div
										className="col-span-12 sm:col-span-6 2xl:col-span-6 intro-y"
										onClick={absentopenModal}
									>
										<div className="box px-5 py-3 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													Absent
												</div>
												<div className="text-xs text-slate-500 ml-auto">
													<div
														style={{
															width: 30,
															height: 30,
														}}
													>
														<CircularProgressbar
															value={
																attdata &&
																attdata.absentper
															}
															text={`${attdata &&
																attdata.absentper
																}%`}
															styles={{
																text: {
																	fontSize:
																		"30px",
																	fill: "red",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{" "}
												{attdata && attdata.absent}{" "}
												Count
											</div>
										</div>
									</div>

									<div
										className="col-span-12 sm:col-span-6 2xl:col-span-6 intro-y"
										onClick={leaveopenModal}
									>
										<div className="box px-5 py-3 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													On Leave
												</div>
												<div className="text-xs text-slate-500 ml-auto">
													<div
														style={{
															width: 30,
															height: 30,
														}}
													>
														<CircularProgressbar
															value={
																attdata &&
																attdata.onleaveper
															}
															text={`${attdata &&
																attdata.onleaveper
																}%`}
															styles={{
																text: {
																	fontSize:
																		"30px",
																	fill: "red",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{" "}
												{attdata &&
													attdata.onleaveCount}{" "}
												Count
											</div>
										</div>
									</div>

									<div className="col-span-12 sm:col-span-6 2xl:col-span-6 intro-y">
										<div className="box px-5 py-4 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													Total Employee
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{" "}
												{attdata &&
													attdata.totalmembers}{" "}
												Count
											</div>
										</div>
									</div>

									<div
										className="col-span-12 sm:col-span-6 2xl:col-span-6 intro-y"
										onClick={openUnknownAbsentModal} // Open modal on click
									>
										<div className="box px-5 py-4 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													Unknown/Absent
												</div>
												<div className="text-xs text-slate-500 ml-auto">
													<div
														style={{
															width: 30,
															height: 30,
														}}
													>
														<CircularProgressbar
															value={
																attdata &&
																	attdata.unknownAbsentUsers
																	? (attdata
																		.unknownAbsentUsers
																		.length /
																		attdata.totalmembers) *
																	100
																	: 0
															}
															text={`${attdata &&
																attdata.unknownAbsentUsers
																? Math.round(
																	(attdata
																		.unknownAbsentUsers
																		.length /
																		attdata.totalmembers) *
																	100
																)
																: 0
																}%`}
															styles={{
																text: {
																	fontSize:
																		"30px",
																	fill: "red",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{attdata &&
													attdata.unknownAbsentUsers
													? `${attdata.unknownAbsentUsers.length} Count`
													: "0 Count"}
											</div>
										</div>
									</div>
									<Modal
										isOpen={unknownAbsentModalIsOpen}
										onRequestClose={closeUnknownAbsentModal}
										style={customStyles} // Add custom styles if needed
									>
										<div
											className="row header sticky z-50"
											style={{
												padding: "9px",
												background: "#1e40af",
												color: "white",
											}}
										>
											<div className="flex items-center">
												<h2 className="font-medium text-base mr-auto">
													Unknown/Absent Employees
												</h2>
												<button
													className="btn btn-outline-secondary hidden sm:flex text-white"
													onClick={
														closeUnknownAbsentModal
													}
												>
													<FaTimes />
												</button>
											</div>
										</div>
										<div className="grid grid-cols-4">
											{attdata.unknownAbsentUsers &&
												attdata.unknownAbsentUsers.length >
												0 ? (
												attdata.unknownAbsentUsers.map(
													(item, i) => (
														<div
															className="box p-3"
															style={{
																border: "1px solid rgb(230 231 231)",
																borderRadius: 0,
															}}
															key={i}
														>
															<div className="m-0 p-0">
																<h6
																	style={{
																		fontSize:
																			"14px",
																	}}
																>
																	{item}
																</h6>
															</div>
														</div>
													)
												)
											) : (
												<div className="box p-3 text-slate-500 text-xs whitespace-nowrap mt-0.5">
													No data found
												</div>
											)}
										</div>
									</Modal>

									<div
										className="col-span-12 sm:col-span-6 2xl:col-span-6 intro-y"
										onClick={openLateArrivedModal}
									>
										<div className="box px-5 py-4 flex-1 zoom-in">
											<div className="flex items-center">
												<div className="font-medium">
													Late Arrived
												</div>
												<div className="text-xs text-slate-500 ml-auto">
													<div
														style={{
															width: 30,
															height: 30,
														}}
													>
														<CircularProgressbar
															value={
																attdata &&
																	attdata.latearrived
																	? (attdata.latearrived /
																		attdata.totalmembers) *
																	100
																	: 0
															}
															text={`${attdata &&
																attdata.latearrived
																? Math.round(
																	(attdata.latearrived /
																		attdata.totalmembers) *
																	100
																)
																: 0
																}%`}
															styles={{
																text: {
																	fontSize:
																		"30px",
																	fill: "orange",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="text-slate-500 mt-1">
												{attdata && attdata.latearrived
													? attdata.latearrived
													: 0}{" "}
												Count
											</div>
										</div>
									</div>
									<Modal
										isOpen={lateArrivedModalIsOpen}
										onRequestClose={closeLateArrivedModal}
										style={customStyles}
									>
										<div
											className="row header sticky z-50"
											style={{
												padding: "9px",
												background: "#1e40af",
												color: "white",
											}}
										>
											<div className="flex items-center">
												<h2 className="font-medium text-base mr-auto">
													Late Arrived Employees
												</h2>
												<button
													className="btn btn-outline-secondary hidden sm:flex text-white"
													onClick={
														closeLateArrivedModal
													}
												>
													<FaTimes />
												</button>
											</div>
										</div>
										<div className="grid grid-cols-4">
											{attdata.latearrivedUsers &&
												attdata.latearrivedUsers.length >
												0 ? (
												attdata.latearrivedUsers.map(
													(item, i) => (
														<div
															className="box p-3 "
															style={{
																border: "1px solid rgb(230 231 231)",
																borderRadius: 0,
															}}
															key={i}
														>
															<div className="m-0 p-0">
																<h6
																	style={{
																		fontSize:
																			"14px",
																	}}
																>
																	{item}
																</h6>
															</div>
														</div>
													)
												)
											) : (
												<div className="box p-3 text-slate-500 text-xs whitespace-nowrap mt-0.5">
													No data Found
												</div>
											)}
										</div>
									</Modal>
								</div>
							)}
							{/* <div className="intro-y box pt-2 mt-5 sm:mt-3 tblborder">
								<div
									className="flex flex-col md:flex-row md:items-center"
									style={{ justifyContent: "center" }}
								>
									{dateloading ? (
										<div className="flex justify-center">
											<HashLoader
												color="#5755d9"
												size={30}
											/>
										</div>
									) : (
										<div className="md:flex  ">
											<div className="">
												<div
													onClick={openModal}
													className="text-primary dark:text-slate-200 text-md xl:text-md  md:font-medium cursor-pointer"
												>
													<u>Present</u>
												</div>
												<div className="mt-0.5 sm:font-medium">
													<span
														style={{
															color: "green",
														}}
													>
														{attdata &&
															attdata.present}
													</span>{" "}
													out of{" "}
													{attdata &&
														attdata.totalmembers}
												</div>
											</div>
											<div className="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-2  xxl:mx-5"></div>
											<div className="">
												<div
													onClick={absentopenModal}
													className="text-primary dark:text-slate-200 text-md xl:text-md md:font-medium cursor-pointer"
												>
													<u>Absent</u>
												</div>
												<div className="mt-0.5 text-slate-500">
													<span
														style={{ color: "red" }}
													>
														{attdata &&
															attdata.absent}
													</span>{" "}
													out of{" "}
													{attdata &&
														attdata.totalmembers}
												</div>
											</div>
											<div className="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-2  xxl:mx-5"></div>
											<div className="">
												<div
													onClick={leaveopenModal}
													className="text-primary dark:text-slate-200 text-md xl:text-md md:font-medium cursor-pointer"
												>
													<u>On Leave</u>
												</div>
												<div className="mt-0.5 text-slate-500">
													<span
														style={{ color: "red" }}
													>
														{attdata &&
															attdata.onleave}
													</span>{" "}
													out of{" "}
													{attdata &&
														attdata.totalmembers}
												</div>
											</div>
											<div className="w-px h-12 border border-r border-dashed border-slate-200 dark:border-darkmode-300 mx-2 xxl:mx-5"></div>
											<div className="">
												<div className="text-primary dark:text-slate-200 text-md xl:text-md md:font-medium">
													Total Members
												</div>
												<div className="mt-0.5 text-slate-500">
													{attdata &&
														attdata.totalmembers}{" "}
													Count
												</div>
											</div>
										</div>
									)}
								</div>
							</div> */}
						</div>

						<div
							className="col-span-12 lg:col-span-6 mt-1 tblborder"
							style={{ zIndex: 0 }}
						>
							<div
								className="intro-y block sm:flex items-center h-5"
								style={{ zIndex: 0 }}
							>
								<h2 className="text-lg font-medium truncate mr-5">
									Monthly Productivity Statistics
								</h2>
							</div>
							<div className="intro-y sm:mt-5 pb-8">
								{getSummaryProductivity()}
							</div>
						</div>
						<div className="col-span-12 lg:col-span-6 mt-1 tblborder">
							<div className="intro-y block sm:flex items-center h-5">
								<h2 className="text-lg font-medium truncate mr-5">
									Daily Attendance Statistics
								</h2>
							</div>
							<div className="intro-y sm:mt-5 pb-8">
								{getSummaryAttendance()}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="row">
      <div className="col-span-12 xl:col-span-6">
        <div className="col-span-12 lg:col-span-12 mt-5 ">
          <div className="intro-y block sm:flex items-center h-5">
            <h2 className="text-lg font-medium truncate mr-5">
              Monthly Productivity Statistics
            </h2>
          </div>
          <div className="intro-y sm:mt-5 pb-8">
            {getSummaryProductivity()}
          </div>
        </div>
      </div>
      </div>
    <div className="row">
      <div className="col-span-12 xl:col-span-6">
        <div className="col-span-12 lg:col-span-12 mt-2 ">
          <div className="intro-y block sm:flex items-center h-5">
            <h2 className="text-lg font-medium truncate mr-5">
             Daily Attendance Statistics
            </h2>
          </div>
          <div className="intro-y sm:mt-5 pb-8">
            {getSummaryAttendance()}
          </div>
        </div>
      </div>
      </div> */}

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
			>
				<div
					className="row header sticky z-50"
					style={{
						padding: "9px",
						background: "#1e40af",
						color: "white",
					}}
				>
					<div className="flex items-center">
						<h2 className="font-medium text-base mr-auto">
							Punched In Employees
						</h2>
						<button
							className="btn btn-outline-secondary hidden sm:flex text-white"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>
				</div>
				<div className="grid grid-cols-4">
					{attdata.presentusers && attdata.presentusers.length > 0 ? (
						attdata.presentusers.map((item, i) => {
							return (
								<>
									<div
										className="box p-3 "
										style={{
											border: "1px solid rgb(230 231 231)",
											borderRadius: 0,
										}}
									>
										<div className="m-0 p-0">
											<h6
												style={{ fontSize: "14px" }}
												key={i}
											>
												{item}
											</h6>
										</div>
									</div>
								</>
							);
						})
					) : (
						<div className="box p-3 text-slate-500 text-xs whitespace-nowrap mt-0.5">
							No data Found
						</div>
					)}
				</div>
			</Modal>

			<Modal
				isOpen={absentmodalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
			>
				<div
					className="row header sticky z-50"
					style={{
						padding: "9px",
						background: "#1e40af",
						color: "white",
					}}
				>
					<div className="flex items-center">
						<h2 className="font-medium text-base mr-auto">
							Absent Employees
						</h2>
						<button
							className="btn btn-outline-secondary hidden sm:flex text-white"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>
				</div>
				<div className="grid grid-cols-4">
					{attdata.absentusers && attdata.absentusers.length > 0 ? (
						attdata.absentusers.map((item, i) => {
							return (
								<>
									<div
										className="box p-3 "
										style={{
											border: "1px solid rgb(230 231 231)",
											borderRadius: 0,
										}}
									>
										<div className="m-0 p-0">
											<h6
												style={{ fontSize: "14px" }}
												key={i}
											>
												{item}
											</h6>
										</div>
									</div>
								</>
							);
						})
					) : (
						<div className="box p-3 text-slate-500 text-xs whitespace-nowrap mt-0.5">
							No data Found
						</div>
					)}
				</div>
			</Modal>

			<Modal
				isOpen={onleavemodalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
			>
				<div
					className="row header sticky z-50"
					style={{
						padding: "9px",
						background: "#1e40af",
						color: "white",
					}}
				>
					<div className="flex items-center">
						<h2 className="font-medium text-base mr-auto">
							Onleave Employees
						</h2>
						<button
							className="btn btn-outline-secondary hidden sm:flex text-white"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>
				</div>
				<div className="grid grid-cols-4">
					{attdata.onleaveusers && attdata.onleaveusers.length > 0 ? (
						attdata.onleaveusers.map((item, i) => {
							return (
								<>
									<div
										className="box p-3 "
										style={{
											border: "1px solid rgb(230 231 231)",
											borderRadius: 0,
										}}
									>
										<div className="m-0 p-0">
											<h6
												style={{ fontSize: "14px" }}
												key={i}
											>
												{item}
											</h6>
										</div>
									</div>
								</>
							);
						})
					) : (
						<div className="box p-3 text-slate-500 text-xs whitespace-nowrap mt-0.5">
							No data Found
						</div>
					)}
				</div>
			</Modal>
		</>
	);
};

export default Home;

// const mapStateToProps = ({ auth }) => {
//   const { currentTheme } =  auth;
//   return { currentTheme }
// };
// const mapDispatchToProps = {
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Home)

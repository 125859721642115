// import React, { useEffect, useState } from "react";
// import { BASEURL } from "./BASEURL";
// import { useParams } from "react-router-dom";
// import {
// 	FaPhone,
// 	FaCalendar,
// 	FaEnvelope,
// 	FaMapMarker,
// 	FaAddressCard,FaExternalLinkAlt 
// } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { format } from "date-fns";
// import CallDetails from "../componunt/admin/CallDetails";
// import SMS from "../componunt/admin/Sms";
// import AssessmentData from "../componunt/admin/JobAssessment";
// import { FaUserCircle } from "react-icons/fa";
// import Testresult from "./admin/Testresult";

// const Employees_details = () => {
// 	const [jobdata, setjobdata] = useState([]);
// 	const [position, setposition] = useState(null);
// 	let [isAuth] = useState(JSON.parse(localStorage.getItem("isAuth")));
// 	const params = useParams();
// 	let [isTab, setisTab] = useState(0);
// 	useEffect(() => {
// 		getData();
// 	}, []);

// 	const getData = async () => {
// 		const myHeaders = new Headers();
// 		let jobdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (jobdata != null) {
// 			let token = jobdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		await fetch(BASEURL + `get-jobdata`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: params.id,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					setjobdata(res.data);
// 					setposition(res.position);
// 				}
// 			})
// 			.catch((err) => console.log(err));
// 	};

// 	return (
// 		<>
// 			<div className="intro-y flex items-center">
// 				<h2 className="text-lg font-medium mr-auto">
// 					Application Details
// 				</h2>
// 			</div>
// 			<div className="intro-y chat grid grid-cols-12 gap-5">
// 				<div className="col-span-12 lg:col-span-12 2xl:col-span-12">
// 					<div className="intro-y box px-5 pt-2 mt-2">
// 						<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
// 							<div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
// 								<div className="">
// 									{jobdata &&
// 									jobdata.length > 0 &&
// 									jobdata[0].profilepic === "" ? (
// 										<FaUserCircle
// 											className="text-5xl"
// 											style={{ color: "#1d45ab" }}
// 										/>
// 									) : (
// 										<div>
// 											<img
// 												src={
// 													jobdata &&
// 													jobdata[0] &&
// 													jobdata[0].profilepic
// 												}
// 												style={{ borderRadius: "52px" }}
// 												alt=""
// 												srcset=""
// 											/>
// 										</div>
// 									)}
// 								</div>
// 								<div className="ml-5 text-left">
// 									<div className="w-30 sm:w-60 truncate sm:whitespace-normal font-medium text-lg d-flex">
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].name}
// 									</div>
// 									<div className="text-slate-500">
// 										{position && position[0].name}
// 									</div>
// 								</div>
// 							</div>
// 							<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
// 								<div className="font-medium text-center lg:text-left lg:mt-3">
// 									Contact Details
// 								</div>
// 								<div className="flex flex-col justify-center items-center lg:items-start mt-2">
// 									<div className="truncate sm:whitespace-normal flex items-center">
// 										<i
// 											data-lucide="mail"
// 											className="w-4 h-4 mr-2"
// 										>
// 											<FaPhone />
// 										</i>
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].contactno}
// 									</div>
// 									<div className="truncate sm:whitespace-normal flex items-center">
// 										<i
// 											data-lucide="mail"
// 											className="w-4 h-4 mr-2"
// 										>
// 											<FaEnvelope />
// 										</i>
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].email}
// 									</div>
// 								</div>
// 								<div className="font-medium text-center lg:text-left lg:mt-3">
// 									Application Date time
// 								</div>
// 								<div className="flex flex-col justify-center items-center lg:items-start mt-2">
// 									<div className="truncate sm:whitespace-normal flex items-center">
// 										<i
// 											data-lucide="mail"
// 											className="w-4 h-4 mr-2"
// 										>
// 											<FaCalendar />
// 										</i>
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											format(
// 												new Date(
// 													jobdata[0].applicationdate
// 												),
// 												"yyyy-MM-dd HH:mm:ss"
// 											)}
// 									</div>
// 								</div>
// 							</div>
// 							<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
// 								<div className="font-medium text-center lg:text-left lg:mt-3">
// 									Address
// 								</div>

// 								<div className="flex flex-col justify-center items-center lg:items-start mt-2">
// 									{jobdata &&
// 									jobdata.length > 0 &&
// 									jobdata[0].address == "Other" ? (
// 										""
// 									) : (
// 										<div className="truncate sm:whitespace-normal flex items-center">
// 											<i
// 												data-lucide="mail"
// 												className="w-4 h-4 mr-2"
// 											>
// 												<FaMapMarker />
// 											</i>
// 											{jobdata &&
// 												jobdata.length > 0 &&
// 												jobdata[0].address}
// 										</div>
// 									)}
// 									<div className="truncate sm:whitespace-normal flex items-center">
// 										<i
// 											data-lucide="mail"
// 											className="w-4 h-4 mr-2"
// 										>
// 											<FaAddressCard />
// 										</i>
// 										{jobdata &&
// 										jobdata.length > 0 &&
// 										jobdata[0].city === 0
// 											? "Ahmedabad"
// 											: jobdata &&
// 											  jobdata.length > 0 &&
// 											  jobdata[0].city === 1
// 											? "Gandhinagar"
// 											: ""}
// 										,{" "}
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].state}
// 										,{" "}
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].zipcode}
// 									</div>
// 								</div>
// 								<div className="font-medium text-center lg:text-left lg:mt-3">
// 									Notice Period
// 								</div>
// 								<div className="flex flex-col justify-center items-center lg:items-start mt-2">
// 									<div className="truncate sm:whitespace-normal flex items-center">
// 										<i
// 											data-lucide="mail"
// 											className="w-4 h-4 mr-2"
// 										>
// 											<FaCalendar />
// 										</i>
// 										{jobdata &&
// 											jobdata.length > 0 &&
// 											jobdata[0].noticeperiod}
// 									</div>
// 								</div>
// 							</div>
						
// 							<div className="mt-6 lg:mt-0 flex-1 px-5 border border-slate-200/60 dark:border-darkmode-400 pt-5 lg:pt-0 rounded-md bg-white dark:bg-darkmode-600">
// 								<div className="font-medium text-center lg:text-left lg:mt-3">
// 									Salary Information
// 								</div>
// 								<div className="mt-4 space-y-3">
// 									{/* Current Salary */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className=" text-gray-500 dark:text-gray-400">
// 											Current Salary:
// 										</span>
// 										<span className="ml-3 font-medium text-slate-700 dark:text-white">
// 											{jobdata?.[0]?.currentsalary ||
// 												"N/A"}
// 										</span>
// 									</div>

// 									{/* Expected Salary */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className="text-gray-500 dark:text-gray-400">
// 											Expected Salary:
// 										</span>
// 										<span className="ml-3 font-medium text-slate-700 dark:text-white">
// 											{jobdata?.[0]?.expectedsalary ||
// 												"N/A"}
// 										</span>
// 									</div>

// 									{/* Willing to Relocate */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className=" text-gray-500 dark:text-gray-400">
// 											Willing to relocate?:
// 										</span>
// 										<span className="ml-3 font-medium text-slate-700 dark:text-white">
// 											{jobdata?.[0]?.isrelocate === 1
// 												? "Yes"
// 												: "No"}
// 										</span>
// 									</div>

// 									{/* Training */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className=" text-gray-500 dark:text-gray-400">
// 											Is Taken Training?:
// 										</span>
// 										<span className="ml-3 font-medium text-slate-700 dark:text-white">
// 											{jobdata?.[0]?.istraining === 1
// 												? "Yes"
// 												: "No"}
// 										</span>
// 									</div>

// 									{/* Work Experience */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className="text-gray-500 dark:text-gray-400">
// 											Work Experience
// 										</span>
// 										<span className=" font-medium text-slate-700 dark:text-white">
// 											{jobdata?.[0]
// 												?.totalworkexperience || "N/A"}
// 										</span>
// 									</div>
// 									<div>
// 										{/* Other Salary Information */}

// 										{/* Portfolio */}
// 										{jobdata?.[0]?.portfolio && (
// 											<div className="flex items-center justify-center lg:justify-start">
// 												<span className="text-gray-500 dark:text-gray-400">
// 													Portfolio Link:
// 												</span>
// 												<a
// 													href={
// 														jobdata?.[0]?.portfolio
// 													}
// 													target="_blank"
// 													rel="noopener noreferrer"
// 													className="ml-3 text-blue-600 hover:underline font-medium"
// 												>
// 													<FaExternalLinkAlt className="mr-2" /> 
// 												</a>
// 											</div>
// 										)}
// 									</div>

// 									{/* Form Submission Date */}
// 									<div className="flex items-center justify-center lg:justify-start">
// 										<span className="text-gray-500 dark:text-gray-400">
// 											Form Submitted at:
// 										</span>
// 										<span className="ml-3 font-medium">
// 											{jobdata?.[0]?.applicationdate ? (
// 												<span className="text-blue-600">
// 													{format(
// 														new Date(
// 															jobdata[0].applicationdate
// 														),
// 														"dd-MM-yyyy"
// 													)}
// 												</span>
// 											) : (
// 												<span className="text-red-600">
// 													N/A
// 												</span>
// 											)}
// 										</span>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 						<ul
// 							className="nav nav-link-tabs  flex-wrap flex-col sm:flex-row justify-center sm:justify-start text-center"
// 							role="tablist"
// 							style={{ cursor: "pointer" }}
// 						>
// 							<li
// 								id="dashboard-tab"
// 								className="nav-item"
// 								role="presentation"
// 							>
// 								<div
// 									className={
// 										isTab === 0
// 											? "nav-link py-1 active"
// 											: "nav-link py-1"
// 									}
// 									onClick={() => setisTab(0)}
// 									data-tw-target="#dashboard"
// 									aria-controls="dashboard"
// 									aria-selected="true"
// 									role="tab"
// 								>
// 									Call/SMS History
// 								</div>
// 							</li>
// 							<li
// 								id="dashboard-tab"
// 								className="nav-item"
// 								role="presentation"
// 							>
// 								<div
// 									className={
// 										isTab === 2
// 											? "nav-link py-1 active"
// 											: "nav-link py-1"
// 									}
// 									onClick={() => setisTab(2)}
// 									data-tw-target="#dashboard"
// 									aria-controls="dashboard"
// 									aria-selected="true"
// 									role="tab"
// 								>
// 									Assessment Details
// 								</div>
// 							</li>

// 							<li
// 								id="dashboard-tab"
// 								className="nav-item"
// 								role="presentation"
// 							>
// 								<div
// 									className={
// 										isTab === 2
// 											? "nav-link py-1 active"
// 											: "nav-link py-1"
// 									}
// 									onClick={() => setisTab(3)}
// 									data-tw-target="#dashboard"
// 									aria-controls="dashboard"
// 									aria-selected="true"
// 									role="tab"
// 								>
// 									Assessment Test Results
// 								</div>
// 							</li>
// 						</ul>
// 					</div>
// 				</div>
// 			</div>
// 			<div className="intro-y tab-content mt-5">
// 				<div
// 					id="dashboard"
// 					className="tab-pane active"
// 					role="tabpanel"
// 					aria-labelledby="dashboard-tab"
// 				>
// 					{isTab === 0 ? (
// 						<CallDetails id={params.id} />
// 					) : isTab === 1 ? (
// 						<>
// 							<SMS id={params.id} />{" "}
// 						</>
// 					) : isTab === 2 ? (
// 						<>
// 							<AssessmentData id={params.id} />{" "}
// 						</>
// 					) : isTab === 3 ? (
// 						<>
// 							<Testresult id={params.id} />{" "}
// 						</>
// 					) : null}
// 				</div>
// 			</div>
// 			{isAuth.role === 0 && (
// 				<div className="text-left mt-3">
// 					<Link
// 						to="/admin/12"
// 						className="btn btn-primary btn-sm text-white"
// 					>
// 						Back to All Applications
// 					</Link>
// 				</div>
// 			)}
// 		</>
// 	);
// };

// export default Employees_details;

import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { useParams } from "react-router-dom";
import {
	FaPhone,
	FaCalendar,
	FaEnvelope,
	FaMapMarker,
	FaAddressCard,FaExternalLinkAlt 
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import CallDetails from "../componunt/admin/CallDetails";
import SMS from "../componunt/admin/Sms";
import AssessmentData from "../componunt/admin/JobAssessment";
import { FaUserCircle } from "react-icons/fa";
import Testresult from "./admin/Testresult";

const Employees_details = () => {
	const [jobdata, setjobdata] = useState([]);
	const [position, setposition] = useState(null);
	let [isAuth] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const params = useParams();
	let [isTab, setisTab] = useState(0);
	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const myHeaders = new Headers();
		let jobdata = JSON.parse(localStorage.getItem("isAuth"));
		if (jobdata != null) {
			let token = jobdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		await fetch(BASEURL + `get-jobdata`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: params.id,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setjobdata(res.data);
					setposition(res.position);
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<div className="intro-y flex items-center">
  <h2 className="text-lg font-medium mr-auto">
    Application Details
  </h2>
</div>
<div className="intro-y chat grid grid-cols-12 gap-5">
  <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
    <div className="intro-y box px-5 pt-2 mt-2">
      <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
        <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
          <div>
            {jobdata && jobdata.length > 0 && jobdata[0].profilepic === "" ? (
              <FaUserCircle className="text-5xl" style={{ color: "#1d45ab" }} />
            ) : (
              <img
                src={jobdata[0]?.profilepic}
                alt="Profile"
                style={{ borderRadius: "50%" }}
                className="w-16 h-16 object-cover"
              />
            )}
          </div>
          <div className="ml-5 text-left">
            <div className="text-lg font-medium truncate">
              {jobdata?.[0]?.name || "N/A"}
            </div>
            <div className="text-slate-500">
              {position && position[0]?.name || "Position not available"}
            </div>
          </div>
        </div>

        {/* Contact Details Section */}
        <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
          <div className="font-medium text-center lg:text-left">Contact Details</div>
          <div className="flex flex-col justify-center items-center lg:items-start mt-2">
            <div className="flex items-center">
              <FaPhone className="w-4 h-4 mr-2" />
              {jobdata?.[0]?.contactno || "N/A"}
            </div>
            <div className="flex items-center">
              <FaEnvelope className="w-4 h-4 mr-2" />
              {jobdata?.[0]?.email || "N/A"}
            </div>
          </div>

          {/* Application Date */}
          <div className="font-medium text-center lg:text-left mt-3">Application Date & Time</div>
          <div className="flex flex-col justify-center items-center lg:items-start mt-2">
            <div className="flex items-center">
              <FaCalendar className="w-4 h-4 mr-2" />
              {jobdata?.[0]?.applicationdate
                ? format(new Date(jobdata[0].applicationdate), "yyyy-MM-dd HH:mm:ss")
                : "N/A"}
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
          <div className="font-medium text-center lg:text-left mt-3">Address</div>
          <div className="flex flex-col justify-center items-center lg:items-start mt-2">
            {jobdata?.[0]?.address !== "Other" && (
              <div className="flex items-center">
                <FaMapMarker className="w-4 h-4 mr-2" />
                {jobdata?.[0]?.address || "N/A"}
              </div>
            )}
            <div className="flex items-center">
              <FaAddressCard className="w-4 h-4 mr-2" />
              {jobdata?.[0]?.city && jobdata?.[0]?.state
                ? `${jobdata[0]?.city}, ${jobdata[0]?.state}, ${jobdata[0]?.zipcode}`
                : "N/A"}
            </div>
          </div>
        </div>

        {/* Salary Information */}
        <div className="mt-6 lg:mt-0 flex-1 px-5  pt-5 lg:pt-0  bg-white dark:bg-darkmode-600">
          <div className="font-medium text-center lg:text-left lg:mt-3">Salary Information</div>
          <div className="mt-4 space-y-3">
            {/* Current Salary */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Current Salary:</span>
              <span className="ml-3 font-medium text-slate-700 dark:text-white">
                {jobdata?.[0]?.currentsalary || "N/A"}
              </span>
            </div>

            {/* Expected Salary */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Expected Salary:</span>
              <span className="ml-3 font-medium text-slate-700 dark:text-white">
                {jobdata?.[0]?.expectedsalary || "N/A"}
              </span>
            </div>

            {/* Willing to Relocate */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Willing to relocate?:</span>
              <span className="ml-3 font-medium text-slate-700 dark:text-white">
                {jobdata?.[0]?.isrelocate === 1 ? "Yes" : "No"}
              </span>
            </div>

            {/* Training */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Is Taken Training?:</span>
              <span className="ml-3 font-medium text-slate-700 dark:text-white">
                {jobdata?.[0]?.istraining === 1 ? "Yes" : "No"}
              </span>
            </div>

            {/* Work Experience */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Work Experience:</span>
              <span className="font-medium text-slate-700 dark:text-white">
                {jobdata?.[0]?.totalworkexperience || "N/A"}
              </span>
            </div>

            {/* Portfolio */}
            {jobdata?.[0]?.portfolio && (
              <div className="flex items-center justify-center lg:justify-start">
                <span className="text-gray-500 dark:text-gray-400">Portfolio Link:</span>
                <a
                  href={jobdata?.[0]?.portfolio}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-3 text-blue-600 hover:underline font-medium"
                >
                  <FaExternalLinkAlt className="mr-2" />
                </a>
              </div>
            )}

            {/* Form Submission Date */}
            <div className="flex items-center justify-center lg:justify-start">
              <span className="text-gray-500 dark:text-gray-400">Form Submitted at:</span>
              <span className="ml-3 font-medium">
                {jobdata?.[0]?.applicationdate ? (
                  <span className="text-blue-600">
                    {format(new Date(jobdata[0].applicationdate), "dd-MM-yyyy")}
                  </span>
                ) : (
                  <span className="text-red-600">N/A</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs for additional data */}
      <ul className="nav nav-link-tabs flex-wrap flex-col sm:flex-row justify-center sm:justify-start text-center">
  <li className="nav-item">
    <div
      className={isTab === 0 ? "nav-link py-1 active cursor-pointer" : "nav-link py-1 cursor-pointer"}
      onClick={() => setisTab(0)}
    >
      Call/SMS History
    </div>
  </li>
  <li className="nav-item">
    <div
      className={isTab === 2 ? "nav-link py-1 active cursor-pointer" : "nav-link py-1 cursor-pointer"}
      onClick={() => setisTab(2)}
    >
      Assessment Details
    </div>
  </li>
  <li className="nav-item">
    <div
      className={isTab === 3 ? "nav-link py-1 active cursor-pointer" : "nav-link py-1 cursor-pointer"}
      onClick={() => setisTab(3)}
    >
      Assessment Test Results
    </div>
  </li>
</ul>


      {/* Tab Content */}
      <div className="intro-y tab-content mt-5">
        <div className="tab-pane active" role="tabpanel">
          {isTab === 0 && <CallDetails id={params.id} />}
          {isTab === 1 && <SMS id={params.id} />}
          {isTab === 2 && <AssessmentData id={params.id} />}
          {isTab === 3 && <Testresult id={params.id} />}
        </div>
      </div>

      {/* Back to Applications Button */}
      {isAuth?.role === 0 && (
        <div className="text-left mt-3">
          <Link to="/admin/12" className="btn btn-primary btn-sm text-white">
            Back to All Applications
          </Link>
        </div>
      )}
    </div>
  </div>
</div>

		</>
	);
};

export default Employees_details;

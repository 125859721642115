import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { HashLoader } from "react-spinners";
import { SITEURL, BASEURL } from "../BASEURL";

const GetUsers = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	// Function to fetch users from the API
	const fetchUsers = async () => {
		try {
			const response = await fetch(`${BASEURL}getUsersStore`);
			const result = await response.json();

			if (response.status === 200) {
				setUsers(result.data); // Store the fetched user data
				// Swal.fire("Success", result.message, "success");
			} else {
				Swal.fire(
					"Error",
					result.message || "Failed to fetch users",
					"error"
				);
			}
		} catch (error) {
			Swal.fire(
				"Error",
				"An error occurred while fetching users",
				"error"
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<div className="intro-y box lg:mt-1">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">
					Users Who Opted Out from the Exercise Session
				</h2>
			</div>

			{loading ? (
				<HashLoader size={30} />
			) : (
				<table
					className="table table-bordered"
					style={{ width: "100%" }}
				>
					<thead>
						<tr>
							<th className="border px-4 py-2">Name</th>
							<th className="border px-4 py-2">Username</th>
							<th className="border px-4 py-2">Department</th>
							<th className="border px-4 py-2">Reason</th>
							<th className="border px-4 py-2">Time</th>
						</tr>
					</thead>
					<tbody>
						{users.length > 0 ? (
							users.map((user) => (
								<tr key={user.id}>
									<td className="border px-4 py-2">
										{user.name}
									</td>
									<td className="border px-4 py-2">
										{user.username}
									</td>
									<td className="border px-4 py-2">
										{user.dept}
									</td>
									<td
										className="border px-4 py-2"
										style={{
											whiteSpace: "pre-wrap",
											wordBreak: "break-word",
										}}
									>
										{user.reason}
									</td>
									<td className="border px-4 py-2">
										{new Date(
											user.created_at
										).toLocaleString("en-US", {
											year: "numeric",
											month: "2-digit",
											day: "2-digit",
											hour: "2-digit",
											minute: "2-digit",
											hour12: true,
										})}
									</td>
								</tr>
							))
						) : (
							<tr>
								<td
									colSpan="5"
									className="border px-4 py-2 text-center"
								>
									No users found
								</td>
							</tr>
						)}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default GetUsers;
